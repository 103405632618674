import { IAction, IAlert, IAlertState } from "src/types";
import { SET_ALERT, REMOVE_ALERT } from "../actions/actionTypes";

const initalState: IAlertState = []

const alertReducer = (state: IAlertState = initalState, action: IAction) => {

    const { type, payload } = action;

    switch (type) {
        case SET_ALERT:
            return [
                ...state,
                payload
            ]
        case REMOVE_ALERT:
            return state.filter((alert: IAlert) => alert.id !== payload);
        default:
            return state
    }
}

export default alertReducer