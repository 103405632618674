import { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBuildingByOrganization, getbuildingHomeDatabyOrganisation } from "src/services/buildingService";
import {getInstrumentsByOrganization} from 'src/services/instrumentService';
import { getDeviceByOrganization,getAllDeviceDataByOrganisation } from "src/services/deviceService";
import { getFloorByOrganization } from "src/services/floorService";
import { getRoomByOrganization } from "src/services/roomService";
import { getAllStudentDataByOrganisation } from "src/services/studentDataService";
import {getAlertsByOrganizationId} from '../../services/alertsFarmService'
import { RootState } from "src/store";
import { SET_DEVICEDATA, SET_BUILDINGDATA, SET_FLOORDATA, SET_ROOMDATA, SET_INSTRUMENTSDATA,SET_STUDENTDATA, SET_FARMALERTSBYORGANISATION } from "src/store/actions/actionTypes";
import { formatData } from "src/utils/commonMethods";
import {refreshAllDeviceDataByOrg} from '../../store/actions/deviceActions'
import { getBuildingHomeDatabyOrg } from "src/store/actions/buildingActions";
// import { refreshInstrumentData } from "src/store/actions/instrumentsAction";

const RefreshDoorLockData: FC = (props: any): ReactElement => {

    const dispatch = useDispatch()

    const application = useSelector((state: RootState) => state.application)
    const organization = useSelector((state: RootState) => state.organization)
    const devices = useSelector((state: RootState) => state.devices.devices)
    const floors = useSelector((state: RootState) => state.floors.floors)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    const instruments=useSelector((state:RootState)=>state.instruments.instruments)
    const studentDatas=useSelector((state:RootState)=>state.studentData.students)
    // const alertsFarmGuard=useSelector((state:RootState)=>state.farmGuardAlerts.farmAlerts)
    useEffect(() => {
        refreshBuildingData()
    }, [buildings.fetchStatus])
    useEffect(() => {
        refreshBuildingHomeDatabyOrg()
    }, [buildings.fetchStatus])

    useEffect(() => {
        refreshFloorData()
    }, [floors.fetchStatus])

    useEffect(() => {
        refreshRoomsData()
    }, [rooms.fetchStatus])

    useEffect(() => {
        refreshDeviceData()
    }, [devices.fetchStatus])

    // useEffect(()=>{
    //     refreshFarmGuardData()
    // },[alertsFarmGuard.fetchStatus])


    useEffect(()=>{
            refreshInstrumentsData()    
    },[instruments.fetchStatus])
    
    useEffect(()=>{
        refreshStudentData()
    },[studentDatas.fetchStatus])
    // useEffect(() => {
    // }, [devices, rooms, floors, buildings,instruments])

    useEffect(()=>{
        refreshAllDeviceDataByOrganisation()
    },[devices.fetchStatus])
    const refreshDeviceData = async () => {
        if (!devices.fetchStatus) {
            const organizationId = organization.id
            const applicationId = application.id
            const response = await getDeviceByOrganization({ organizationId, applicationId })
            if (response.status === 200 && response.data.length !== 0) {
                dispatch({ type: SET_DEVICEDATA, payload: { value: response.data.map((x: any) => formatData(x)), fetchStatus: true } })
            }
        }
    }
    const refreshAllDeviceDataByOrganisation=async()=>{
        const organizationId = organization.id
        const applicationId = application.id
        const response= await getAllDeviceDataByOrganisation({organizationId, applicationId})
        if(response.status===200){
            refreshAllDeviceDataByOrg(dispatch,response.data)
        }
    }
    // const refreshFarmGuardData=async()=>{
    //     if(!alertsFarmGuard.fetchStatus){
            
    //         const response=await getAlertsByOrganizationId({organizationId:organization.id,applicationId:application.id})
    //         console.log(response,"response")
    //         if(response.status===200){
    //             dispatch({type:SET_FARMALERTSBYORGANISATION,payload:{value:response.data.map((x:any)=>formatData(x)),fetchStatus:true}})
    //         }
    //     }
        
    // }
    const refreshRoomsData = async () => {
        if (!rooms.fetchStatus) {
            const organizationId = organization.id
            const applicationId = application.id
            const response = await getRoomByOrganization({ organizationId, applicationId })
            if (response.status === 200 && response.data.length !== 0) {
                dispatch({ type: SET_ROOMDATA, payload: { value: response.data.map((x: any) => formatData(x)), fetchStatus: true } })
            }
        }
    }


    const refreshFloorData = async () => {
        if (!floors.fetchStatus) {
            const organizationId = organization.id
            const applicationId = application.id
            const response = await getFloorByOrganization({ organizationId, applicationId })
            if (response.status === 200 && response.data.length !== 0) {
                dispatch({ type: SET_FLOORDATA, payload: { value: response.data.map((x: any) => formatData(x)), fetchStatus: true } })
            }
        }
    }


    const refreshBuildingData = async () => {
        if (!buildings.fetchStatus) {
            const organizationId = organization.id
            const applicationId = application.id
            const response = await getBuildingByOrganization({ organizationId, applicationId })
            if (response.status === 200 && response.data.length !== 0) {
                
                dispatch({ type: SET_BUILDINGDATA, payload: { value: response.data.map((x: any) => formatData(x)), fetchStatus: true } })
            }
        }
    }
    const refreshBuildingHomeDatabyOrg= async () => {
        const response = await getbuildingHomeDatabyOrganisation({ organizationId: organization.id, applicationId: application.id })
        if (response.status === 200) {
            getBuildingHomeDatabyOrg(dispatch,response.data)
        }
    }

    
    const refreshInstrumentsData= async()=>{
        if(!instruments.fetchStatus){
            const organizationId = organization.id
            const applicationId = application.id
            const response= await getInstrumentsByOrganization({organizationId,applicationId})
            if(response.status===200 && response.data.length !==0){
                dispatch({type:SET_INSTRUMENTSDATA,payload:{value:response.data,fetchStatus:true}})
            }
        }

    }

    const refreshStudentData=async()=>{
        if(!studentDatas.fetchStatus){
            const organizationId = organization.id
            const applicationId = application.id
            const response=await getAllStudentDataByOrganisation({organizationId,applicationId})
            if(response.status===200 && response.data.length!==0){
                dispatch({type:SET_STUDENTDATA,payload:{value:response.data,fetchstatus:true}})
            }
        }
    }
    return (
        <></>
    )
}

export default RefreshDoorLockData