import React from 'react';
import {Button,Typography } from '@mui/material';
import { FC, Fragment, ReactElement,useState,useEffect,useCallback} from "react";
import { useDispatch, useSelector, } from 'react-redux';
import { RootState } from "src/store";
import {useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faCircle, faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../Instruments/instruments.css'
import { refreshInstrumentsData } from 'src/store/actions/instrumentsAction';
import {updateInstrumentsList,deleteInstrumentsList,createInstrumentsList} from '../../../services/instrumentService';
import {getInstrumentsByOrganization} from 'src/services/instrumentService';


const Instruments: FC = (props: any): ReactElement => {	
	const [inputValue, setInputValue] = useState('');
	const [totalItemCount, setTotalItemCount] = useState(6);
	const instruments=useSelector((state:RootState)=>state.instruments.instruments)
	// console.log(instruments,'instruments')
	const[instrumentList,setInstrumentList]=useState([{ instrumentName: '', quantity: 1,watts:1, isSelected: false }])
	const organization=useSelector((state:RootState)=>state.organization)
	const application=useSelector((state:RootState)=>state.application)
	//.length!==0?instruments:[{ instrumentName: '', quantity: 1,watts:1, isSelected: false }]
	const [items, setItems] = useState(instruments);
	// console.log(items,'items')
	const [visibility,setVisbility]=useState(false)
	const dispatch=useDispatch()
	const organizationId = organization.id
	const applicationId = application.id
	const navigate =useNavigate()
	const initialList=useCallback(async ()=>{
		const response1= await getInstrumentsByOrganization({organizationId,applicationId})
		setInstrumentList(response1.data)
	},[])

	useEffect(()=>{
			initialList();
	},[])
	// console.log(instrumentList,'instrumentList')
	
	const refreshHome= useCallback(async ()=>{
		const response1= await getInstrumentsByOrganization({organizationId,applicationId})
		// dispatch({type:SET_INSTRUMENTSDATA,payload:{value:response1.data,fetchStatus:true}})
		refreshInstrumentsData(dispatch,response1.data)
		navigate('/home')
		setTimeout(()=>{navigate('/home/Instruments')},100)
	},[])

	const HandleAddButtonClick = async () => {
		let counts=0
		for(let i=0;i<items.length;i++){			
			if(items[i].instrumentName===inputValue){
				counts++;
			}			
		}
		if (counts===0){			
			// const newItem = {
			// 	instrumentName: inputValue,
			// 	quantity: 1,
			// 	  watts:1,
			// 	isSelected: false,
			// };
			// const newItems = [...items, newItem];
			// setItems(newItems);
			
			const response= await createInstrumentsList({instrumentName:inputValue.toUpperCase(),applicationId:applicationId,organizationId:organizationId})			
			if (response.status===200 && response.data.length!==0){
				refreshHome()
	}
		}
		else if(counts>0 || counts<0){			
			// setItems([...items])
			// alert('Item Already Existing!!')
			setVisbility(true)
			setTimeout(()=>{setVisbility(false)},2000)
		}
		setInputValue('');
		calculateTotal();
	};

	const handleQuantityIncrease = (index:any) => {
		const newItems = [...items];
    if (newItems[index].quantity>=0){
		newItems[index].quantity++;
    setItems(newItems);
    }		
		calculateTotal();
	};

	const handleQuantityDecrease = (index:any) => {
		const newItems = [...items];

		
    if (newItems[index].quantity>0){
      newItems[index].quantity--;
      setItems(newItems);
    }
		
		calculateTotal();
	};

	const toggleComplete = (index:any) => {
		const newItems = [...items];

		newItems[index].isSelected = !newItems[index].isSelected;

		setItems(newItems);
	};

	const calculateTotal = () => {
		const totalItemCount = items.reduce((total:any, item:any) => {
			return total + item.quantity;
		}, 0);

		setTotalItemCount(totalItemCount);
	};

  const InputValue=(index:any)=>(event:any)=>{
    const newItems = [...items];
		const value=event.target.value
		newItems[index].quantity=value
      	setItems(newItems);		
		calculateTotal();
  }


  const handleQuantityIncrease1 = (index:any) => {
	const newItems = [...items];
if (newItems[index].watts>=0){
	newItems[index].watts++;
	setItems(newItems);
}
	
	calculateTotal();
};

const handleQuantityDecrease1 = (index:any) => {
	const newItems = [...items];

	
if (newItems[index].watts>0){
  newItems[index].watts--;
  setItems(newItems);
}
	
	calculateTotal();
};

const InputValue1=(index:any)=>(event:any)=>{
    const newItems = [...items];
		const value=event.target.value
		newItems[index].watts=value
      	setItems(newItems);		
		calculateTotal();
  }


const submitItems= async ()=>{
for(let i=0;i<items.length;i++){
	for(let j=0;j<instrumentList.length;j++){
		if(items[i].instrumentName===instrumentList[j].instrumentName){
			if(items[i].quantity!==instrumentList[j].quantity || items[i].watts!==instrumentList[j].watts){				
				await updateInstrumentsList({_id:items[i]._id,quantity:items[i].quantity,watts:items[i].watts})
			}
		}		
	}

}
refreshHome();
}
  const deleteItems=async ()=>{
	for(let i=0;i<items.length;i++){
		if(items[i].isSelected===true){
			// console.log('organizationId:',organizationId)
			// console.log('instrumentName:',items[i].instrumentName)
			// console.log('isSelected:',items[i].isSelected)
			await deleteInstrumentsList({organizationId:organizationId,instrumentName:items[i].instrumentName})
		}

	}
	refreshHome()

  }
	return (
		<>

		
		<div className='app-background'>			
			<div className='main-container'>
			<Typography sx={{color:'#000',fontSize:22,fontWeight:'bold' }}>Instruments List</Typography>			
			<Button variant='contained' sx={{ backgroundColor:'#fbc1bb',color:'#000',float:'right',borderRadius:5,ml:5, ":hover": { backgroundColor: '#fbc1bb',color:'#000' } }} onClick={deleteItems}>Delete</Button>
			<Button variant='contained' sx={{ backgroundColor:'#fbc1bb',color:'#000',float:'right',borderRadius:5, ":hover": { backgroundColor: '#fbc1bb',color:'#000' } }} onClick={submitItems}>Update</Button>
			{visibility ?(<Typography sx={{color:'yellow',fontSize:22,fontWeight:'bold',float:'right',borderRadius:5,mr:15}}>**Item Already Existing!!**</Typography>):<></>}
			<br/>
			<br/>
				<div className='add-item-box'>					
					<input style={{fontWeight:'bold',fontSize:14}} value={inputValue} onChange={(event) => setInputValue(event.target.value)} className='add-item-input' placeholder='Add an item...' />
					<FontAwesomeIcon icon={faPlus} onClick={() => HandleAddButtonClick()} />
				</div>
				<div className='item-list'>
					{items.map((item:any, index:any) => 
          			item.instrumentName!==''?(
          (
						<div key={index} className='item-container'>
							<div className='item-name' onClick={() => toggleComplete(index)}>
								{item.isSelected ? (
									<>
										<FontAwesomeIcon icon={faCheckCircle} />
										<span className='completed' style={{fontWeight:'bold',fontSize:16}}>{item.instrumentName}</span>
									</>
								) : (
									<>
										<FontAwesomeIcon  icon={faCircle} />
										<span style={{fontWeight:'bold',fontSize:16}}>{item.instrumentName}</span>
									</>
								)}
							</div>
							<div className='quantity'>
								<button style={{height:'15px'}}>
									<FontAwesomeIcon style={{height:'15px'}} icon={faChevronLeft} onClick={() => handleQuantityDecrease(index)} />
								</button>
								<span style={{fontWeight:'bold',fontSize:14}}>Qty : <input type='text' value={item.quantity} onChange={InputValue(index)} name='qty' style={{maxWidth:'25px',border:'none',fontWeight:'bold',fontSize:14}}></input></span>
								<button style={{height:'15px'}}>
									<FontAwesomeIcon style={{height:'15px'}} icon={faChevronRight} onClick={() => handleQuantityIncrease(index)} />
								</button>
                				<span style={{marginLeft:'10px'}}>|</span>
								<button style={{height:'15px'}}>
									<FontAwesomeIcon style={{height:'15px'}} icon={faChevronLeft} onClick={() => handleQuantityDecrease1(index)} />
								</button>
								<span style={{fontWeight:'bold',fontSize:14}}>Watts : <input type='text' value={item.watts} onChange={InputValue1(index)} name='qty' style={{maxWidth:'28px',border:'none',fontWeight:'bold',fontSize:14}}></input></span>
								<button style={{height:'15px'}}>
									<FontAwesomeIcon style={{height:'15px'}} icon={faChevronRight} onClick={() => handleQuantityIncrease1(index)} />
								</button>
							</div>
						</div>
					)):<></>)}
				</div>
				{/* <div className='total'>Total: {totalItemCount}</div> */}
			</div>
		</div>
		</>
	);
};

export default Instruments