import React from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import { FC, Fragment, ReactElement, useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import CreateBuilding from './CreateBuilding';
import DeleteBuilding from './DeleteBuilding';
import { useNavigate } from 'react-router-dom'
import { ColDef, GroupCellRendererParams, ValueGetterParams } from 'ag-grid-community';
import moment from 'moment';
import GridView from 'src/components/homePage/GridView';
import { RemoveRedEye, Delete } from '@mui/icons-material';
import '../../../screens/SwitchApps.css';
import dateComparator from 'src/utils/commonMethods';
import { refreshBuildingData } from 'src/store/actions/buildingActions';
import RefreshDoorLockData from '../refreshDoorLockData';
import { SET_SELECTEDBUILDINGDATA } from 'src/store/actions/actionTypes';
import { IBuilding } from 'src/types';
import {getBuildingHomeDatabyOrg} from '../../../store/actions/buildingActions'
import { getBuildingDashboardDetails, getDeviceDataGroupbyBuildingId, getbuildingHomeDatabyOrganisation } from "src/services/buildingService";

import Notification from '../../../components/homePage/Notifications/notification'
interface deleteModalProps {
    id: string,
    name: string,
    visible: boolean
}

type AlertType = "error" | "success" | "info" | "warning" | undefined;
const BuildingHome: FC = (props): ReactElement => {
    const [createModelopen, setCreateModelopen] = useState(false);
    const [deleteModal, setDeleteModal] = useState<deleteModalProps>({ id: '', name: '', visible: false })
    const buildingHomeData=useSelector((state:RootState)=>state.buildings.buildingHomeDatabyOrg)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const application = useSelector((state: RootState) => state.application)
    const organization = useSelector((state: RootState) => state.organization)
    const { id: deleteModalId, name: deleteModalName, visible: deleteModalVisible } = deleteModal;

    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const [data, setData] = useState<any>({ floorResponse: [], roomResponse: [],groupByBuildingId:[] });
    // // console.log(data, 'data')
    // const refreshHome1 = useCallback(async () => {
    //     const response = await getbuildingHomeDatabyOrganisation({ organizationId: organization.id, applicationId: application.id })
    //     if (response.status === 200) {
    //         getBuildingHomeDatabyOrg(dispatch,response.data)
    //         setData(response.data)
    //     }
    // }, [])

    // useEffect(() => {
    //     refreshHome1()
    // }, [])


    const getFloorCount = (buildingId: any) => {
        const floorCountData = buildingHomeData.floorResponse.find((x: any) => x._id === buildingId)
        return floorCountData ? floorCountData.floorCount : 0
    }
    const getRoomCount = (buildingId: any) => {
        // return data.roomResponse.length!==0?data.roomResponse.filter((x:any)=>x._id===buildingId)[0].roomCount:0
        const roomCountData = buildingHomeData.roomResponse.find((x: any) => x._id === buildingId)
        return roomCountData ? roomCountData.roomCount : 0
    }
    const getUnitSaved = (buildingId: any) => {
        // return data.roomResponse.length!==0?data.roomResponse.filter((x:any)=>x._id===buildingId)[0].roomCount:0
        const unitSaved = buildingHomeData.groupByBuildingId.find((x: any) => x._id === buildingId)
        console.log('unitSaved',unitSaved)
        return unitSaved ? unitSaved.total_units : 0
    }
    const getCostSaved = (buildingId: any) => {
        // return data.roomResponse.length!==0?data.roomResponse.filter((x:any)=>x._id===buildingId)[0].roomCount:0
        const costSaved = buildingHomeData.groupByBuildingId.find((x: any) => x._id === buildingId)
        return costSaved ? costSaved.total_cost_saved : 0
    }
    const onhandleClick = (data: IBuilding) => {
        dispatch({ type: SET_SELECTEDBUILDINGDATA, payload: data })
        navigate(`/home/buildings/${data._id}`)
    }



    const columnDefs: ColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: 'Hostel Name',
            flex: 1,
            initialWidth:600,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            filter: 'agTextColumnFilter',
            cellRenderer: (params: GroupCellRendererParams) => {
                return <Button onClick={() => onhandleClick(params.data)}> {params.data.name}</Button>
            },
            sort: 'asc'
        }, {
            field: 'TotalRooms',
            headerName: 'Total Rooms',
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                const val = getRoomCount(params.data.id)
                return val ? val : 0
            }
        }, {
            field: 'TotalFloors',
            headerName: 'Total Floors',
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                const val = getFloorCount(params.data.id)
                return val ? val : 0
            }
        },{
            field: 'TotalUnits',
            headerName: 'Units Saved',
            flex: 1,
            initialWidth:200,
            maxWidth:200,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                const val = getUnitSaved(params.data.id)
                return val ? val : 0
            }
        },{
            field: 'CostSaved',
            headerName: 'Cost Saved in Rs.',
            flex: 1, 
            initialWidth:200,
            maxWidth:200,
            wrapHeaderText: true,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                const val = getCostSaved(params.data.id)
                return val ? val : 0
            }
        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            filter: 'agTextColumnFilter',
            comparator: dateComparator,
            valueGetter: (params: ValueGetterParams) => {
                return moment(params.data.createdDate).format('DD/MM/YYYY | HH:mm:ss')
            }

        },
        {
            field: 'actions',
            headerName: 'Action',
            cellRenderer: (params: GroupCellRendererParams) => (
                <Grid container spacing={4}>
                    <Grid item><RemoveRedEye sx={{ color: '#660066', mt: 1 }} onClick={() => onhandleClick(params.data)} /></Grid>
                    <Grid item><Delete sx={{ color: '#660066', mt: 1 }} onClick={() => setDeleteModal({ id: params.data._id, name: params.data.name, visible: true })} /></Grid>
                </Grid>)
        },
    ], [buildings, buildingHomeData]);

    const refreshHome = () => {
        refreshBuildingData(dispatch)
    }

    useMemo(() => {
        refreshHome()
    }, [])

    const refreshDataGrid = () => {
        navigate('../home')
        setTimeout(() => { navigate(`/home/buildings`) }, 500)
    }

    const [status,setStatus]=useState(false)
    const statusData=(data:boolean)=>{
        setStatus(data)
    }
    
    const [message,setMessage]=useState<AlertType>('success')
    const messagesData=(data:AlertType)=>{
        setMessage(data)
    }
    
    return (
        <Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h5'> Buildings </Typography>
                <Button variant='contained' sx={{ backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} onClick={() => { setCreateModelopen(true) }}>+Add Building</Button>
            </div>
            <hr />
            <Button variant='contained' sx={{ backgroundColor: '#660066', float: 'right', ":hover": { backgroundColor: '#660066' } }} onClick={refreshDataGrid}>Refresh</Button>
            <br />
            <br />
            <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs} rowData={buildings} />
            </Box>
            <CreateBuilding visibility={createModelopen} setVisiblity={setCreateModelopen} refreshHome={refreshHome} messagesData={messagesData} statusData={statusData}/>
            <DeleteBuilding visibility={deleteModalVisible} setVisiblity={setDeleteModal} refreshHome={refreshHome} data={{ name: deleteModalName, id: deleteModalId }} messagesData={messagesData} statusData={statusData}/>
            <Notification message={message} status={status}/>
            <RefreshDoorLockData />
        </Fragment>
    )
}

export default BuildingHome