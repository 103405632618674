import { render } from "@testing-library/react";
import React,{FC,ReactElement,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION, SET_FARMALERTSBYORGANISATION } from "src/store/actions/actionTypes";
import { getAlertsByOrganizationId,getFarmAlertsCountByOrg,getAlertReportsByOrgainzationId } from "src/services/alertsFarmService";
import { formatData } from "src/utils/commonMethods";
import { RootState } from "src/store";
import { refresh_chart_By_org,refresh_Report_By_Org } from "src/store/actions/alertFarmGuardAction";
import { response } from "express";
const RefreshFarmGuardData:FC=(props:any):ReactElement=>{

    const dispatch =useDispatch()
    const organization=useSelector((state:RootState)=>state.organization)
    const application=useSelector((state:RootState)=>state.application)
    const farmGuardAlerts=useSelector((state:RootState)=>state.farmGuardAlerts)
    
    const refreshFarmGuardData=async()=>{
        if(!farmGuardAlerts.fetchStatus){
            
            const response=await getAlertsByOrganizationId({organizationId:organization.id,applicationId:application.id})
            // console.log(response,"response")
            if(response.status===200){
                dispatch({type:SET_FARMALERTSBYORGANISATION,payload:{value:response.data.map((x:any)=>formatData(x)),fetchStatus:true}})
                // refreshFarmGuardData(dispatch,)
            }
        }   
        
    }

    useEffect(()=>{
        refreshFarmGuardData();
    },[farmGuardAlerts.fetchStatus])

    const refreshFarmAlertsByOrg=async()=>{
        if(!farmGuardAlerts.fetchStatus){
        const response=await getFarmAlertsCountByOrg({organizationId:organization.id,applicationId:application.id})
        if(response.status===200){
            // refresh_chart_By_org(dispatch,response.data)
            // console.log(response.data,'response.data')
            dispatch({type:SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION,payload:{dayResponse:response.data.dayResponse,monthResponse:response.data.monthResponse}})
        }}}
    useEffect(()=>{
       
            refreshFarmAlertsByOrg();
        
    },[farmGuardAlerts.fetchStatus])


    const refreshFarmAlertReportByOrg=async()=>{
        if(!farmGuardAlerts.fetchStatus){
        const response=await getAlertReportsByOrgainzationId({organizationId:organization.id,applicationId:application.id})
        if(response.status===200){
            // console.log(response.data,'response.datarefreshFarmAlertReportByOrg')
            refresh_Report_By_Org(dispatch,response.data)
        }}}
    useEffect(()=>{ 
        refreshFarmAlertReportByOrg();
    },[farmGuardAlerts.fetchStatus])
    return(

        <></>
    )

}
export default RefreshFarmGuardData