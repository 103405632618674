import { Dispatch } from "react";
import { CREATE_INSTRUMENTS,UPDATE_INSTRUMENTS,DELETE_INSTRUMENTS,SET_INSTRUMENTSDATA } from "./actionTypes";

export const createInstrument=(dispatch:Dispatch<any>,instrumentName:string)=>{
    dispatch({type:CREATE_INSTRUMENTS,instrumentName})
}

export const updateInstrument=(dispatch:Dispatch<any>,instrumentId:string)=>{
    dispatch({type:UPDATE_INSTRUMENTS,instrumentId})
}

export const deleteInstrument=(dispatch:Dispatch<any>,instrumentId:string)=>{
    dispatch({type:DELETE_INSTRUMENTS,instrumentId})
}


export const refreshInstrumentsData=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:SET_INSTRUMENTSDATA,payload:{value:data,fetchStatus:true}})
}