import React from 'react';
import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import FarmGuardDashboard from './farmGuard';
import DoorLockDashboard from './DoorLock';
import { Typography } from '@mui/material';

const Dashboard: FC = (): ReactElement => {
  
  const selectedApplication = useSelector((state: RootState) => state.application)
  const user =useSelector((state:RootState)=>state.user)
  // console.log('user.role',user)

  // console.log(selectedApplication,'selectedApplication')
  // console.log(selectedApplication.name==='Smart Door Lock Detector and Monitor','selectedApplication.name')
  switch (selectedApplication.name){
    case 'Smart Door Lock Detector and Monitor' : return <DoorLockDashboard role={user.role}/>
    case 'Farm Guard' : return <FarmGuardDashboard/>
    default: return ( <Typography> No dashboard found </Typography>)
  } 

}

export default Dashboard