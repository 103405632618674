import * as React from 'react';
import {FC} from 'react';
import "../../screens/SwitchApps.css"
import { Typography,Box,Card,Button } from '@material-ui/core';
import lauchimg from '../../assets/svg/launch3.png'
import farmguardIcon from '../../assets/svg/farmguard.png';
import doorlockIcon from '../../assets/svg/doorlock.png';
import smarttimecontrollerIcon from '../../assets/svg/smarttimecontroller.png';
import motionsensorIcon from '../../assets/svg/motion-sensor.png'
interface Appformat{
    _id:string,
    name:string,
    description:string
}

interface Appdata{
    data:Appformat;
    
}

interface Appaccess{
    access:(id:string)=>boolean;
}

interface Appnavigate{
    pageNavigate:(x: any, applicationId: string, applicationName: string) => void
}

interface Appindex{
    indexvalue:number
}

type Props=Appdata & Appaccess & Appnavigate & Appindex
const CardComponent:FC<Props>= (props:Props) => {
    const {data,access,pageNavigate}=props;
    // let accessdata=access(data._id)


    const icons:any={
        'Farm Guard':farmguardIcon,
        'Smart Door Lock Detector and Monitor':doorlockIcon,
        'Smart Timer Controller':smarttimecontrollerIcon,
        'Smart Motion Detector':motionsensorIcon,
        }
    
    //const num=indexvalue.toString()
    const source=icons[data.name]
    return (
        
            <div className="col-1-of-3" style={{borderRadius:'25%'}}>
                <div className="card" style={{backfaceVisibility:'hidden',borderStyle:'none'}}> 
                    <div className="card__side card__side--front-1" 
                    style={{boxShadow:'2px 5px 9px #888888'}}>
                    <Box  className='headerBox' style={{width:"300px",wordBreak:"break-word"}}
                        > 
                         <Typography  variant="h5"
                         style={{color:'white',
                             fontWeight:'bold'}}>
                             {data.name}
                         </Typography> </Box>
                         <div></div>
                    <Box className='iconBox'  boxShadow={10} style={{
                    textAlign:'center',justifyContent:'center'
                       
                    }}>
                    <img src={source} alt='icon' width='50px' height='50px' style={{textAlign:'center',
                        marginTop:'25%'}}
                        />
                </Box>
                    <hr className="horizontalLine"/>
                        
                        <Box className="descBox" style={{width:"300px",wordBreak:"break-word"}}> {data.description} </Box>
                    </div>
                    <Card style={{ justifyContent:"center", alignItems:"center"}}></Card>
                    <div className="card__side card__side--back card__side--back-1"
                    style={{boxShadow:'2px 5px 9px #888888'}}>
                    <div className="card__cta">
                    <div style={{ overflow: 'hidden',marginTop:'15%',height:'8.5rem',
                    textAlign:'center',justifyContent:'center',  
                    }}>
                    <Typography  variant="h5" style={{color:'white',
                             fontWeight:'bold'}}
                    >
                        {data.name}
                    </Typography></div>
                            <div style={{textAlign:'center',justifyContent:'center',marginTop:'-25px'}}>
                            <Button variant='text' size="small" disabled={access(data._id)}
                            onClick={(x:any)=>pageNavigate(x,data._id,data.name)}                            
                            >
                                {access(data._id)===false ? (
                            <img src={lauchimg} alt='icon' width='100px' height='100px'/>)
                            :(<img className='launchButton' src={lauchimg} alt='icon' width='100px' height='100px'/>)}                           
                            </Button></div>




                        </div>
                    </div>
                </div>
            </div>
    )

}
export default CardComponent;


