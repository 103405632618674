import React,{FC, Fragment, ReactElement} from 'react'
import { useState } from 'react'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'
import folder from '../assets/folder.png'

interface folderProps{
    cameraName:string
}

const FolderComponent:FC<folderProps>=(props:folderProps):ReactElement=>{
    const organanization=useSelector((state:RootState)=>state.organization)
    // console.log(organanization,'organanization')
    const {cameraName}=props
    
    return(
        <Fragment>
            
        
                <figure style={{textAlign:'center',justifyContent:'center',}}>
                <img src={folder} width='200px' alt='folder'/>
                <figcaption style={{textAlign:'center',justifyContent:'center',fontWeight:'bold',fontSize:'25px'}}>{cameraName}</figcaption>
                </figure>
                    
                </Fragment>
                
                )
}
export default FolderComponent
