import { formatData } from 'src/utils/commonMethods'
import http from './http-common'

export const createStudent=async(formData:any)=>{
    return http.post<any>('/studentData/createStudent',formData)

}

export const updateStudent=async(formData:any)=>{
    return http.post('/studentData/updateStudent',formData)
}

export const deleteStudent=async(formData:any)=>{
    return http.post('/studentData/deleteStudent',formData)
}

export const getAllStudentDataByOrganisation=async(formData:any)=>{
    return http.post('/studentData/getAllStudentDataByOrganisation',formData)
}

export const uploadFiles=async(formData:any)=>{
    return http.post('/upload/uploadStudentData',formData)
}