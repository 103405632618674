import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Grid, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC, ReactElement, useState } from "react";
import { useSelector } from 'react-redux';
import { createRoomService } from 'src/services/roomService';
import { RootState } from 'src/store';
import { IBuilding, IFloor } from 'src/types';
import {useNavigate} from 'react-router-dom';
type AlertType = "error" | "success" | "info" | "warning" | undefined;
interface CreateRoomProps {
    visibility: boolean,
    setVisibility: (a: boolean) => void;
    refreshHome: () => void;
    statusData:(a: boolean)=>void;
    messagesData:(a:AlertType)=>void
}

const CreateRoom: FC<CreateRoomProps> = (props: CreateRoomProps): ReactElement => {

    const { visibility, setVisibility, refreshHome,statusData,messagesData } = props;

    const [formData, setFormData] = useState({
        number: '',
        buildingId: '',
        floorId: ''
    })

    const { number, buildingId, floorId } = formData;
    const navigate=useNavigate()
    const clear = () => {
        setFormData({ number: '', buildingId: '', floorId: ''})
    }

    const applicationId = useSelector((state: RootState) => state.application.id)
    const organizationId = useSelector((state: RootState) => state.organization.id)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const floors = useSelector((state: RootState) => state.floors.floors)

    const handleClick = async (e: any) => {
        e.preventDefault();
        const response = await createRoomService({ number,  buildingId, floorId, applicationId, organizationId })
        if (response.status === 200) {
            refreshHome()
            setVisibility(false)
            statusData(true)
            messagesData('success')
            clear()
            setTimeout(()=>{
                navigate('../home')
                navigate('../rooms/')
            },100)
        }
    }

    const handleClose = () => {
        setVisibility(false)
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <Dialog open={visibility} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby='alert-dialog-description'>

            <DialogTitle color="#660066" id="alert-dialog-title"> Create Room </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid spacing={3}>
                        <TextField color="secondary" sx={{ mb: 1 }} onChange={handleChange} value={number} fullWidth id="number" name="number" label="Number" variant="standard"  />
                        <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-label">Building</InputLabel>
                            <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="buildingId" onChange={handleChange} value={buildingId}>
                                <MenuItem value=""> <em>None</em></MenuItem>
                                {
                                    buildings.map((x: IBuilding) => {
                                        return <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl color="secondary" fullWidth variant="standard">
                            <InputLabel id="demo-simple-select-label">Floors</InputLabel>
                            <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="floorId" onChange={handleChange} value={floorId}>
                                <MenuItem value=""> <em> None </em></MenuItem>
                                {
                                    floors.filter((x: IFloor)=>( x.buildingId === buildingId)).map((x: IFloor)=> {
                                        return <MenuItem value ={x.id} key={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClose}> Cancel </Button>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClick} autoFocus> Create </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateRoom