import { Box, Card, CardContent, Grid, Paper, Typography,Button } from "@mui/material";
import { FC, Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "src/store";
import { getAlertByDevice } from "src/services/alertDeviceService";
import { IDevice, IAlertDevice } from "src/types";
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import GridView from 'src/components/homePage/GridView';
import '../../../screens/SwitchApps.css'
import moment from 'moment';
import { getDeviceAlertsByMonthService, updateDeviceStatus,updateDeviceActiveStatus,getDeviceByOrganization, getDeviceById } from "src/services/deviceService";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import NoofTimesDoorOpened from "src/components/homePage/NewChartWidget/NoofTimesDoorOpened";
import dateComparator  from 'src/utils/commonMethods';
import { SET_DEVICEDATA, SET_SELECTEDDEVICEDATA } from "src/store/actions/actionTypes";
import TotalUnitsSaved from "src/components/homePage/NewChartWidget/TotalUnitsSaved";
import TotalCostSaved from "src/components/homePage/NewChartWidget/TotalCostSaved";
import {getAlertByDeviceId} from '../../../store/actions/alertDevicesAction'
import TotalPowerConsumption1 from "src/components/homePage/NewChartWidget/TotalPowerConsumption1";
import { stat } from "fs";
import {refreshDeviceAlertsByMonthService} from '../../../store/actions/deviceActions'
// interface IDeviceView {
// }

const DeviceView: FC = (props): ReactElement => {
  const { id } = useParams()
  const [loadingVisbility,setLoadingVisibility]=useState(false)
  const navigate = useNavigate();
  const statusList={
    1:'Enable',
    2:'Active',
    3:'Inactive',
    4:'Disabled'
  }

  // const [data, setdata] = useState<any>({
  //   deviceCount: 0,
  //   roomCount: 0,
  //   chartResponseOpened: [],
  //   chartResponseClosed: [],
  //   cosumptionDetails: [{
  //     "_id": {
  //       "year": 0,
  //       "month": 0
  //     },
  //     "total_hours": 0,
  //     "total_count": 0,
  //     // "total_units":0,
  //     // "total_cost_saved":0,
  //   }]
  // });
  // console.log(data,'dataDevice')
  // const [dataFetchStatus, setDataFetchStatus] = useState(false)

  // const [alerts, setAlerts] = useState([])
  
  // const [alertfetchStatus, setalertfetchStatus] = useState(false)

  const device: IDevice = useSelector((state: RootState) => state.devices.selectedDevice)
  const organization= useSelector((state: RootState) => state.organization)
  const application = useSelector((state:RootState)=>state.application)
  
  const alertDevice=useSelector((state:RootState)=>state.alertDevices)[0]
  const deviceAlertsByMonthService=useSelector((state:RootState)=>state.devices.selectedDeviceAlertsByMonthService)
  const organizationId = organization.id
	const applicationId = application.id
  const dispatch=useDispatch()
  // console.log(alertDevice,'alertDevice')
  // const watts=Number(device.totalWattsOfInstruments)===0||null||NaN||undefined?0:Number(device.totalWattsOfInstruments)//device.instrumentList.length===0?0:
  // const kiloWatts=watts/1000;
  // const costPerUnit=9;
  // const NewData=data
  // NewData.chartResponseClosed.map((data:any,index:any)=>{
  //   NewData.chartResponseClosed[index].total_units=Number((data.total_hours*kiloWatts).toFixed(0))
  //   NewData.chartResponseClosed[index].total_cost_Saved=Number((data.total_hours*kiloWatts*costPerUnit).toFixed(0))
  // })
  // console.log(device,'device')
  /*#####Data Verified########## */
  const refreshAlertByDevice=async()=>{
    const response = await getAlertByDevice({ deviceId: id })
    if (response.status === 200) {
      // console.log(response.data,'response.data')
      getAlertByDeviceId(dispatch,response.data)
      
    }   
  }
  useEffect(()=>{
    refreshAlertByDevice();
  },[id])
  /*#####Data Verified########## */

  const getAlertByDeviceData = (id: string): string | undefined | null => {
    // console.log(id,'id')
    const alertDevicesData = alertDevice.find((x: IAlertDevice) => x._id === id)
    return alertDevicesData;
  }

  const columnDefs: ColDef[] = useMemo(() => [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      filterParams: {
        buttons: ['reset', 'apply'],
      },
      filter: 'agTextColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        const a: any = getAlertByDeviceData(params.data._id)
        switch(a && a.status){
          case 'true':
            return "Door Closed"
          case 'Restarted':
            return 'Restarted'
          case 'Smoke Detected':
            return 'Smoke Detected'
          default:
            return 'Door Opened'
        }
        // return params.data._id
      },
    },
    {
      field: 'triggeredDate',
      headerName: 'Triggered Date & Time',
      comparator: dateComparator,
      flex: 1,
      filterParams: {
        buttons: ['reset', 'apply'],
      },
      sortable:true,
      filter: 'agTextColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        return moment(params.data.createdOn).format('DD/MM/YYYY | HH:mm:ss')
      },
      sort:'desc'
    }
  ], [alertDevice,id])//alertfetchStatus

  // function formatData(data: any) {
  //   return {
  //     id: data._id,
  //     createdDate: new Date(data.createadOn),
  //     ...data
  //   }
  // }
///////////////
  // const refreshAlertData = useCallback(async () => {
  //   const response = await getAlertByDevice({ deviceId: id })
  //   if (response.status === 200) {
  //     setAlerts(response.data.map((x: any) => formatData(x)))
  //     setalertfetchStatus(true)
  //   }
  //   const responseAlertsByMonth = await getDeviceAlertsByMonthService({ deviceId: id })
  //   setdata(responseAlertsByMonth.data)
  // }, [])

  // useEffect(() => {
  //   if (!alertfetchStatus) {
  //     refreshAlertData()
  //   }
  // }, [alertfetchStatus, refreshAlertData, alerts])
///////////////
  const refreshHome = useCallback(async () => {
    const response = await getDeviceAlertsByMonthService({ organizationId:organization.id,deviceId: id })
    if (response.status === 200) {
      refreshDeviceAlertsByMonthService(dispatch,response.data)
      // setdata(response.data)
      // setDataFetchStatus(true)
    }
  }, [])

  useEffect(() => {
    // if (!dataFetchStatus) {
      refreshHome()
    // }
  }, [ ])//dataFetchStatus,refreshHome, deviceAlertsByMonthService
///////////////
  

 
  
  const handleClick = async (data:any) => {
    const response = await updateDeviceStatus({  
      '_id':id,
      'deviceStatus':data
     })
    if (response.status === 200) {
      // refreshHome()organizationId,applicationId
      const response1 = await getDeviceByOrganization({organizationId,applicationId})
      const response2 = await getDeviceById({id})
      
      // console.log(response2.data,'response.data')
      dispatch({ type: SET_DEVICEDATA, payload: { value:response1.data, fetchStatus: true } })
      dispatch({ type: SET_SELECTEDDEVICEDATA, payload: { value:response2.data, fetchStatus: true } })
      navigate('/home')
      navigate('../home/devices')
      setTimeout(()=>{navigate(`../devices/${id}`)},50)

      
      
    }
    

}

const handleActiveClick = async (data:any) => {
  const response = await updateDeviceActiveStatus({  
    '_id':id,
    'deviceActiveStatus':data
   })
  if (response.status === 200) {
    // refreshHome()
    // navigate('../devices')
    // setTimeout(()=>{navigate(`../devices/${id}`)},500)

    const response1 = await getDeviceByOrganization({organizationId,applicationId})
    const response2 = await getDeviceById({id})
      
      // console.log(response1.data,'response.data')
      dispatch({ type: SET_DEVICEDATA, payload: { value:response1.data, fetchStatus: true } })
      dispatch({ type: SET_SELECTEDDEVICEDATA, payload: { value:response2.data, fetchStatus: true } })
      navigate('../home')
      navigate('../home/devices')
      setTimeout(()=>{navigate(`../devices/${id}`)},50)

  }
  

}

// console.log(device.deviceActiveStatus,'device.deviceActiveStatus')
const refreshDataGrid=useCallback(async()=>{
  setLoadingVisibility(true)
  const response = await getDeviceByOrganization({organizationId,applicationId})
    // const response2 = await getDeviceById({id})
      
      // console.log(response1.data,'response.data')
      if (response.status === 200 && response.data.length !== 0) {
      dispatch({ type: SET_DEVICEDATA, payload: { value:response.data, fetchStatus: true } })
      setLoadingVisibility(false)
      }
      // dispatch({ type: SET_SELECTEDDEVICEDATA, payload: { value:response2.data, fetchStatus: true } })
    // navigate('../devices')
    // setTimeout(()=>{navigate(`../devices/${id}`)},500)
},[])
return (
    <div>
      <Fragment>
        {device ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant='h5'> <KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate('../devices')} style={{
                width: "25px", height: "25px", marginRight: '25px',
                borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
              }} /> Device - {device.name} </Typography>
              
              {device.deviceStatus === 'Enable' ?
              (<Button variant='contained' sx={{ backgroundColor: 'RED', ":hover": { backgroundColor: 'RED' } }} onClick={() => { handleClick(statusList[4]) }}><PauseIcon sx={{color:'#fff'}}/>Disable</Button>)              
              :
              (<Button variant='contained' sx={{ backgroundColor: 'GREEN', ":hover": { backgroundColor: 'GREEN' } }} onClick={() => { handleClick(statusList[1]) }}><PlayArrowIcon sx={{color:'#fff'}}/>Enable</Button>)            
            }
              {device.deviceActiveStatus === 'Active' ?
              (<Button variant='contained' sx={{ backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} onClick={() => { handleActiveClick(statusList[3]) }}>Stop</Button>)
              :
              (<Button variant='contained' sx={{ backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} onClick={() => { handleActiveClick(statusList[2]) }}>Start</Button>)
          }
              <div>
                <Typography variant='inherit'>Secret Key      : {device.secretId} </Typography>
                <Typography variant='inherit'>Secret Password :{device.secretId}  </Typography></div>
            </div> <hr /> <br />
            {device.deviceStatus === 'Enable' ?
            (<>
            <Button variant='contained' sx={{ backgroundColor: '#660066',float:'right', ":hover": { backgroundColor: '#660066' } }} onClick={refreshDataGrid}>
            {loadingVisbility?<img width='25' height='25' style={{marginRight:4}} src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>:<></>}Refresh</Button>
            <br/>
            <br/>
            <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%', '&header': { backgroundColor: '#660066' } }}>
              <GridView columnDefs={columnDefs} rowData={alertDevice}/>
            </Box>
            {/*<Grid container columnSpacing={2} sx={{ mt: 15 }}>
               <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Total Number of times Door Opened
                      </Typography>
                      <NoofTimesDoorOpened data={deviceAlertsByMonthService.chartResponseOpened} height={300} width={500} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Total Power Saved in Hours
                      </Typography>
                      <TotalPowerSavedinHours data={deviceAlertsByMonthService.chartResponseClosed} height={300} width={500} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid> */}
            <Grid container columnSpacing={2} sx={{ mt: 15 }}>
              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                       Total Units Saved
                      </Typography>
                      <TotalUnitsSaved data={deviceAlertsByMonthService.chartResponseClosed} height={300} width={500}  />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        
                        Total Cost Saved in Rs.
                      </Typography>
                      <TotalCostSaved data={deviceAlertsByMonthService.chartResponseClosed} height={300} width={500}/>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid></>):<h2>Device Not Enabled</h2>}
          </>
        ) : (
          <></>
        )}
      </Fragment></div>
  )
}

export default DeviceView



