import { combineReducers } from 'redux'
import organizationReducer from './organizationReducer'
import roleReducer from './roleReducer'
import userReducer from './userReducer'
import loadingReducer from './loadingReducer'
import applicationReducer from './applicationReducer'
import alertReducer from './alertReducer'
import buildingReducer from './buildingReducer'
import floorReducer from './floorReducer'
import deviceReducer from './deviceReducer'
import roomReducer from './roomReducer'
import alertDeviceReducer from './alertDeviceReducer'
import instrumentReducer from './instrumentsReducer'
import studentDataReducer from './studentDataReducer'
import alertFarmReducer from './alertFarmGuardReducer'
const reducer = combineReducers({
    user : userReducer,
    role: roleReducer,
    organization: organizationReducer,
    loading: loadingReducer,
    application: applicationReducer,
    alerts: alertReducer,
    buildings: buildingReducer,
    floors: floorReducer,
    rooms: roomReducer,
    devices: deviceReducer,
    alertDevices: alertDeviceReducer, 
    instruments:instrumentReducer,
    studentData:studentDataReducer,
    farmGuardAlerts:alertFarmReducer,
})

export default reducer