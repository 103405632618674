import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Grid, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { FC, ReactElement, useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { updateDevice } from 'src/services/deviceService';
import { RootState } from "src/store";
import { IBuilding, IFloor, IRoom } from "src/types";

import AddInstruments from './AddInstruments';
type AlertType = "error" | "success" | "info" | "warning" | undefined;
interface EditDeviceProps {
    visibility: boolean,
    setVisibility: (a: boolean) => void,
    refreshHome: () => void,
    deviceId:string,
    statusData:(a: boolean)=>void;
    messagesData:(a:AlertType)=>void
}



const EditDevice: FC<EditDeviceProps> = (props: EditDeviceProps): ReactElement => {

    const [formData, setFormData] = useState({
        name: '',
        buildingId: '',
        floorId: '',
        roomId: ''
    })

    const { visibility, setVisibility, refreshHome,deviceId,statusData,messagesData } = props;



    const { name, buildingId, floorId, roomId } = formData;

    const[instrumentModel,setInstrumentModel]=useState(false)
    const devices=useSelector((state:RootState)=>state.devices.devices)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const floors = useSelector((state: RootState) => state.floors.floors)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    const device=devices.filter((x:any)=>x._id===deviceId)
    const deviceItem=device[0]

    const handleClick = async (e: any) => {
        e.preventDefault();
        const totalWatts=editedInstruments.filter((x:any)=>x.instrumentName!=='').reduce((prev,cur)=>{return prev + +cur.watts},0)
        const totalQuantity=editedInstruments.filter((x:any)=>x.instrumentName!=='').reduce((prev,cur)=>{return prev + +cur.quantity},0)
        const response = await updateDevice({ _id:deviceId,name:name, buildingId:buildingId,floorId:floorId, roomId:roomId,totalWattsOfInstruments:totalWatts,instrumentList:editedInstruments,quantity:totalQuantity })
        if (response.status === 200) {
            refreshHome()
            statusData(true)
            messagesData('info')
            setVisibility(false)
        }
    }

    const handleClose = () => {
        setVisibility(false)
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const [editedInstruments,setEditedInstruments] =useState([{_id:'', instrumentName: '', quantity: 0,watts:0, isSelected: false,isItemSelected:false }])
    const instrumentsList=(data:any)=>{
        setEditedInstruments([])
        // console.log(data,'############data')
        setEditedInstruments(data)
        
    }

   useEffect(()=>{
    if(device.length!==0){
        setEditedInstruments(deviceItem.instrumentList)
        setFormData(deviceItem)
    }
    
   },[deviceItem])
        
    
    // console.log(editedInstruments,'addedInstruments_New')
    return (
        <>
        {device.length!==0?
        <Dialog open={visibility} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" sx={{color:'#660066'}}> Edit Device </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid spacing={3}>
                        <TextField color="secondary" sx={{ mb: 1,color:'#660066' }} onChange={handleChange}  value={name} fullWidth id="name" name="name" label="Name" variant="standard" />

                        <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-lablel"> Building </InputLabel>
                            <Select labelId="demo-simple-select-label"  sx={{ mb: 1 }} name="buildingId" onChange={handleChange} value={buildingId}>
                               
                                {
                                    buildings.map((x: IBuilding) => {
                                        return <MenuItem value={x.id} selected key={x.id} >{x.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-lablel"> Floor </InputLabel>
                            <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="floorId" onChange={handleChange} value={floorId}>
                                <MenuItem value=""><em> None </em></MenuItem>
                                {
                                    floors.filter((x: IFloor) => x.buildingId === buildingId).map((x: IFloor) => {
                                        return <MenuItem value={x.id} selected key={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-lablel"> Room </InputLabel>
                            <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="roomId" onChange={handleChange} value={roomId}>
                                <MenuItem value=""><em> None </em></MenuItem>
                                {
                                    rooms.filter((x: IRoom) => (x.buildingId === buildingId && x.floorId === floorId )).map((x: IFloor) => {

                                        return <MenuItem value={x.id} selected key={x.id}>{x.number}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                        {/* <FormControl color="secondary" fullWidth variant="standard" >
                         <div style={{ fontWeight:'bold',display:'flex',justifyContent:'space-between' }}>
                         <Typography sx={{marginTop:'5px',fontWeight:'bold'}}>Instrument Name</Typography>
                         <Typography sx={{fontWeight:'bold'}}>Quantity</Typography>  
                            <Typography sx={{fontWeight:'bold'}}>Watts</Typography>                                                     
                            </div>                                                       
                        </FormControl> */}
                        
                        <FormControl color="secondary" fullWidth variant="outlined" >
                        <Typography sx={{fontWeight:'bold',fontSize:25,color:'#660066'}}>Instruents List :-</Typography>
                        <table style={{border:'1px solid grey'}}>
                        
                        <tr style={{border:'1px solid grey'}}>
                                <th style={{textAlign:'left',color:'#660066'}}>Sl.No</th>
                                <th style={{textAlign:'left',color:'#660066'}}>Instrument Name</th>
                                <th style={{textAlign:'left',color:'#660066'}}><Typography sx={{fontWeight:'bold'}}>Quantity</Typography>  </th>
                                <th style={{textAlign:'left',color:'#660066'}}><Typography sx={{fontWeight:'bold',alignItems:'left'}}>Watts</Typography></th>
                            </tr>
                        {editedInstruments.filter((x:any)=>x.instrumentName!=='' && x.isItemSelected===true ).map((data:any,index:any)=>{
                            return  <tr  key={index}>
                            <td style={{textAlign:'left',color:'#000'}}><Typography sx={{marginTop:'5px'}}>{index+1}</Typography></td>
                            <td style={{textAlign:'left',color:'#000'}}><Typography sx={{marginTop:'5px'}}>{data.instrumentName}</Typography></td>
                            <td style={{textAlign:'center',color:'#000'}}><Typography sx={{}}>{data.quantity}</Typography></td>
                            <td style={{textAlign:'center',color:'#000'}} ><Typography sx={{}}>{data.watts}</Typography></td>                                                  
                            </tr>
                            
                        })}
                        <tr style={{border:'1px solid grey'}}>
                            <td style={{textAlign:'left',color:'#000'}}><Typography sx={{marginTop:'5px'}}>{}</Typography></td>
                            <td style={{textAlign:'left',color:'#660066'}}><Typography sx={{marginTop:'5px',fontWeight:'bold',color:'#660066'}}>Total</Typography></td>
                            <td style={{textAlign:'center',color:'#660066'}}><Typography style={{marginTop:'5px',fontWeight:'bold',color:'#660066'}}>{editedInstruments.filter(x=>x.instrumentName!=='').reduce((prev,cur)=>{return prev + +cur.quantity},0)}</Typography></td>
                            <td style={{textAlign:'center',color:'#660066'}} ><Typography style={{marginTop:'5px',fontWeight:'bold',color:'#660066'}}>{editedInstruments.filter(x=>x.instrumentName!=='').reduce((prev,cur)=>{return prev + +cur.watts},0)}</Typography></td>                                                  
                            </tr>
                                                   
                        </table>
                            
                        </FormControl>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                
                <Button sx={{backgroundColor:'#660066',color:'#fff',float:'left',":hover":{backgroundColor:'#660066'}}} onClick={()=>setInstrumentModel(true)}> {editedInstruments.filter((x:any)=>x.instrumentName!=='').length===0 ? '+Add Instruments' : 'Edit Instruments' } </Button>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClose}> Cancel </Button>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClick} autoFocus> Update </Button>
            </DialogActions>
            <AddInstruments visibilityOfInstruments={instrumentModel} setVisibilityOfInstruments={setInstrumentModel} selectedInst={instrumentsList} defaultSelected={deviceItem.instrumentList}></AddInstruments>
            
        </Dialog>
        :<></>}




        </>
    )

}

export default EditDevice;




