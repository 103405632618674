import moment from "moment";
import React, { FC, ReactElement,useState } from "react";
import { PieChart, Pie, Cell,Sector} from "recharts";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

interface NumberOfUnits {
    data : any,
    height : number,
    width : number,
    watts?:number 
}


const RenderActiveShape:FC<any> = (props:any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props; 
  // const NewWatts=(watts===undefined||0)?140:(watts)
  // 
  // console.log(NewWatts,'TotalUnitsSavedWatt')
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {moment(`${payload._id.month}/01/${payload._id.year}`).format('MMM-YYYY')}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${(value).toFixed(0)} Units`}</text>
    </g>
  );
};




const NumberOfUnits: FC<NumberOfUnits> = (Props : NumberOfUnits) : ReactElement => {
  
  const colors = scaleOrdinal(schemeCategory10).range();
  const {data} = Props

  const[activeIndex,setActiveIndex]=useState(0)
    const onPieEnter=(_:any,index:any)=>{
      setActiveIndex(index)
    }
  return (
    <PieChart width={Props.width} height={Props.height} >
      <Pie
        activeIndex={activeIndex}
        activeShape={<RenderActiveShape/>}
        dataKey='total_units'
        isAnimationActive={false}
        data={data}
        cx={250}
        cy={125}
        outerRadius={80}
        innerRadius={55}
        paddingAngle={5}
        onMouseEnter={onPieEnter}
        fill="#8884d8"
      >
        {data.map((entry:any, index:any) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]}  />
        ))}
        </Pie>
    </PieChart>
  );
}

export default NumberOfUnits;