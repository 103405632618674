import http from "./http-common";

export const getRoom = async (formData: any) => {
    return http.get<any>(`/room`, formData);
}

export const getRoomById = async (formData: any) => {


    return http.post<any>(`/room/roomById`, formData);
}

export const getRoomByOrganization = async (formData : any) => {
    return http.post<any>(`/room/roomByOrganization`, formData);

}

export const createRoomService = async (formData: any) => {
    return http.post<any>(`/room/create`, formData);
}

export const updateRoom = async (formData: any) => {
    return http.post<any>(`/room/update`, formData);
}

export const deleteRoomService = async (formData: any) => {
    return http.post<any>(`/room/delete`, formData);
}

export const getRoomDashboardDetails = async (formData: any) => {
    return http.post<any>(`/room/deviceAlertByRoom`, formData)
}