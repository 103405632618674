import { IRoleState, IAction } from "src/types";
import { SET_ROLEDATA, RESET_ROLE } from "../actions/actionTypes";

const initalState : IRoleState = {
    id: '',
    name : '',
    fetchStatus: false
}
const roleReducer = (state : IRoleState = initalState, action: IAction) => {

    const {type, payload} = action;

    switch(type) {
        case SET_ROLEDATA:
            return {
                ...state,
                 name: payload.name,
                 id: payload.id
            }
        case RESET_ROLE:
            return {
                ...state,
                initalState
            }
        default:
            return state
    }
}

export default roleReducer