import React from 'react';
import { Typography, Button, Box, Grid,TextField,MenuItem } from '@mui/material';
import { FC, Fragment, ReactElement, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import CreateFloor from './CreateFloor';
import DeleteFloor from './DeleteFloor';
import { useNavigate } from 'react-router-dom';
import { ColDef, GroupCellRendererParams, ValueGetterParams } from 'ag-grid-community';
import moment from 'moment';
import GridView from 'src/components/homePage/GridView';
import { RemoveRedEye, Delete } from '@mui/icons-material';
import '../../../screens/SwitchApps.css';
import dateComparator, { getBuildingData } from 'src/utils/commonMethods';
import { refreshBuildingData } from 'src/store/actions/buildingActions';
import { refreshFloorData } from 'src/store/actions/floorActions';
import RefreshDoorLockData from '../refreshDoorLockData';
import { SET_SELECTEDFLOORDATA } from 'src/store/actions/actionTypes';
import { IFloor,IBuilding } from 'src/types';

import Notification from '../../../components/homePage/Notifications/notification'
interface deleteModalProps {
    id: string,
    name: string,
    visible: boolean
}

type AlertType = "error" | "success" | "info" | "warning" | undefined;
const FloorHome: FC = (): ReactElement => {
    const [createModelopen, setCreateModelopen] = useState(false);
    const [deleteModal, setDeleteModal] = useState<deleteModalProps>({ id: '', name: '', visible: false })
    const { id: deleteModalId, name: deleteModalName, visible: deleteModalVisible } = deleteModal;

    const floors = useSelector((state: RootState) => state.floors.floors)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onhandleClick = (data: IFloor) => {
        dispatch({ type: SET_SELECTEDFLOORDATA, payload: data })
        navigate(`/home/floors/${data._id}`)
    }

    const columnDefs: ColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            cellRenderer: (params: GroupCellRendererParams) => {
                return <Button onClick={() => onhandleClick(params.data)}> {params.data.name}</Button>
            }
        },
        {
            field: 'buildingId',
            headerName: 'Building',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            valueGetter: (params: ValueGetterParams) => {
                const val = getBuildingData(buildings, params.data.buildingId)
                return val ? val.name : 'NA'
            }
        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            filter: 'agTextColumnFilter',
            comparator: dateComparator,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            valueGetter: (params: ValueGetterParams) => {
                return moment(params.data.createdDate).format('DD/MM/YYYY | HH:mm:ss')
            },
            sort: 'desc'
        },
        {
            field: 'actions',
            headerName: 'Action',
            cellRenderer: (params: GroupCellRendererParams) => (
                <Grid container spacing={4}>
                    <Grid item><RemoveRedEye sx={{ color: '#660066', mt: 1 }} onClick={() => onhandleClick(params.data)} /></Grid>
                    <Grid item><Delete sx={{ color: '#660066', mt: 1 }} onClick={() => setDeleteModal({ id: params.data._id, name: params.data.name, visible: true })} /></Grid>
                </Grid>)
        },
    ], [floors, buildings]);

    const refreshHome = () => {
        refreshBuildingData(dispatch)
        refreshFloorData(dispatch)
    }

    useMemo(() => {
        refreshHome()
    }, [])
    const refreshDataGrid = () => {
        navigate('../home')
        setTimeout(() => { navigate(`/home/floors`) }, 500)
    }
    const [status,setStatus]=useState(false)
    const statusData=(data:boolean)=>{
        setStatus(data)
    }
    
    const [message,setMessage]=useState<AlertType>('success')
    const messagesData=(data:AlertType)=>{
        setMessage(data)
    }
    const [filterParams,setFilterParams]=useState('')
    const [types,setTypes]=useState('')
    const [filters,setFilters]=useState('')
    const[visibility,setVisibility]=useState(false)
    const [buildingName,setBuildingName]=useState<any[]>([])
    useEffect(()=>{
        setBuildingName([])
        buildings.map((x:IBuilding)=>{
            setBuildingName((prev:any)=>[...prev,x.name])
        })
    },[buildings])

    const floorName:any[]=[]
    const [floordata,setFloorData]=useState([''])
    useEffect(()=>{
        floors && floors.map((x:any)=>{
            if(!floorName.includes(x.name)){
                floorName.push(x.name)
                
            }
            
        })
        setFloorData(floorName)

    },[floors])

    
    const handleEventChange=(e:any)=>{
        e.preventDefault()
        const name=e.target.name.split(',')
        setTypes(name[1])
        setFilterParams(name[0])
        setFilters(e.target.value)
    }
    const[clearFilter,setClearFilter]=useState('')
    const applyClearFilter=()=>{
        setClearFilter('clear')
        setTimeout(()=>{
            setClearFilter('')
            setVisibility(!visibility)
        // setTypes('')
        // setFilterParams('')
        // setFilters('')
        },1000)
    }
    return (
        <Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h5'> Floors </Typography>
                <Button variant='contained' sx={{ backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} onClick={() => { setCreateModelopen(true) }}>+Add Floor</Button>
            </div>
            <div>
            <button onClick={()=>{setVisibility(!visibility)}}>Apply Filters!</button>
            <button onClick={applyClearFilter}>Clear Filters!</button>
            </div>
            {visibility?<Box sx={{height:'50px'}}>
                <TextField name='buildingId,equals' select onChange={handleEventChange} defaultValue=''
                label='Select Building' size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}}>
                {buildingName && buildingName.sort().map((x:string)=>{
                        return <MenuItem key={x} value={x}>{x}</MenuItem>
                    })}
                </TextField>
                <TextField name='name,contains' select onChange={handleEventChange}
                label='Select Floor' size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}}>
                {floordata && floordata.map((x:string)=>{
                        return <MenuItem key={x} value={x}>{x}</MenuItem>
                    })}
                </TextField>

                </Box>:<></>}
            <hr />
            <Button variant='contained' sx={{ backgroundColor: '#660066', float: 'right', ":hover": { backgroundColor: '#660066' } }} onClick={refreshDataGrid}>Refresh</Button>
            <br />
            <br />
            <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs} rowData={floors} types={types} filters={filters} filterParams={filterParams} clear={clearFilter}/>
            </Box>
            <CreateFloor visibility={createModelopen} setVisiblity={setCreateModelopen} refreshHome={refreshHome} messagesData={messagesData} statusData={statusData} />
            <DeleteFloor visibility={deleteModalVisible} setVisiblity={setDeleteModal} refreshHome={refreshHome} data={{ name: deleteModalName, id: deleteModalId }} messagesData={messagesData} statusData={statusData} />
            <Notification message={message} status={status}/>
            <RefreshDoorLockData />
        </Fragment>
    )
}

export default FloorHome