import { Dispatch } from "react"
import { CREATE_FLOOR, UPDATE_FLOOR, DELETE_FLOOR, SET_FLOORDATA, FLOOR_DASHBOARD_DETAILS, GROUP_DATA_BY_FLOORID } from "./actionTypes"

export const createFLOOR = (dispath: Dispatch<any>, floorName: string) => {
    dispath({type: CREATE_FLOOR, floorName})
}

export const updateFLOOR = (dispath: Dispatch<any>, floorId: string) => {
    dispath({type: UPDATE_FLOOR, floorId})
}

export const deleteFLOOR = (dispath: Dispatch<any>, floorId: string) => {
    dispath({type: DELETE_FLOOR, floorId})
}

export const refreshFloorData = (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_FLOORDATA, payload: { value: [], fetchStatus: false } })
}


export const floorDashBoardDetails=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:FLOOR_DASHBOARD_DETAILS,payload:{value:data}})
}
export const deviceDataGroupByFloorId=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:GROUP_DATA_BY_FLOORID,payload:{value:data}})
}