import { Button, Grid, Stack } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useParams,useNavigate } from 'react-router-dom'
import { RootState } from 'src/store'
import HistoryIcon from '@mui/icons-material/History';
import { getAlertsByOrganizationId } from '../../../../services/alertsFarmService'
import AlertView from './alertView'
import ImagesView from './imagesView'
import ChartView from './chartView'
import Draggable from 'react-draggable'
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Refresh';
import loader from '../../../../assets/svg/loader.gif'
import { SET_FARMALERTSBYCAMERA,SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE } from 'src/store/actions/actionTypes'
import { getAlertsByDeviceId,getFarmAlertCountByDeviceId } from 'src/services/alertsFarmService'
import { refreshFarmGuardDataByCamera } from 'src/store/actions/alertFarmGuardAction'
import folderViewBackgroundImage from '../assets/folderViewBackground.jpg'
const FolderView = () => {
    
    
    const {id}=useParams()
    const organizationId=useSelector((state:RootState)=>state.organization.id)
    const applicationId=useSelector((state:RootState)=>state.application.id)
    const farmGuardAlertsByCamera=useSelector((state:RootState)=>state.farmGuardAlerts.selectedCamera)
    const [selected, setSelected] = useState({ 'AlertNotifications': true, "AlertImages": false, "AlertCharts": false })
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const onclickStylesHandler1 = useCallback(() => {
        const styles1 = { fontStyle: 'italic', color: '#cc99cc' }
        const styles2 = { fontStyle: 'italic', color: '#660066', border: '2px solid #660066', borderRadius: '15px', marginTop: '25px' }

        if (selected.AlertImages === true) {
            return styles2
        }
        else if (selected.AlertImages === false) {
            return styles1
        }
        else {
            return styles1
        }

    }, [selected])
    const onclickStylesHandler2 = useCallback(() => {
        const styles1 = { fontStyle: 'italic', color: '#cc99cc' }
        const styles2 = { fontStyle: 'italic', color: '#660066', border: '2px solid #660066', borderRadius: '15px', marginTop: '25px' }

        if (selected.AlertNotifications === true) {
            return styles2
        }
        else if (selected.AlertNotifications === false) {
            return styles1
        }
        else {
            return styles1
        }

    }, [selected, onclickStylesHandler1])
    const onclickStylesHandler3 = useCallback(() => {
        const styles1 = { fontStyle: 'italic', color: '#cc99cc' }
        const styles2 = { fontStyle: 'italic', color: '#660066', border: '2px solid #660066', borderRadius: '15px', marginTop: '25px' }

        if (selected.AlertCharts === true) {
            return styles2
        }
        else if (selected.AlertCharts === false) {
            return styles1
        }
        else {
            return styles1
        }

    }, [selected, onclickStylesHandler1, onclickStylesHandler2])

   
    const [visibility,setVisibility]=useState(false)
    const refreshDataGrid=async()=>{
               setVisibility(true)
               const response1 = await getAlertsByDeviceId({deviceId: id, organizationId: organizationId, applicationId: applicationId })
               if(response1.status===200){
                   refreshFarmGuardDataByCamera(dispatch,response1.data)       
               }       
               const response2 = await getFarmAlertCountByDeviceId({deviceId:id, organizationId: organizationId, applicationId: applicationId })
               if(response2.status===200){
                   dispatch({type:SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE,payload:{dayResponse:response2.data.dayResponse,monthResponse:response2.data.monthResponse}})
               }   
               setTimeout(()=>{setVisibility(false)},1000)
            // navigate('../home/cameras/folderView')
            // setTimeout(() => { navigate(`/home/cameras/folderView/${id}`) }, 50)
    }
    return (
        <>
            <div style={{
                position: 'fixed', width: '82%', borderRadius: '5px', marginTop: '-21px', zIndex: 1000, height: '100px', alignItems: 'center', justifyContent: 'center',
                backgroundImage: `url(${folderViewBackgroundImage})`,
                backgroundSize: 'cover'
            }}>
                <Draggable>
            <Fab sx={{position:'fixed',zIndex:1000,marginTop:'25%'}} aria-label='Edit' color='secondary' onClick={refreshDataGrid}>
                        {visibility?<img src={loader} width='25px' height='25px'></img>:<EditIcon/>}
              </Fab>
            </Draggable>
                <Grid container xs={12} spacing={10}>
                    <Grid item xs={4} sx={{ textAlign: 'center' }}>
                        <Button onClick={() => setSelected({ 'AlertNotifications': true, "AlertImages": false, "AlertCharts": false })} sx={onclickStylesHandler2}>
                            <HistoryIcon style={{ width: '50px' }} />
                            <span style={{ width: '10px' }}>{' '}</span>
                            <h5>Alert Notifications</h5>
                        </Button>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'center' }}>
                        <Button onClick={() => setSelected({ 'AlertNotifications': false, "AlertImages": true, "AlertCharts": false })} sx={onclickStylesHandler1}>
                            <HistoryIcon width='10px' />
                            <span style={{ width: '10px' }}>{' '}</span>
                            <h5>Alert Images</h5>
                        </Button>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'center' }}>
                        <Button onClick={() => setSelected({ 'AlertNotifications': false, "AlertImages": false, "AlertCharts": true })} sx={onclickStylesHandler3}>
                            <HistoryIcon width='50px' />
                            <span style={{ width: '10px' }}>{' '}</span>
                            <h5>Alert Charts</h5>
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <div style={{ marginTop: '90px' }}>
                {selected && (selected.AlertNotifications === true && selected.AlertImages === false && selected.AlertCharts === false) ?

                    <AlertView alerts={farmGuardAlertsByCamera} />
                    : (selected.AlertNotifications === false && selected.AlertImages === true && selected.AlertCharts === false) ?
                        <ImagesView alerts={farmGuardAlertsByCamera} /> : (selected.AlertNotifications === false && selected.AlertImages === false && selected.AlertCharts === true) ?
                            <ChartView/> : <></>

                }
            </div>
        </>

    )
}
export default FolderView