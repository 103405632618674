import React from 'react';
import {
    SET_FARMALERTSBYORGANISATION, SET_FARMALERTSBYCAMERA,
    SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION,SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE,SET_FARM_ALERTS_REPORT_By_ORG
} from '../actions/actionTypes';
import { IAction, IAlertFarmState,IAlertFarmChart } from 'src/types';
const initialState: IAlertFarmState = {
    selectedCamera: [],
    farmAlerts: [],
    fetchStatus: false,
    report_By_Org:[],
    chart_By_org:{dayResponse:[{_id:{year:0,month:0,date:0},total_count:0}],monthResponse:[{_id:{year:0,month:0,date:0},total_count:0}]},
    chart_By_device:{dayResponse:[{_id:{year:0,month:0,date:0},total_count:0}],monthResponse:[{_id:{year:0,month:0,date:0},total_count:0}]}
}
const alertFarmReducer = (state: IAlertFarmState = initialState, action: IAction) => {
    const { type, payload } = action;
    // console.log(payload,'payload')
    switch (type) {
        case SET_FARMALERTSBYORGANISATION:
            return {
                ...state,
                farmAlerts: payload.value,
                fetchStatus: payload.fetchStatus
            }
        case SET_FARMALERTSBYCAMERA:
            return {
                ...state,
                selectedCamera: payload.value

            }
        case SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION:
            return {
                ...state,
                chart_By_org: {dayResponse:payload.dayResponse,monthResponse:payload.monthResponse}
            }
        case SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE:
            return {
                ...state,
                chart_By_device:{dayResponse:payload.dayResponse,monthResponse:payload.monthResponse}
            }
        case SET_FARM_ALERTS_REPORT_By_ORG:
            return{
                ...state,
                report_By_Org:payload.value
            }
        default:
            return state
    }
}

export default alertFarmReducer;
//{dayResponse:payload.dayResponse,monthResponse:[payload.monthResponse]}