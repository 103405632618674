import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
export default function SearchField(props:any) {

    const allApplications = useSelector((state: RootState) => state.application.allApplications)

    return (
      <Autocomplete
        freeSolo
        size="small"
        sx={{ width: 280,marginRight:'10px'}}
        disableClearable
        disableListWrap
        options={allApplications.map((option:any) => option.name)}      
        onChange={(event, value:any) => {props.getDetail(value.toLocaleLowerCase())}}
        renderInput={(params) => (
          <TextField
            variant="standard"
            placeholder='Search Product'            
            style={{backgroundColor:'white',borderRadius:'25px',paddingLeft:'5px'}}
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',disableUnderline: true,
            }}
            onChange={(event)=>{props.getDetail(event.target.value)}}
            />

        )}
      />
  ); 
}