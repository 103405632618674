import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { IAlertDevice, IBuilding, IFloor } from 'src/types';
import { getAlertByDevice } from 'src/services/alertDeviceService';
import { useParams } from 'react-router-dom';
import { IDevice } from "src/types";
import { getAllDeviceDataByOrganisation } from "src/services/deviceService";
import DoorClosed from '../../../assets/svg/Door Closed.png'
import DoorOpened from '../../../assets/svg/Door Open.png'
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Refresh';
import loader from '../../../assets/svg/loader.gif'

export default function DeviceGridView() {
    const devices = useSelector((state: RootState) => state.devices.devices)
    // console.log(devices,'devices')
    const floors = useSelector((state: RootState) => state.floors.floors)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    // console.log(buildings.id,'buildings')
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    const organization = useSelector((state: RootState) => state.organization)
    const application = useSelector((state: RootState) => state.application)
    // const [datas, setDatas] = React.useState<{[key: string]: string}>({})

    const navigate = useNavigate()
    const getBuildingData = (buildingId: string): string | undefined | null => {
        const buildingData = buildings.find((x: IBuilding) => x.id === buildingId)
        return buildingData ? buildingData.name : ''
    }
    const getBuildingId = (buildingName: string) => {
        const buildingData = buildings.find((x: IBuilding) => x.name === buildingName)
        return buildingData ? buildingData._id : ''
    }

    const getFloorData = (floorId: string): string | undefined | null => {
        const floorData = floors.find((x: IFloor) => x.id === floorId)
        return floorData ? floorData.name : ''
    }

    const getRoomData = (roomId: string): string | undefined | null => {
        const roomData = rooms.find((x: IFloor) => x.id === roomId)
        return roomData ? roomData.number : ''
    }

    //     const RefreshHome = async (data:IDevice) => {
    //         console.log(typeof(data.id),'typedata')
    //         const response = await getAlertByDevice({ deviceId:data.id })       
    //         if (response.status === 200 && response.data.length!==0) {
    //             const num=(response.data.length-1).toString()
    //             // let id=data.id.toString()        
    //             setDatas((alertPrev: {[key: string]: string})=>({...alertPrev,[data.id]:response.data[num].status}))
    //     }

    // }

    //         React.useEffect(()=>{
    //         {devices.map((data: IDevice, index: number) => {
    //         RefreshHome(data);})
    //         }
    //         },[])











    const styleSheet = {
        borderRight: "2px solid #660066",
        width: '5px',
        marginTop: '15px',
        marginBottom: '1px'
    }
    const gridStyle = { margin: '5px 5px' }
    const textStyle = { fontSize: '10px', color: '#000000', fontWeight: 'bold' }
    const textStyleSecondary = { fontSize: '10px', color: '#000000' }




    const [data1, setData1] = useState({
        response: [{
            _id: '',
            total_hours: 0,
            total_watts: 0,
            total_units: 0,
            total_cost_Saved: 0
        }, {
            _id: '',
            total_hours: 0,
            total_watts: 0,
            total_units: 0,
            total_cost_Saved: 0
        }],
        deviceStatus: [{
            _id: '',
            name:'',
            createdOn: '',
            status: "true",
            deviceStatus: "Enable",
            deviceActiveStatus: "Active"
        },
        {
            _id: '',
            name:'',
            createdOn: '',
            status: "true",
            deviceStatus: "Enable",
            deviceActiveStatus: "Active"
        }

        ]
    })

    console.log(data1,'data1')
    const allDeviceData = useCallback(async () => {
        const response = await getAllDeviceDataByOrganisation({ organizationId: organization.id, applicationId: application.id })
        if (response.status === 200) {
            setData1(response.data)
            // console.log(response.data,'allDeviceData')
        }
    }, [])


    useEffect(() => {
        allDeviceData();
    }, [devices])

    const deviceList = data1.deviceStatus.map((x: any) => x._id)

    const result = (data: IDevice) => {
        if (deviceList.includes(data.id)) {
            switch (data1.deviceStatus.filter((x: any) => x._id === data.id)[0].status) {
                case 'true':
                    return DoorClosed
                case 'false':
                    return DoorOpened
                default:
                    return DoorOpened
            }
        }
        else {
            return DoorOpened
        }

    }

    const colourStatus = (id: any) => {
        if (deviceList.includes(id)) {
            switch (data1.deviceStatus.filter((x: any) => x._id === id)[0].status) {
                case 'true':
                    return "linear-gradient(45deg,#F67280 50%,#F67280 50%)"
                case 'false':
                    return "linear-gradient(45deg,#00FA9A 50%,#00FA9A 50%)"
                default:
                    return "linear-gradient(45deg,#00FA9A 50%,#00FA9A 50%)"
            }
        }
        else {
            return "linear-gradient(45deg,#00FA9A 50%,#00FA9A 50%)"
        }
    }
    const [visibility, setVisibility] = useState(false)
    const refreshDataGrid = () => {
        setVisibility(true)
        // navigate('../')
        // setTimeout(()=>{navigate('../devicegridview')},50)
        allDeviceData();
        setTimeout(() => { setVisibility(false) }, 1000)
    }

    const [buildigName, setBuildingName] = useState(['All Details'])
    const [visibleBuilding, setVisibleBuilding] = useState(Object.values({ ...buildings.map((x: any) => { return x._id }) })[0])
    useEffect(() => {
        setBuildingName(() => [])
        {
            buildings && buildings.map((data: any, index: any) => (
                setBuildingName((prev: any[]) => [...prev, getBuildingData(data._id)])
            ))
        }
    }, [])
 
    // const NewDevices=[...devices].sort((a, b) =>
    // a.buildingId > b.buildingId ?1:-1,
    // );
    return (
        <div>
            <Fab sx={{ position: 'fixed', zIndex: 1000, marginTop: '25%' }} aria-label='Edit' color='secondary' onClick={refreshDataGrid}>
                {visibility ? <img src={loader} width='25px' height='25px'></img> : <EditIcon />}
            </Fab>
            <div style={{width:'81%',position:'fixed',marginTop:'-140px',borderRadius:'15px',minHeight:'150px',backgroundRepeat:'no-repeat',backgroundSize:'100%'}}>
            {buildigName && buildigName.sort().map((data:any,index:any)=>(
                    <Button  onClick={()=>{setVisibleBuilding(getBuildingId(data))}}   variant='contained' key={index} sx={{ backgroundColor:'#fff',color:'#660066',mt:2,mb:1,borderRadius:5,ml:5,fontWeight:'bold', ":hover": { backgroundColor: '#fff',color:'#660066' } }} >{data}</Button>
            ))}
        </div> 
            
            <Grid container spacing={2} sx={{mt:15}}  >

                {devices && devices.filter((x:any)=>x.buildingId===visibleBuilding).map((data: IDevice, index: any) => { 
                // {devices && devices.map((data: IDevice, index: any) => {
                    return (
                        <Grid item xs={4} key={index}>

                            <Card variant="outlined" key={index} style={{ borderRadius: '15px', background: `${colourStatus(data.id)}` }}>
                                <React.Fragment>
                                    <Grid container spacing={1} style={{ marginLeft: '2px' }}>
                                        <Grid item style={gridStyle}>
                                            <Typography style={textStyle} >
                                                Device
                                            </Typography>
                                            <Typography style={textStyleSecondary} >
                                                {data.name}
                                            </Typography>
                                        </Grid><div style={styleSheet}>{' '}</div>
                                        <Grid item style={gridStyle}>
                                            <Typography style={textStyle}>
                                                Room
                                            </Typography>
                                            <Typography style={textStyleSecondary}>
                                                {getRoomData(data.roomId)}
                                            </Typography>
                                        </Grid><div style={styleSheet}>{' '}</div>
                                        <Grid item style={gridStyle}>
                                            <Typography style={textStyle}>
                                                Floor
                                            </Typography>
                                            <Typography style={textStyleSecondary}>
                                                {getFloorData(data.floorId)}
                                            </Typography>
                                        </Grid><div style={styleSheet}>{' '}</div>
                                        <Grid item style={gridStyle}>
                                            <Typography style={textStyle}>
                                                Building
                                            </Typography>
                                            <Typography style={textStyleSecondary}>
                                                {getBuildingData(data.buildingId)}
                                            </Typography>
                                        </Grid><div style={styleSheet}>{' '}</div>
                                        <Grid item style={gridStyle}>
                                            <Typography style={textStyle}>
                                                Status
                                            </Typography>
                                            <img style={{ width: '40px', height: '40px' }}
                                                src={result(data)}>
                                            </img>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            </Card>
                        </Grid>)
                })}

            </Grid>
        </div>
    );
}
