import React from 'react';
import { Typography, Button, Grid, Box,TextField,MenuItem} from '@mui/material';
import { FC, Fragment, ReactElement, useMemo, useState, useRef, useCallback, useEffect,memo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store';
import { IBuilding, IFloor, IAlertDevice, IDevice } from 'src/types';
import dateComparator from 'src/utils/commonMethods';
import CreateDevice from './CreateDevice';
import DeleteDevice from './DeleteDevice';
import EditDevice from './EditDevice';
import { ColDef, GroupCellRendererParams, ValueGetterParams } from 'ag-grid-community';
import GridView from 'src/components/homePage/GridView';
import moment from 'moment';
import Delete from '@mui/icons-material/Delete';
import { RemoveRedEye } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { formatData } from "src/utils/commonMethods";
import { SET_DEVICEDATA } from "src/store/actions/actionTypes";

// import { Box } from '@mui/system';
import '../../../screens/SwitchApps.css';
import { refreshDeviceData } from 'src/store/actions/deviceActions';
import { refreshRoomData } from 'src/store/actions/roomActions';
import { refreshFloorData } from 'src/store/actions/floorActions';
import { refreshBuildingData } from 'src/store/actions/buildingActions';
import RefreshDoorLockData from '../refreshDoorLockData';
import { SET_SELECTEDDEVICEDATA } from 'src/store/actions/actionTypes';
import { getAlertByDevice } from 'src/services/alertDeviceService';
import { getAllDeviceDataByOrganisation, getSmokeDataByOrganisation, getDeviceByOrganization } from "src/services/deviceService";
import Notification from '../../../components/homePage/Notifications/notification'
import { Ttype } from 'src/components/homePage/GridView';
interface deleteModalProps {
    id: string,
    name: string,
    visible: boolean
}

// interface DeviceHomeProps {
// }
type AlertType = "error" | "success" | "info" | "warning" | undefined;
const DeviceHome: FC = (props): ReactElement => {

    const [createModelopen, setCreateModelopen] = useState(false);
    const [editModelopen, setEditModelopen] = useState(false);
    const [deleteModal, setDeleteModal] = useState<deleteModalProps>({ id: '', name: '', visible: false })
    const devices = useSelector((state: RootState) => state.devices.devices)
    // console.log(devices,'devices details')
    // const devices1 = useSelector((state: RootState) => state.devices.devices)
    const floors = useSelector((state: RootState) => state.floors.floors)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    const organization = useSelector((state: RootState) => state.organization)
    const application = useSelector((state: RootState) => state.application)
    const allDeviceDataByOrg = useSelector((state:RootState)=>state.devices.allDeviceDataByOrg)
    const { id: deleteModalId, name: deleteModalName, visible: deleteModalVisible } = deleteModal;
    const [loadingVisbility, setLoadingVisibility] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const alerts = useSelector((state: RootState) => state.alerts)
    const onhandleClick = (data: IDevice) => {
        dispatch({ type: SET_SELECTEDDEVICEDATA, payload: data })
        navigate(`/home/devices/${data._id}`)
    }

    const [editId, setEditId] = useState('')
    const refreshDataGrid = useCallback(async () => {
        setLoadingVisibility(true)
        const organizationId = organization.id
        const applicationId = application.id
        const response = await getDeviceByOrganization({ organizationId, applicationId })
        if (response.status === 200 && response.data.length !== 0) {
            dispatch({ type: SET_DEVICEDATA, payload: { value: response.data.map((x: any) => formatData(x)), fetchStatus: true } })
            setLoadingVisibility(false)}
        // navigate('/home/dashboard')
        // setTimeout(()=>{navigate(`../devices/`)},100)
    }, [])
    

    const getBuildingData = (buildingId: string): string | undefined | null => {
        const buildingData = buildings.find((x: IBuilding) => x.id === buildingId)
        return buildingData ? buildingData.name : ''
    }

    const getFloorData = (floorId: string): string | undefined | null => {
        const floorData = floors.find((x: IFloor) => x.id === floorId)
        return floorData ? floorData.name : ''
    }

    const getRoomData = (roomId: string): string | undefined | null => {
        const roomData = rooms.find((x: IFloor) => x.id === roomId)
        return roomData ? roomData.number : ''
    }

    // const[smokeData,setSmokeData]=useState([])
    // const allSmokeData=useCallback(async ()=>{
    //     const response=await getSmokeDataByOrganisation({organizationId:organization.id})
    //     if(response.status===200){
    //         setSmokeData(response.data)
    //         // console.log(response.data)
    //     }
    // },[])

    // useEffect(()=>{
    //     allSmokeData();
    // },[datas])//,devices
    console.log('allDeviceDataByOrg.response',allDeviceDataByOrg.response)
    const deviceList = allDeviceDataByOrg.deviceStatus.map((x: any) => x._id)
    // const defaultFilterParams = { readOnly: true };
    const columnDefs: ColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: 'Name',
            initialWidth: 200,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            filter: 'agTextColumnFilter',
            cellRenderer: (params: GroupCellRendererParams) => {
                return <Button onClick={() => onhandleClick(params.data)}> {`Device - ${params.data.name}`}</Button>
            }
        },
        // {
        //     field: 'Id',
        //     headerName: 'Id',
        //     initialWidth: 75,
        //     maxWidth: 100,
        //     filterParams: {
        //         buttons: ['reset', 'apply'],
        //     },
        //     flex: 1,
        //     filter: 'agTextColumnFilter', valueGetter: (params: ValueGetterParams) => {
        //         const val = params.data.id
        //         return val !== '' ? val : 'NA'
        //     }
        // },
        {
            field: 'roomId',
            headerName: 'Room',
            initialWidth: 75,
            maxWidth: 100,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            flex: 1,
            filter: 'agTextColumnFilter', valueGetter: (params: ValueGetterParams) => {
                const val = getRoomData(params.data.roomId)
                return val !== '' ? val : 'NA'
            }
        },
        {
            field: 'floorId',
            headerName: 'Floor',
            initialWidth: 75,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            flex: 1,
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                const val = getFloorData(params.data.floorId)
                return val !== '' ? val : 'NA'
            }
        },
        {
            field: 'buildingId',
            headerName: 'Building',
            initialWidth: 200,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            flex: 1,
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                const val = getBuildingData(params.data.buildingId)
                return val !== '' ? val : 'NA'
            },
            // filterParams: defaultFilterParams

        },
        {
            field: 'Date',
            headerName: 'Created Date & Time',
            initialWidth: 150,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            filter: 'agTextColumnFilter',
            comparator: dateComparator,
            wrapHeaderText: true,
            valueGetter: (params: ValueGetterParams) => {
                return moment(params.data.createdDate).format('DD/MM/YYYY | HH:mm:ss')
            },
            sort: 'desc'
        },
        {
            field: 'cost',
            headerName: 'Cost Saved curr month in Rs.',
            initialWidth: 200,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            flex: 1,
            filter: 'agTextColumnFilter',
            wrapHeaderText: true,
            valueGetter: (params: ValueGetterParams) => {
                switch ((allDeviceDataByOrg.response.filter((x: any) => x._id === params.data.id)).length === 0) {
                    case true:
                        return 0;
                    case false:
                        return (Number((allDeviceDataByOrg.response.filter((x: any) => x._id === params.data.id))[0].total_cost_Saved)).toFixed(2) !== 'NaN' ? (Number((allDeviceDataByOrg.response.filter((x: any) => x._id === params.data.id))[0].total_cost_Saved)).toFixed(2) : 0;
                    default:
                        return 0;
                }
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            initialWidth: 200,
            filterParams: {
                buttons: ['reset', 'apply'],
            },
            flex: 1,
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                if (deviceList.includes(params.data.id)) {
                    switch (allDeviceDataByOrg.deviceStatus.filter((x: any) => x._id === params.data.id)[0].status) {
                        case 'true':
                            return 'Door Closed'
                        case 'false':
                            return 'Door Opened'
                        case 'Restarted':
                            return 'Restarted'
                        default:
                            return 'Door Opened'
                    }
                }

                else {
                    return 'Door Opened'
                }

            }

        },
        {
            field: 'action',
            headerName: 'Actions',
            cellRenderer: (params: GroupCellRendererParams) => (
                <Grid container spacing={4}>
                    <Grid item><RemoveRedEye sx={{ color: '#660066', mt: 1 }} onClick={() => onhandleClick(params.data)} /></Grid>
                    <Grid item><EditIcon sx={{ color: '#660066', mt: 1 }} onClick={() => { setEditModelopen(true); setEditId(params.data._id) }} /></Grid>
                    <Grid item><Delete sx={{ color: '#660066', mt: 1 }} onClick={() => setDeleteModal({ id: params.data._id, name: params.data.name, visible: true })} /></Grid>
                </Grid>
            )
        }
    ], [devices, rooms, floors, buildings, allDeviceDataByOrg]);


    // const columnDefs1: ColDef[] = useMemo(() => [
    //     {
    //         field: 'name',
    //         headerName: 'Name',
    //         initialWidth: 200,
    //         wrapHeaderText: true,
    //         flex: 1,
    //         filterParams: {
    //             buttons: ['reset', 'apply'],
    //           },
    //         filter: 'agTextColumnFilter',
    //         valueGetter: (params: ValueGetterParams) => {
    //             return `Device - ${params.data.deviceData.name}`
    //         }
    //     },
    //     {
    //         field: 'roomId',
    //         headerName: 'Room',
    //         initialWidth: 100,
    //         filterParams: {
    //             buttons: ['reset', 'apply'],
    //           },
    //         flex: 1,
    //         filter: 'agTextColumnFilter', valueGetter: (params: ValueGetterParams) => {
    //             const val = getRoomData(params.data.deviceData.roomId)
    //             return val !== '' ? val : 'NA'
    //         }
    //     },
    //     {
    //         field: 'floorId',
    //         headerName: 'Floor',
    //         initialWidth: 100,
    //         filterParams: {
    //             buttons: ['reset', 'apply'],
    //           },
    //         flex: 1,
    //         filter: 'agTextColumnFilter',
    //         valueGetter: (params: ValueGetterParams) => {
    //             const val = getFloorData(params.data.deviceData.floorId)
    //             return val !== '' ? val : 'NA'
    //         }
    //     },
    //     {
    //         field: 'buildingId',
    //         headerName: 'Building',
    //         initialWidth: 150,
    //         filterParams: {
    //             buttons: ['reset', 'apply'],
    //           },
    //         flex: 1,
    //         filter: 'agTextColumnFilter',
    //         valueGetter: (params: ValueGetterParams) => {
    //             const val = getBuildingData(params.data.deviceData.buildingId)
    //             // const val =buildings.filter((x: IBuilding) => x.id ===params.data.deviceData.buildingId)[0]
    //             return val !== '' ? val : 'NA'
    //         }

    //     },
    //     {
    //         field: 'Date',
    //         headerName: 'Created Date & Time',
    //         initialWidth: 200,
    //         filterParams: {
    //             buttons: ['reset', 'apply'],                
    //           },
    //         filter: 'agTextColumnFilter',
    //         comparator:dateComparator,
    //         wrapHeaderText: true,
    //         valueGetter: (params: ValueGetterParams) => {
    //             return moment(params.data.smokeData.smokeDetectedOn).format('DD/MM/YYYY | HH:mm:ss')
    //         },
    //         sort: 'desc'
    //     },

    //     {
    //         field: 'status',
    //         headerName: 'Status',
    //         initialWidth: 200,
    //         filterParams: {
    //     buttons: ['reset', 'apply'],
    //   },
    //         flex: 1,
    //         filter: 'agTextColumnFilter',
    //         valueGetter: (params: ValueGetterParams) => {      
    //             return 'Smoke Detected'

    //               }

    //     },

    // ], [devices, rooms, floors, buildings,datas,data1,watts]);

    const refreshHome = () => {
        refreshBuildingData(dispatch)
        refreshFloorData(dispatch)
        refreshRoomData(dispatch)
        refreshDeviceData(dispatch)
    }

    useMemo(() => {
        refreshHome()
    }, [])



    const [status, setStatus] = useState(false)
    const statusData = (data: boolean) => {
        setStatus(data)
    }

    const [message, setMessage] = useState<AlertType>('success')
    const messagesData = (data: AlertType) => {
        setMessage(data)
    }

    const [filterParams,setFilterParams]=useState('')
    const [types,setTypes]=useState('')
    const [filters,setFilters]=useState('')
    const[visibility,setVisibility]=useState(false)
    const [buildingName,setBuildingName]=useState<any[]>([])
    useEffect(()=>{
        setBuildingName([])
        buildings.map((x:IBuilding)=>{
            setBuildingName((prev:any)=>[...prev,x.name])
        })
    },[buildings])

    const floorName:any[]=[]
    const [floordata,setFloorData]=useState([''])
    useEffect(()=>{
        floors && floors.map((x:any)=>{
            if(!floorName.includes(x.name)){
                floorName.push(x.name)
                
            }
            
        })
        setFloorData(floorName)

    },[floors])

    const roomName:any[]=[]
    const [roomdata,setRoomData]=useState<any[]>([])
    useEffect(()=>{
        rooms && rooms.map((x:any)=>{
            if(!roomName.includes(x.number)){
                roomName.push(Number(x.number))
                
            }
            
        })
        setRoomData(roomName.sort(function(a,b){return a - b}))

    },[rooms])
    const handleEventChange=(e:any)=>{
        e.preventDefault()
        const name=e.target.name.split(',')
        setTypes(name[1])
        setFilterParams(name[0])
        setFilters(e.target.value)
    }
    const[clearFilter,setClearFilter]=useState('')
    const applyClearFilter=()=>{
        setClearFilter('clear')
        setTimeout(()=>{
            setClearFilter('')
            setVisibility(!visibility)
        // setTypes('')
        // setFilterParams('')
        // setFilters('')
        },1000)
    }
    const alertStatus=['Door Opened','Door Closed','Restarted']
    return (
        <Fragment>
            
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h5'> Devices </Typography>
                <Button variant='contained' sx={{ backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} onClick={() => { navigate('/home/devicegridview') }}>GRID View</Button>
                <Button variant='contained' sx={{ backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} onClick={() => { setCreateModelopen(true) }}>+Add Device</Button>
            </div>
            <div>
            <button onClick={()=>{setVisibility(!visibility)}}>Apply Filters!</button>
            <button onClick={applyClearFilter}>Clear Filters!</button>
            </div>
            {visibility?<Box sx={{height:'50px'}}>
                <TextField name='buildingId,equals' select onChange={handleEventChange} defaultValue=''
                label='Select Building' size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}}>
                {buildingName && buildingName.sort().map((x:string)=>{
                        return <MenuItem key={x} value={x}>{x}</MenuItem>
                    })}
                </TextField>
                <TextField name='floorId,equals' select onChange={handleEventChange}
                label='Select Floor' size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}}>
                {floordata && floordata.map((x:string)=>{
                        return <MenuItem key={x} value={x}>{x}</MenuItem>
                    })}
                </TextField>
                <TextField name='roomId,equals' select onChange={handleEventChange}
                label='Select Room' size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}}>
                {roomdata && roomdata.map((x:string)=>{
                        return <MenuItem key={x} value={x}>{x}</MenuItem>
                    })}
                </TextField>
                <TextField name='status,equals' select onChange={handleEventChange} defaultValue=''
                label='Select Status' size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}}>
                {alertStatus && alertStatus.map((x:string)=>{
                        return <MenuItem key={x} value={x}>{x}</MenuItem>
                    })}
                </TextField>
                </Box>:<></>}
            
            <hr />
            <Button variant='contained' sx={{ backgroundColor: '#660066', float: 'right', ":hover": { backgroundColor: '#660066' } }} onClick={refreshDataGrid}>{loadingVisbility ? <img width='25' height='25' style={{ marginRight: 4 }} src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img> : <></>}Refresh</Button>
            <br />
            <br />

            <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs} rowData={devices} types={types} filters={filters} filterParams={filterParams} clear={clearFilter} />
                
            </Box>
            {/* <Button onClick={onClickButtonHandler}  variant='contained' sx={{ backgroundColor: '#660066',float:'right', ":hover": { backgroundColor: '#660066' } }}>Ireland &amp; UKUSA</Button> */}
            {/* <br/>
            <br/>
            <Typography variant='h5'> Smoke Status </Typography>
            <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs1} rowData={smokeData}/>
            </Box> */}
            <CreateDevice visibility={createModelopen} setVisibility={setCreateModelopen} refreshHome={refreshHome} messagesData={messagesData} statusData={statusData} />
            <DeleteDevice visibility={deleteModalVisible} setVisibility={setDeleteModal} refreshHome={refreshHome} data={{ name: deleteModalName, id: deleteModalId }} messagesData={messagesData} statusData={statusData} />
            <EditDevice visibility={editModelopen} setVisibility={setEditModelopen} refreshHome={refreshHome} deviceId={editId} messagesData={messagesData} statusData={statusData} />
            <Notification message={message} status={status} />
            <RefreshDoorLockData />
        </Fragment>
    )
}


export default memo(DeviceHome)