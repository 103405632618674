import { Box } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import MainContainer from 'src/components/homePage/MainContainer';
import SideNavBar from 'src/components/homePage/sideNavBar';
// import TopNavBar from 'src/components/homePage/TopNavBar/TopNavBar';
import { TopNavBarMain } from 'src/components/homePage';
import { APP_NAME } from 'src/constants/shared';
import { RootState } from "src/store";
import Routing from 'src/utils/routes';
import './index.css'

const Home: FC = (): ReactElement => {

    const user = useSelector((state: RootState) => state.user)


    if (user.token === "") {
        return <Navigate to="/" />
    }

    const getValue = (value: any) => {
        console.log(value, "valuesIndex")
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <TopNavBarMain role={user.role} appName={APP_NAME} getData={getValue} />
            <SideNavBar/>
            <MainContainer>
                <Routing role={user.role} />
            </MainContainer>
        </Box>
    )
}

export default Home