import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Grid, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import {  createFloorService } from "src/services/floorService";
import { RootState } from "src/store";
import { IBuilding } from "src/types";

interface CreateFloorProps {
    visibility: boolean,
    setVisiblity: (a: boolean) => void;
    refreshHome: () => void;
    statusData:(a: boolean)=>void;
    messagesData:(a:AlertType)=>void
}
type AlertType = "error" | "success" | "info" | "warning" | undefined;
const CreateFloor: FC<CreateFloorProps> = (props: CreateFloorProps): ReactElement => {

    const { visibility, setVisiblity, refreshHome,statusData,messagesData } = props;

    const [formData, setFormData] = useState({
        name: '',
        number: '',
        description: '',
        buildingId: ''
    })

    const { name, number, description, buildingId } = formData;

    const clear = () => {
        setFormData({ name: '', number: '', description: '', buildingId: '' });
      };


    const applicationId = useSelector((state: RootState) => state.application.id)
    const organizationId = useSelector((state: RootState) => state.organization.id)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)

    const handleClick = async (e: any) => {
        e.preventDefault();
        const response = await createFloorService({ name, number, description, buildingId, applicationId, organizationId })
        if (response.status === 200) {
            refreshHome()
            statusData(true)
            messagesData('success')
            setVisiblity(false)
            clear()
        }
    }

    const handleClose = () => {
        setVisiblity(false)
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <Dialog open={visibility} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle color="#660066" id="alert-dialog-title"> Create Floor </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" >
                    <Grid spacing={3}>
                        <TextField color="secondary" sx={{ mb: 1 }} onChange={handleChange} value={name} fullWidth id="name" name="name" label="Name" variant="standard"  />
                        <TextField color="secondary" sx={{ mb: 1 }} onChange={handleChange} value={description} fullWidth id="description" name="description" label="Description" variant="standard" />
                        <TextField color="secondary" sx={{ mb: 1 }} onChange={handleChange} value={number} fullWidth id="number" name="number" label="Number" variant="standard" />
                        <FormControl fullWidth variant="filled">
                            <InputLabel color="secondary" id="demo-simple-select-label">Building</InputLabel>
                            <Select color="secondary" labelId="demo-simple-select-label" sx={{ mb: 1 }} name="buildingId" onChange={handleChange} value={buildingId} variant="standard">
                                <MenuItem color="secondary" value=""> <em>None</em></MenuItem>
                                {
                                    buildings.map((x: IBuilding) => {
                                        return <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClose}>Cancel</Button>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClick} autoFocus> Create </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateFloor