import http from "./http-common";

export const getAlertsByDevice =async () => {
    return http.post<any>(`/FarmGuards/getFarmAlerts`);
}
export const getAlertbyId = async (formData: any)=>{
    return http.post<any>('/farmGuard/getAlertbyId',formData)
}

export const getAlertsByDeviceId=async(formData:any)=>{
    return http.post<any>('/farmGuard/getAlertsByDeviceId',formData)
}

export const getAlertsByOrganizationId=async(formData:any)=>{
    return http.post<any>('/farmGuard/getAlertsByOrganizationId',formData)
}
export const getFarmAlertsCountByOrg=async(formData:any)=>{
    return http.post<any>('/farmGuard/getFarmAlertsCountByOrg',formData)
}

export const getFarmAlertCountByDeviceId=async(formData:any)=>{
    return http.post<any>('/farmGuard/getFarmAlertCountByDeviceId',formData)
}
export const getAlertReportsByOrgainzationId=async(formData:any)=>{
    return http.post<any>('/farmGuard/getAlertReportsByOrgainzationId',formData)
}