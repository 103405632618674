import { Console } from "console";
import http from "./http-common";

export const getInstrumentsByOrganization= async (formData:any)=>{
    return http.post<any>('instrument/instrumentsByOrganization',formData)
}



export const deleteInstrumentsList= async (formData:any)=>{
    return http.post<any>('instrument/deleteInstrument',formData)
}
export const createInstrumentsList= async (formData:any)=>{
    return http.post<any>('instrument/createInstrument',formData)
}

export const updateInstrumentsList= async (formData:any)=>{
    return http.post<any>('instrument/updateInstrument',formData)
}