import { Box, Button,Grid,Typography } from '@mui/material';
import { FC, ReactElement } from "react";
import { useSelector } from 'react-redux';
import { RootState } from "src/store";
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
import {useNavigate } from 'react-router-dom';
import ProfileCard from './profileCard';
import DataCard from './DataCard';
const Profile: FC = (props: any): ReactElement => {
  const user = useSelector((state: RootState) => state.user);
  const organization = useSelector((state: RootState) => state.organization);
  const allApplications = useSelector((state: RootState) => state.application.allApplications)
  const accessedApplications = useSelector((state: RootState) => state.application.accessedApplications)
  const userId = useSelector((state: RootState) => state.user.id)
  const role = useSelector((state: RootState) => state.role)
  const navigate = useNavigate();
  
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant='h5'>  <Button style={{width:'5px'}}><KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate('/home/dashboard')} style={{
          width: "25px", height: "25px", marginRight: '25px',
          borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
        }} /></Button> Profile  </Typography>
      </div> <hr /><Box>
        <Typography variant="h3" color='#660066' style={{ alignItems: 'center', textAlign: 'center' }}>
        {organization.name}
        </Typography>
        <br />
        <Grid container spacing={25} justifyContent="center" alignItems="flex-start">
          <Grid item>
            <ProfileCard user={user} org={organization} /></Grid>
            <Grid item><DataCard user={user} />
            <br />
            <br />
            <br />
            <br />
            {/* <AlertCard/> */}
            </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default Profile