import React, { ReactElement, FC } from "react";
import {Avatar, Grid, Typography, Button, Box, Card, CardContent, CardMedia, IconButton, useTheme, CardActionArea, CardActions, Paper, Divider, Chip, Stack } from '@mui/material'
import { RootState } from "src/store";
import { useSelector } from "react-redux";
import AnimalIntrusionDayWiseChart from "../ChartWidget/AnimalIntrusionDayWiseChart";
import AnimalIntrusionMonthWiseChart from "../ChartWidget/AnimalIntrusionMonthWiseChart";


const ChartView: FC = (): ReactElement => {
    const farmGuardAlertsChart=useSelector((state:RootState)=>state.farmGuardAlerts.chart_By_device)   
    return (
        <>
          <Grid item xs={6} sx={{ paddingRight: '4px', borderRadius: '11px',border: '2px solid #660066' }}>
            <div>
              <Typography color="#660066" align='center' variant='h5'>
                Animal Intrusion Day Wise
              </Typography>
              <AnimalIntrusionDayWiseChart data={farmGuardAlertsChart.dayResponse} height={300} width={1200} />
            </div>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: '4px', borderRadius: '11px',border: '2px solid #660066',mt:2 }}>
            <div>
              <Typography color="#660066" align='center' variant='h5'>
                Animal Intrusion Month Wise
              </Typography>
              <AnimalIntrusionMonthWiseChart data={farmGuardAlertsChart.monthResponse} height={300} width={1200} />
            </div>
          </Grid>

          
        </>
        

    )



}

export default ChartView;





