import React, { FC,ReactElement } from "react";



const SuperAdminDoorLockDashboard:FC = ():ReactElement=>{
    
    
    return(
        <>
    
        <h1>Hello WELCOME Hello!</h1>
        
        </>


    )
   
}

export default SuperAdminDoorLockDashboard
