import { IStudentDataState,IAction } from "src/types";
import { CREATE_STUDENT,DELETE_STUDENT,UPDATE_STUDENT,SET_STUDENTDATA,SET_SELECTEDSTUDENTDATA } from "../actions/actionTypes";

const initialState:IStudentDataState={
    selectedStudent: null,
    students:[],
    fetchStatus: false,


}
const studentDataReducer=(state:IStudentDataState=initialState,action:IAction)=>{
    const {type,payload}=action;
    switch(type){
        case SET_STUDENTDATA:
            return{
                ...state,
                students:payload.value,
                fetchStatus:payload.fetchStatus
            }
        case SET_SELECTEDSTUDENTDATA:
            return{
                ...state,
                selectedStudent:{
                    ...payload
                }
            }

        default:
            return state
    }
}

export default studentDataReducer;