import moment from 'moment';
import { FC, ReactElement } from 'react';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';


import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  Label,ResponsiveContainer
} from "recharts";

interface AmountOfHours{
    data: any,
    height : number,
    width : number
}


interface propsProps{
    
  _id: {
    year?: number,
    month?: number,
    date?:number
  },
  total_count?: number,
}
const AnimalIntrusionDayWiseChart: FC<AmountOfHours>= (Props:AmountOfHours) : ReactElement=> {
  const colors = scaleOrdinal(schemeCategory10).range();
    const { data } = Props;
    // console.log(data,'dataData')
//     const val = 5
//   function getRandColor(brightness: any) {

//     // Six levels of brightness from 0 to 5, 0 being the darkest
//     const rgba = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
//     const mix = [brightness * val, brightness * val, brightness * val]; //51 => 255/5
//     const mixedrgb = [rgba[0] + mix[0], rgba[1] + mix[1], rgba[2] + mix[2]].map(function (x) { return Math.round(x / 2.0) })
//     return "rgba(" + mixedrgb.join(",") + ")";
// }
  return (
    <ScatterChart
      width={Props.width}
      height={Props.height}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid />
      <XAxis dataKey={(props: propsProps) => {
        return moment(`${props._id.month}`).format('MMM')+""+`${props._id.date}`
      }} 
      type='category' name='Date'
      tick={{ fill: '#660066',fontWeight:'bold' }} tickLine={{ stroke: 'black' }} axisLine={{stroke:'black'}}>
        <Label
          style={{
            textAnchor: "middle",
            fontSize: "130%",
            fill: "#ccc",
          }}         
          angle={0}
          position="bottom"
          value='' />
          
      </XAxis>
      <YAxis tickCount={10} offset={5}
      dataKey='total_count'
      tick={{ fill: '#660066',fontWeight:'bold' }} tickLine={{ stroke: 'black' }} axisLine={{stroke:'black'}}
      >  <Label
          style={{
            textAnchor: "middle",
            fontSize: "110%",
            fill: "#000"
          }}
          angle={270}
          position="left"
          value='No of Times' /> </YAxis>
          <Tooltip cursor={{ strokeDasharray: '2 2',fill:'transparent' }}/>
      <Scatter dataKey="total_units" data={data} fill='#8884d8' fontSize={25}>
      {data.map((entry:any, index:any) => (
          <Cell  key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
       
      </Scatter>
    </ScatterChart>
  );
}

export default AnimalIntrusionDayWiseChart;