import { Typography, Button, Grid ,Box} from '@mui/material';
import { FC, Fragment, ReactElement, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store';
import { IBuilding, IFloor } from 'src/types';
import dateComparator  from 'src/utils/commonMethods';
import CreateDevice from '../Device/CreateDevice';
import DeleteDevice from '../Device/DeleteDevice';
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
import EditDevice from '../Device/EditDevice';
import IndividualView from './invidualStudentView';
import { ColDef, GroupCellRendererParams, ValueGetterParams } from 'ag-grid-community';
import GridView from 'src/components/homePage/GridView';
import moment from 'moment';
import Delete from '@mui/icons-material/Delete';
import { RemoveRedEye } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
// import { Box } from '@mui/system';

import '../../../screens/SwitchApps.css';
import { refreshRoomData } from 'src/store/actions/roomActions';
import { refreshFloorData } from 'src/store/actions/floorActions';
import { refreshBuildingData } from 'src/store/actions/buildingActions';
import RefreshDoorLockData from '../refreshDoorLockData';
import {deleteStudent} from 'src/services/studentDataService';
interface deleteModalProps {
    id: string,
    name: string,
    visible: boolean
}

// interface DeviceHomeProps {
// }

const StudentReport: FC = (props): ReactElement => {

    
        
    const [createModelopen, setCreateModelopen] = useState(false);
    const [editModelopen, setEditModelopen] = useState(false);
    const [individualView, setIndividualView] = useState(false);
    // console.log(individualView,'individualView')
    const [deleteModal, setDeleteModal] = useState<deleteModalProps>({ id: '', name: '', visible: false })
    // const devices1 = useSelector((state: RootState) => state.devices.devices)
    const floors = useSelector((state: RootState) => state.floors.floors)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    const application=useSelector((state:RootState)=>state.application)
    const organization=useSelector((state:RootState)=>state.organization)
    const studentDatas=useSelector((state:RootState)=>state.studentData.students)
    const { id: deleteModalId, name: deleteModalName, visible: deleteModalVisible } = deleteModal;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [editId,setEditId] = useState('')
    const [viewId,setViewId] = useState('')

    const columnDefs: ColDef[] = useMemo(() => [
        {
            field: 'studentName',
            headerName: 'Student Name',
            initialWidth: 200,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.studentName
            }
        },
        {
            field: 'RegNo',
            headerName: 'Reg No',
            initialWidth: 200,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.regNo
            }
        },
        {
            field: 'RollNo',
            headerName: 'Roll No',
            initialWidth: 200,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.rollNo
            }
        },  
        {
            field: 'Gender',
            headerName: 'Gender',
            initialWidth: 200,
            hide:true,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.gender
            }
        },  
        {
            field: 'Age',
            headerName: 'Age',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.age
            }
        },
        {
            field: 'DateOfBirth',
            headerName: 'Date of Birth',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.dateOfBirth
            }
        },
        {
            field: 'AcademicYearFrom',
            headerName: 'Academic Year From',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.academicYearFrom
            }
        },
        {
            field: 'AcademicYearTo',
            headerName: 'Academic Year To',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.academicYearTo
            }
        },
        {
            field: 'Branch',
            headerName: 'Branch',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.branch
            }
        },
        {
            field: 'MailId',
            headerName: 'Mail ID',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.mailID
            }
        },
        {
            field: 'PhoneNo',
            headerName: 'Phone No',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.phoneNo
            }
        },
        {
            field: 'ParentPhoneNo',
            headerName: 'Parent Phone No',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.parentPhoneNo
            }
        },
        {
            field: 'Address',
            headerName: 'Address',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.address
            }
        },
        {
            field: 'roomId',
            headerName: 'Room',
            initialWidth: 100,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            flex: 1,
            filter: 'agTextColumnFilter', valueGetter: (params: ValueGetterParams) => {
                const val = getRoomData(params.data.roomId)
                return val !== '' ? val : 'NA'
            }
        },
        {
            field: 'floorId',
            headerName: 'Floor',
            initialWidth: 100,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            flex: 1,
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                const val = getFloorData(params.data.floorId)
                return val !== '' ? val : 'NA'
            }
        },
        {
            field: 'buildingId',
            headerName: 'Building',
            initialWidth: 150,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            flex: 1,
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                const val = getBuildingData(params.data.buildingId)
                return val !== '' ? val : 'NA'
            }

        },
        {
            field: 'DateOfJoining',
            headerName: 'Date Of Joining',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.dateOfJoining
            }
        },
        {
            field: 'DateofLeft',
            headerName: 'Date Of Left',
            initialWidth: 200,
            wrapHeaderText: true,
            hide:true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.dateOfLeft
            }
        },
        {
            field: 'Date',
            headerName: 'Created Date & Time',
            initialWidth: 200,
            hide:true,
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            filter: 'agTextColumnFilter',
            comparator:dateComparator,
            wrapHeaderText: true,
            valueGetter: (params: ValueGetterParams) => {
                return moment(params.data.createdDate).format('DD/MM/YYYY | HH:mm:ss')
            },
            sort: 'desc'
        },
        {
            field: 'action',
            headerName: 'Actions',
            cellRenderer: (params: GroupCellRendererParams) => (
                <Grid container spacing={4}>
                    <Grid item><RemoveRedEye sx={{ color: '#660066', mt: 1 }} onClick={() => {setIndividualView(true);setViewId(params.data._id)}} /></Grid>
                    <Grid item><EditIcon sx={{ color: '#660066', mt: 1 }} onClick={() => {navigate('../studentEntry');setEditId(params.data._id)}} /></Grid>
                    <Grid item><Delete sx={{ color: '#660066', mt: 1 }} onClick={() => {deleteStudentData(params.data._id)}} /></Grid>
                </Grid>
            )
        }
    ], [ rooms, floors, buildings,studentDatas]);


    const refreshHome = () => {
        refreshBuildingData(dispatch)
        refreshFloorData(dispatch)
        refreshRoomData(dispatch)
        // refreshStudentData(dispatch)
    }

    useMemo(() => {
        refreshHome()
    }, [])

    const getBuildingData = (buildingId: string): string | undefined | null => {
        const buildingData = buildings.find((x: IBuilding) => x.id === buildingId)
        return buildingData ? buildingData.name : ''
    }

    const getFloorData = (floorId: string): string | undefined | null => {
        const floorData = floors.find((x: IFloor) => x.id === floorId)
        return floorData ? floorData.name : ''
    }

    const getRoomData = (roomId: string): string | undefined | null => {
        const roomData = rooms.find((x: IFloor) => x.id === roomId)
        return roomData ? roomData.number : ''
    }
    const refreshDataGrid=()=>{
        navigate('/home/dashboard')
        setTimeout(()=>{navigate(`../studentEntry/studentReport/`)},100)
      }

    const deleteStudentData=async(_id:string)=>{
        const response=await deleteStudent({_id:_id,organizationId:organization.id,applicationId:application.id})
        if(response.status===200){
            refreshDataGrid()
            // console.log(response.data,'deleteStudent')
        }
    }
    return (
        <Fragment>
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant='h5'> Devices </Typography>
                <Button variant='contained' sx={{ backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} onClick={() => { navigate('/home/devicegridview')}}>GRID View</Button>
                <Button variant='contained' sx={{ backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} onClick={() => { setCreateModelopen(true) }}>+Add Device</Button> 
            </div> */}
            {/* <hr /> */}
            <Typography variant='h5'><KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate('../studentEntry')} style={{
                width: "25px", height: "25px", marginRight: '25px',
                borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
              }} /> Report <Button variant='contained' sx={{ backgroundColor: '#660066',float:'right', ":hover": { backgroundColor: '#660066' } }} onClick={refreshDataGrid}>Refresh</Button></Typography>
            <br/>            
            <Box className="ag-theme-alpine" sx={{ height: 600, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs} rowData={studentDatas}/>
            </Box>
            {/* <br/>
            <br/>
            <Typography variant='h5'> Smoke Status </Typography>
            <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs1} rowData={smokeData}/>
            </Box> */}
            {/* <CreateDevice visibility={createModelopen} setVisibility={setCreateModelopen} refreshHome={refreshHome} />
            <DeleteDevice visibility={deleteModalVisible} setVisibility={setDeleteModal} refreshHome={refreshHome} data={{ name: deleteModalName, id: deleteModalId }} />
            <EditDevice visibility={editModelopen} setVisibility={setEditModelopen} refreshHome={refreshHome} deviceId={editId}/> */}
            <IndividualView visibility={individualView} setVisibility={setIndividualView} refreshHome={refreshHome} studentId={viewId}/>
            <RefreshDoorLockData />
        </Fragment>
    )
}

export default StudentReport


// switch((Number(data1[params.data.id])*watts[params.data.id]/1000*9)===NaN){