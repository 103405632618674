import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getOrganizationDashboardDetails } from 'src/services/organizationService';
import { RootState } from 'src/store';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
export default function ProfileCard(props: any) {
  const { user } = props
  const [hoursStatus, sethoursfetchStatus] = useState(false)
  const [data, setdata] = useState<any>({
    deviceCount: 0,
    roomCount: 0,
    floorCount: 0,
    buildingCount: 0,
    chartResponseOpened: [],
    chartResponseClosed: [],
    activeDeviceCount: 0,
    inactiveDeviceCount: 0
  });

  const application = useSelector((state: RootState) => state.application)
  const organization = useSelector((state: RootState) => state.organization)
  const refreshHome = useCallback(async () => {
    const response = await getOrganizationDashboardDetails({ organizationId: organization.id, applicationId: application.id })
    if (response.status === 200) {
      setdata(response.data)
      sethoursfetchStatus(true)
    }
  }, [])

  useEffect(() => {
    if (!hoursStatus) {
      refreshHome()
    }
  }, [hoursStatus, refreshHome, data])
  return (
    <><Card sx={{ maxWidth: 400, borderRadius: '25px', height: '325px', boxShadow: "rgb(152 145 145) 1px 5px 15px", }}>
      <CardContent sx={{}}>
        <Typography color='#660066' gutterBottom variant="h5" component="div">
          <AssignmentIndIcon/> My Accounts
        </Typography>
        <br />
        <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px' }}>Account Status</Typography><Typography sx={{ width: '40px' }}>:</Typography><Typography sx={{ width: '250px', color: 'green', fontWeight: 'bold' }}>Active</Typography></p>
        <br />
        <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px' }}>Version</Typography><Typography sx={{ width: '40px' }}>:</Typography><Typography sx={{ width: '250px' }}>2.0</Typography></p>
        <br />
        <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px' }}>Total Devices Installed</Typography><Typography sx={{ width: '40px' }}>:</Typography><Typography sx={{ width: '250px' }}>{data.deviceCount}</Typography></p>
        <br />
        <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px' }}>Access Application</Typography><Typography sx={{ width: '40px' }}>:</Typography><Typography sx={{ width: '250px', color: 'green', fontWeight: 'bold' }}>1/4</Typography></p>
        <br />
      </CardContent>
    </Card>
      <br />
      <Card sx={{ maxWidth: 400, borderRadius: '25px', height: '325px', boxShadow: "rgb(152 145 145) 1px 5px 15px", }}>
        <CardContent sx={{}}>
          <Typography color='#660066' gutterBottom variant="h5" component="div">
            <CardMembershipIcon/> Subscription Details
          </Typography>
          <br />
          <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px' }}> Valid From </Typography><Typography sx={{ width: '40px' }}>:</Typography><Typography sx={{ width: '250px', color: 'green', fontWeight: 'bold' }}>21-11-2022</Typography></p>
          <br />
          <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px' }}> Till </Typography><Typography sx={{ width: '40px' }}>:</Typography><Typography sx={{ width: '250px' ,color: 'red', fontWeight: 'bold' }}> 21-11-2024 </Typography></p>
          <br />
          <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px' }}> Next Renewal on </Typography><Typography sx={{ width: '40px' }}>:</Typography><Typography sx={{ width: '250px' }}> 21-11-2024 </Typography></p>
          <br />
          <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px' }}> Access Application </Typography><Typography sx={{ width: '40px' }}>:</Typography><Typography sx={{ width: '250px', color: 'green', fontWeight: 'bold' }}>1/4</Typography></p>
          <br />
        </CardContent>
      </Card></>
  );
}
