import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Grid } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { createBuilding } from "src/services/buildingService";
import { RootState } from "src/store";
type AlertType = "error" | "success" | "info" | "warning" | undefined;
interface CreateBuildingProps {
    visibility: boolean,
    setVisiblity: (a: boolean) => void;
    refreshHome: () => void;
    statusData:(a: boolean)=>void;
    messagesData:(a:AlertType)=>void
}

const CreateBuilding: FC<CreateBuildingProps> = (props: CreateBuildingProps): ReactElement => {

    const { visibility, setVisiblity, refreshHome,statusData,messagesData } = props;

    const [formData, setFormData] = useState({
        name: '',
        location: '',
        description: ''
    })

    const { name, location, description } = formData;

    const clear =() => {
        setFormData({ name : '', location: '', description: ''})
    }

    const applicationId = useSelector((state: RootState) => state.application.id)
    const organizationId = useSelector((state: RootState) => state.organization.id)

    const handleClick = async (e: any) => {
        e.preventDefault();
        const response = await createBuilding({name, location, description, applicationId, organizationId})
        if(response.status === 200) {
            refreshHome()
            setVisiblity(false)
            statusData(true)
            messagesData('success')
            clear()
        }
    }

    const handleClose = () => {
        setVisiblity(false)
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setFormData({...formData, [e.target.name]: e.target.value })
    }

    return (
        <Dialog open={visibility} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle color="#660066" id="alert-dialog-title"> Create Building </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid spacing={3}>
                        <TextField color="secondary" sx={{ mb: 1 }} onChange={handleChange} value={name} fullWidth id="name" name="name" label="Name" variant="standard" />
                        <TextField color="secondary" sx={{ mb: 1 }} onChange={handleChange} value={description} fullWidth id="description" name="description" label="Description" variant="standard" />
                        <TextField color="secondary" sx={{ mb: 1 }} onChange={handleChange} value={location} fullWidth id="location" name="location" label="Location" variant="standard" />
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClose}>Cancel</Button>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClick} autoFocus> Create </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateBuilding