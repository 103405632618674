import React, { ReactElement, FC, ReactNode } from "react";
import { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import { Card, CardContent, CardActionArea, Paper, Typography, SvgIconTypeMap } from '@mui/material'
import LandscapeIcon from '@mui/icons-material/Landscape';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { AnyObject } from "immer/dist/internal";



interface NotificationProps {
    about: any;
    icon?:React.ReactNode
    value: any;
    message:any;
    position?:string
    children?: ReactNode
}
//OverridableComponent<SvgIconTypeMap<"any object", "svg">> & {muiName: string;}

const Notification: FC<NotificationProps> = (props: NotificationProps): ReactElement => {
    const { about,message, icon, value,position } = props;

    
    return (
        <>
            <div style={{ marginTop:`${position}`,width:'200px', height:'121px', borderRadius: '11px',backgroundColor:'#fff',opacity:1,border:'2px solid #660066' }}>
                <Typography color="#000" sx={{paddingTop:'5%',fontSize:'1vw',textAlign:'center',justifyContent:'center'}} >
                    {about} {icon}

                </Typography>
                <Typography color="#660066" sx={{marginTop:'-5%',fontSize:'3vw',textAlign:'center',justifyContent:'center'}}>
                    {value}   </Typography>             
                <Typography color="#000" sx={{marginTop:'-5%',fontSize:'1vw',textAlign:'center',justifyContent:'center'}}>
                    {message}
                </Typography>
            </div>


        </>
    )



}

export default Notification;