import { IBuildingState, IAction } from "src/types";
import { SET_BUILDINGDATA, SET_SELECTEDBUILDINGDATA, RESET_BUILDING, SET_BUILDING_HOME_DATA, BUILDING_DASHBOARD_DETAILS, GROUP_DATA_BY_BUILDINGID } from "../actions/actionTypes";

const initalState: IBuildingState = {
    selectedBuilding: null,
    buildingHomeDatabyOrg:{floorResponse: [], roomResponse: [],groupByBuildingId:[]},
    buildingDashBoardDetails:{deviceCount: 0,roomCount: 0,floorCount: 0,buildingCount: 0,
        chartResponseOpened: [],chartResponseClosed: [],
        cosumptionDetails: [{"_id": {"year": 0,"month": 0},"total_hours": 0,"total_count": 0}]},
    deviceDataGroupByBuildingId:{group_chartResponses:[]},
    buildings: [],
    fetchStatus: false
}
const buildingReducer = (state: IBuildingState = initalState, action: IAction) => {

    const { type, payload } = action;

    switch (type) {
        case SET_BUILDINGDATA:
            return {
                ...state,
                buildings: payload.value,
                fetchStatus: payload.fetchStatus
            }
        case SET_SELECTEDBUILDINGDATA:
            return {
                ...state,
                selectedBuilding: {
                    ...payload
                }
            }
        case SET_BUILDING_HOME_DATA:
            return{
                ...state,
                buildingHomeDatabyOrg:{...payload.value}
            }
        case RESET_BUILDING:
            return {
                ...state,
                initalState
            }
        case BUILDING_DASHBOARD_DETAILS:
            return{
                ...state,
                buildingDashBoardDetails:{...payload.value}
            }
        case GROUP_DATA_BY_BUILDINGID:
            return{
                ...state,
                deviceDataGroupByBuildingId:{...payload.value}
            }
        default:
            return state
    }
}

export default buildingReducer