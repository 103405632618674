import http from "./http-common";

export const getDevice = async (formData: any) => {
    return http.get<any>(`/device`, formData);
}

export const getDeviceById = async (formData: any) => {
    return http.post<any>(`/device/deviceById`, formData);
}

export const getDeviceByOrganization = async (formData: any) => {
    return http.post<any>(`/device/deviceByOrganization`, formData);
}


export const createDeviceService = async (formData: any) => {
    return http.post<any>(`/device/create`, formData);
}

export const updateDevice = async (formData: any) => {
    return http.post<any>(`/device/update`, formData);
}

export const deleteDevice = async (formData: any) => {
    return http.post<any>(`/device/delete`, formData);
}

export const getSecretDetails = async (formData: any) => {
    return http.post<any>(`/device/fetchDeviceSecret`, formData);
}

export const getDeviceAlertsByMonthService = async (formData: any) => {
    return http.post<any>(`/device/deviceAlertByDevice`, formData)
}

export const getDeviceAlertsByOrganizationService = async (formData: any) => {
    return http.post<any>(`/device/deviceAlertByOrganization`, formData)
}
export const updateDeviceStatus = async (formData: any) => {
    return http.post<any>(`/device/updateDeviceStatus`, formData);
}

export const updateDeviceActiveStatus = async (formData: any) => {
    return http.post<any>(`/device/updateDeviceActiveStatus`, formData);
}
export const getAllDeviceDataByOrganisation = async (formData: any) => {
    return http.post<any>(`/device/allDeviceDataByOrganisation`, formData);
}
export const getSmokeDataByOrganisation= async  (formData:any)=>{
    return http.post<any>(`/device/smokeByOrganization`,formData);
}