import {FC, ReactElement} from "react";
import { Provider } from "react-redux";
import Landing from "src/screens/Landing";
import store from "src/store";
import Login from "./screens/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import SwitchApps from "./screens/SwitchApps";
// import './App.css';
const App:FC = () : ReactElement => {
  return (
    <Provider store={store} >
    <BrowserRouter >
      <Routes>
        <Route index element={<Landing />}/>
        <Route path="login" element={<Login />} />
        <Route path="home/*" element={<Home />} />
        <Route path="switchApps" element={<SwitchApps />} />
      </Routes>      
    </BrowserRouter>
    </Provider>
  );
}

export default App