import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import DoorLockSideNavBar  from './DoorLockSideNavBar';
import { Typography } from '@mui/material';
import FarmSideNavBar from './FarmGuardSidenavbar';
const SideNavBar : FC =() : ReactElement => {

    const selectedApplication = useSelector((state: RootState) => state.application)
    
    const user = useSelector((state: RootState) => state.user)

    switch(selectedApplication.name){
        case 'Smart Door Lock Detector and Monitor' : return <DoorLockSideNavBar role={user.role} />
        case 'Farm Guard' : return <FarmSideNavBar role={user.role}/>
        default: return(<Typography> No side bar</Typography>)
    }
}

export default SideNavBar