import { IAction, IUserState } from "src/types";
import { SET_USERDATA, RESET_USER } from "../actions/actionTypes";

const initalState: IUserState = {
    id: '',
    name: '',
    emailId: '',
    token: '',
    role: '',
    phoneNumber: '',
}
const userReducer = (state: IUserState = initalState, action: IAction) => {

    const { type, payload } = action;

    switch (type) {
        case SET_USERDATA:
            // console.log(payload)
            return {
                ...state,
                name: payload.name,
                emailId: payload.emailId,
                role: payload.role,
                id: payload.id,
                phoneNumber: payload.phoneNumber,
                token: payload.token,
            }
        case RESET_USER:
            return {
                ...state,
                initalState
            }
        default:
            return state
    }
}

export default userReducer