import { Dispatch } from "react"
import { CREATE_DEVICE, UPDATE_DEVICE, DELETE_DEVICE, SET_DEVICEDATA,SET_ALL_DEVICE_DATA_BY_ORG,SET_DEVICE_ALERT_BY_MONTH_SERVICE } from "./actionTypes"

export const createDEVICE = (dispath: Dispatch<any>, deviceName: string) => {
    dispath({ type: CREATE_DEVICE, deviceName })
}

export const updateDEVICE = (dispath: Dispatch<any>, deviceId: string) => {
    dispath({ type: UPDATE_DEVICE, deviceId })
}

export const deleteDEVICE = (dispath: Dispatch<any>, deviceId: string) => {
    dispath({ type: DELETE_DEVICE, deviceId })
}

export const refreshDeviceData = (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_DEVICEDATA, payload: { value:[], fetchStatus: false } })
}


export const refreshAllDeviceDataByOrg=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:SET_ALL_DEVICE_DATA_BY_ORG,payload:{value:data}})
}

export const refreshDeviceAlertsByMonthService=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:SET_DEVICE_ALERT_BY_MONTH_SERVICE,payload:{value:data}})
}