import { ROLE } from '../constants/shared';
import { useRoutes } from 'react-router-dom';
import { ReactElement } from 'react';
import Settings from '../screens/Home/Settings';
import Dashboard from '../screens/Home/Dashboard/index'; 
import DeviceHome from '../screens/Home/Device/DeviceHome';
import ActiveDevices from '../screens/Home/Dashboard/activeDevices';
import InActiveDevices from '../screens/Home/Dashboard/inActiveDevices';
import CheckDevices from '../screens/Home/Dashboard/checkDevices';
import BuildingHome from '../screens/Home/Building/BuildingHome';
import FloorHome from '../screens/Home/Floor/FloorHome';
import RoomHome from '../screens/Home/Rooms/RoomHome';
import RoomView from 'src/screens/Home/Rooms/RoomView';
import FloorView from 'src/screens/Home/Floor/FloorView';
import BuildingView from 'src/screens/Home/Building/BuildingView';
import DeviceView from 'src/screens/Home/Device/DeviceView';
import Profile from 'src/screens/Home/Profile/ProfileHome';
import StudentEntry from 'src/screens/Home/StudentData/studentEntry';
import DeviceGridView from 'src/screens/Home/Device/DeviceGridView';
import Instruments from 'src/screens/Home/Instruments/InstrumentsHome'
import StudentReport from 'src/screens/Home/StudentData/studentReport'
import Cameras from '../screens/Home/FarmGuard/Cameras';
import FolderView from '../components/homePage/FarmGuard/Camera/folderView';
import AlertReports from '../screens/Home/FarmGuard/AlertReports'
const RouteOrgUser = [
    { path: "dashboard", element: <Dashboard /> },
    { path: "activedevices", element: <ActiveDevices/> },
    { path: "inactivedevices", element: <InActiveDevices/>},
    { path:'activedevices/checkdevices',element:<CheckDevices/>},
    { path: "devices", element: <DeviceHome /> },
    { path: "devices/:id", element: <DeviceView/>},
    { path: "rooms" , element: <RoomHome /> },
    { path: "rooms/:id", element: <RoomView/>},
    { path: "floors", element: <FloorHome /> },
    { path: "floors/:id", element: <FloorView/>},
    { path: "buildings", element: <BuildingHome /> },
    { path: "buildings/:id", element: <BuildingView/>},
    { path: "devicegridview", element: <DeviceGridView/>},
    { path: "Instruments", element: <Instruments /> },
    { path: "profile", element: <Profile/> },
    { path:"studentEntry",element:<StudentEntry/>},
    { path: "settings", element: <Settings /> },
    { path:'studentEntry/studentReport',element:<StudentReport/>},
    { path:'cameras',element:<Cameras/>},
    {path:'cameras/folderView/:id',element:<FolderView/>},
    {path:'alertreports',element:<AlertReports/>},
]

const RouteOrgAdmin = [
    { path: "dashboard", element: <Dashboard /> },
    { path: "activedevices", element: <ActiveDevices/> },
    { path: "inactivedevices", element: <InActiveDevices/>},
    { path:'activedevices/checkdevices',element:<CheckDevices/>},
    { path: "devices", element: <DeviceHome /> },
    { path: "devices/:id", element: <DeviceView/>},
    { path: "rooms" , element: <RoomHome /> },
    { path: "rooms/:id", element: <RoomView/>},
    { path: "floors", element: <FloorHome /> },
    { path: "floors/:id", element: <FloorView/>},
    { path: "buildings", element: <BuildingHome /> },
    { path: "buildings/:id", element: <BuildingView/>},
    { path: "devicegridview", element: <DeviceGridView/>},
    { path: "Instruments", element: <Instruments /> },
    { path: "profile", element: <Profile/> },
    { path:"studentEntry",element:<StudentEntry/>},
    { path: "settings", element: <Settings /> },
    { path:'studentEntry/studentReport',element:<StudentReport/>},
    { path:'cameras',element:<Cameras/>},
    {path:'cameras/folderView/:id',element:<FolderView/>},
    {path:'alertreports',element:<AlertReports/>},
]

const RouteOrgSuperAdmin = [
    { path: "dashboard", element: <Dashboard /> },
    { path: "activedevices", element: <ActiveDevices/> },
    { path: "inactivedevices", element: <InActiveDevices/>},
    { path:'activedevices/checkdevices',element:<CheckDevices/>},
    { path: "devices", element: <DeviceHome /> },
    { path: "devices/:id", element: <DeviceView/>},
    { path: "rooms" , element: <RoomHome /> },
    { path: "rooms/:id", element: <RoomView/>},
    { path: "floors", element: <FloorHome /> },
    { path: "floors/:id", element: <FloorView/>},
    { path: "buildings", element: <BuildingHome /> },
    { path: "buildings/:id", element: <BuildingView/>},
    { path: "devicegridview", element: <DeviceGridView/>},
    { path: "Instruments", element: <Instruments /> },
    { path: "profile", element: <Profile/> },
    { path:"studentEntry",element:<StudentEntry/>},
    { path: "settings", element: <Settings /> },
    { path:'studentEntry/studentReport',element:<StudentReport/>},
    { path:'cameras',element:<Cameras/>},
    {path:'cameras/folderView/:id',element:<FolderView/>},
    {path:'alertreports',element:<AlertReports/>},
]

const Routing = (props: any): ReactElement => {
    let routesData = []

    const { role } = props;

    switch (role) {
        case ROLE.User: routesData = RouteOrgUser; break;
        case ROLE.Admin: routesData = RouteOrgAdmin; break;
        case ROLE.SuperAdmin: routesData = RouteOrgSuperAdmin; break;
        default: routesData = RouteOrgUser; break;

    }
    
    const Routes = useRoutes(routesData);
    return Routes || <></>;
}

export default Routing