import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Grid, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { FC, ReactElement, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import studentView from 'src/assets/svg/studentView.jpg'
import { IBuilding, IFloor, IRoom, IStudentData } from "src/types";
import { Close } from '@mui/icons-material';
import moment from 'moment';

interface EditDeviceProps {
    visibility: boolean,
    setVisibility: (a: boolean) => void,
    refreshHome: () => void,
    studentId: string
}
interface student {
    _id: string,
    studentName: string,
    regNo: number,
    rollNo: string,
    gender: string,
    age: number,
    dateOfBirth: string,
    academicYearFrom: number,
    academicYearTo: number,
    branch: string,
    mailID: string,
    phoneNo: number,
    parentPhoneNo: number,
    address: string,
    roomId: string,
    floorId: string,
    buildingId: string,
    dateOfJoining: string,
    dateOfLeft: string,
    createdDate: string,
    applicationId: string,
    organizationId: string

}
const EditDevice: FC<EditDeviceProps> = (props: EditDeviceProps): ReactElement => {
    const [formData, setFormData] = useState({
        academicYearFrom: 2018,
        academicYearTo: 2022,
        address: "No.1,Gandhi street,chennai-632001",
        age: 21,
        applicationId: "2df1fbee-2d58-40d6-a72b-7c8c8fa59a1c",
        branch: "IT",
        buildingId: "69227a21-abca-4449-8cf9-7ebaec1dbb91",
        createdDate: "2022-11-01T04:38:40.876Z",
        dateOfBirth: "1991-12-31T18:30:00.000Z",
        dateOfJoining: "2017-12-31T18:30:00.000Z",
        dateOfLeft: "2021-12-31T18:30:00.000Z",
        floorId: "6b737d42-1af5-4208-affa-e763e3e317e1",
        gender: "Male",
        mailID: "vijaytit@gmail.com",
        organizationId: "6abaea56-a8b6-4433-a9e0-290955c30172",
        parentPhoneNo: 9876543211,
        phoneNo: 9876543210,
        regNo: 202200001,
        rollNo: "2022SSN05",
        roomId: "5d736e8f-f9d2-4ccd-9f80-fe41658f4c93",
        studentName: "T.Vijay",
        _id: "87a1bfaf-64ff-422e-9ac4-08ee9ccc8f3e"

    })

    const { visibility, setVisibility, refreshHome, studentId } = props;
    console.log(formData, 'formData')
    const dateFormat=(data:any)=>{
        return moment(data).format('yyyy-MM-DD')
    }

    // const {_id,studentName,regNo,rollNo,gender,age,dateOfBirth,academicYearFrom,academicYearTo,branch,mailID,phoneNo,parentPhoneNo,address,roomId,floorId,buildingId,dateOfJoining,dateOfLeft} = formData;


    const studentDatas = useSelector((state: RootState) => state.studentData.students)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const floors = useSelector((state: RootState) => state.floors.floors)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    const studentData = studentDatas.filter((x: any) => x._id === studentId)
    const student = studentData[0]
    console.log(studentData[0], 'student')
    useEffect(() => {
        if(student!==undefined){
            setFormData(student)
        }
        
    }, [student, studentData, studentDatas,formData])

    const handleClose = () => {
        setVisibility(false)
    }

    const getBuildingData = (buildingId: string): string | undefined | null => {
        const buildingData = buildings.find((x: IBuilding) => x.id === buildingId)
        return buildingData ? buildingData.name : ''
    }

    const getFloorData = (floorId: string): string | undefined | null => {
        const floorData = floors.find((x: IFloor) => x.id === floorId)
        return floorData ? floorData.name : ''
    }

    const getRoomData = (roomId: string): string | undefined | null => {
        const roomData = rooms.find((x: IRoom) => x.id === roomId)
        return roomData ? roomData.number : ''
    }


    // console.log(editedInstruments,'addedInstruments_New')
    return (
        <>
            {studentData.length !== 0 ?
                <Dialog maxWidth='lg' open={visibility} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

                    <DialogContent style={{ backgroundImage:`url(${studentView})`, backgroundSize: '1100px 800px', backgroundRepeat: 'no-repeat' }}>
                        <DialogTitle id="alert-dialog-title" sx={{ color: '#660066', textAlign: 'center', fontWeight: 'bold' }}> Student Details <Close style={{ float: 'right', color: 'black' }} onClick={handleClose} /></DialogTitle>
                        <DialogContentText id="alert-dialog-description">
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Regn ID</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly id='RegNo' label="Reg No" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.regNo} className='input-field' />
                                <p style={{ alignItems: 'center', display: 'flex', marginLeft: '15%' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Building Name</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                    <TextField aria-readonly id='BuildingName' label="Building Name" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14, width: '28ch' }} value={getBuildingData(formData.buildingId)} className='input-field' />
                                </p>
                            </p>
                            <br />
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Student Name</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly id='StudentName' label="Student Name" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.studentName} className='input-field' />
                                <p style={{ alignItems: 'center', display: 'flex', marginLeft: '15%' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Floor Name</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                    <TextField aria-readonly id='FloorName' label="Floor Name" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14, width: '28ch' }} value={getFloorData(formData.floorId)} className='input-field' />
                                </p>
                            </p>
                            <br />
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Roll No</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly id='RollNo' label="Roll No" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.rollNo} className='input-field' />
                                <p style={{ alignItems: 'center', display: 'flex', marginLeft: '15%' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Room Name</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                    <TextField aria-readonly id='RoomName' label="Room Name" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14, width: '28ch' }} value={getRoomData(formData.roomId)} className='input-field' />
                                </p>
                            </p>
                            <br />
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Age</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly id='Age' label="Age" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.age} className='input-field' />
                                <p style={{ alignItems: 'center', display: 'flex', marginLeft: '15%' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Date of Birth</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                    <TextField aria-readonly type='date' id='DateofBirth' size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14, width: '28ch' }} value={dateFormat(formData.dateOfBirth)} className='input-field' />
                                </p>
                            </p>
                            <br />
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Gender</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly id='Gender' label="Gender" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.gender} className='input-field' />
                                <p style={{ alignItems: 'center', display: 'flex', marginLeft: '15%' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Date of Joining</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                    <TextField aria-readonly type="date" id='DateofJoining' size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14, width: '28ch' }} value={dateFormat(formData.dateOfJoining)} className='input-field' />
                                </p>
                            </p>
                            <br />
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Academic Year</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly type='number' id='AcademicYearFrom' label="From" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14, width: '12ch' }} value={formData.academicYearFrom} className='input-field' />
                                <TextField aria-readonly type='number' id='AcademicYearTo' label="To" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14, ml: 4, width: '12ch' }} value={formData.academicYearTo} className='input-field' />
                                <p style={{ alignItems: 'center', display: 'flex', marginLeft: '15%' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Date of Left</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                    <TextField aria-readonly type="date" id='DateofLeft' size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14, width: '28ch' }} value={dateFormat(formData.dateOfLeft)} className='input-field' />
                                </p>
                            </p>
                            <br />
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Branch</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly id='Branch' label="Branch" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.branch} className='input-field' />
                                <p style={{ alignItems: 'center', display: 'flex', marginLeft: '15%' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Mail ID</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                    <TextField aria-readonly type='email' id='MailID' label="Mail ID" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.mailID} className='input-field' />
                                </p>
                            </p>
                            <br />
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Phone No</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly id='PhoneNo' label="Phone No" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.phoneNo} className='input-field' />
                                <p style={{ alignItems: 'center', display: 'flex', marginLeft: '15%' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Parent Phone No</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                    <TextField aria-readonly id='ParentPhoneNo' label="Parent Phone No" size='small' variant="outlined" color='secondary' sx={{ fontWeight: 'bold', fontSize: 14 }} value={formData.parentPhoneNo} className='input-field' />
                                </p>
                            </p>
                            <br />
                            <p style={{ alignItems: 'center', display: 'flex' }}><Typography sx={{ width: '150px', color: '#660066', fontSize: 16, fontWeight: 'bold' }} >Address</Typography><Typography sx={{ width: '10px' }} >:</Typography>
                                <TextField aria-readonly id='Address' label="Address" size='small' variant="outlined" color='secondary' multiline rows={6} sx={{ fontWeight: 'bold', fontSize: 14, width: '92ch' }} value={formData.address} className='input-field' />
                            </p>
                            <br />
                        </DialogContentText>
                    </DialogContent>
                    {/* <DialogActions>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClose}> Close </Button>
            </DialogActions> */}
                    {/* <AddInstruments visibilityOfInstruments={instrumentModel} setVisibilityOfInstruments={setInstrumentModel} selectedInst={instrumentsList} defaultSelected={deviceItem.instrumentList}></AddInstruments> */}

                </Dialog>
                : <></>}




        </>
    )

}

export default EditDevice;




