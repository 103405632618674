import http from "./http-common";

export const getBuilding = async (formData: any) => {
    return http.get<any>(`/building`, formData);
}

export const getBuildingById = async (formData: any) => {
    return http.post<any>(`/building/buildingById`, formData);
}

export const getBuildingByOrganization = async (formData: any) => {
    return http.post<any>(`/building/buildingByOrganization`, formData);
}

export const createBuilding = async (formData: any) => {
    return http.post<any>(`/building/create`, formData);
}

export const updateBuilding = async (formData: any) => {
    return http.post<any>(`/building/update`, formData);
}

export const deleteBuilding = async (formData: any) => {
    return http.post<any>(`/building/delete`, formData);
}

export const getBuildingDashboardDetails = async (formData: any) => {
    return http.post<any>(`/building/deviceAlertByBuilding`, formData)
}

export const getDeviceDataGroupbyBuildingId = async (formData: any) => {
    return http.post<any>(`/building/deviceDataByBuildingId`, formData)
}

export const getbuildingHomeDatabyOrganisation=async(formData:any)=>{
    return http.post('/building/buildingHomeDatabyOrganisation',formData)
}