import { IRoomState, IAction } from "src/types";
import { SET_ROOMDATA, SET_SELECTEDROOMDATA,  RESET_ROOM ,ROOM_DASHBOARD_DETAILS} from "../actions/actionTypes";

const initalState : IRoomState = {
    selectedRoom: null,
    roomDashboardDetails:{deviceCount: 0,roomCount: 0,chartResponseOpened: [],chartResponseClosed:[],
    cosumptionDetails: [{"_id": {"year": 0,"month": 0},"total_hours": 0,"total_count": 0}]
    },
    rooms: [],
    fetchStatus: false
}
const roomReducer = (state : IRoomState = initalState, action: IAction) => {

    const {type, payload} = action;

    switch(type) {
        case SET_ROOMDATA:
            return {
                ...state,
                rooms: payload.value,
                fetchStatus: payload.fetchStatus
            }
        case SET_SELECTEDROOMDATA : 
        return {
            ...state,
            selectedRoom: {
                number: payload.number,
                id: payload.id,
                organizationId: payload.organizationId,
                applicationId: payload.applicationId,
                floorId: payload.floorId,
                buildingId: payload.bulidingId,
               ...payload
            }
        }  
        case RESET_ROOM:
            return {
                ...state,
                initalState
            }
        case ROOM_DASHBOARD_DETAILS:
            return{
                ...state,
                roomDashboardDetails:{...payload.value}
            }
        default:
            return state
    }
}

export default roomReducer