import { Close } from "@mui/icons-material";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { FC, Fragment, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { modalStyle } from "src/utils/commonMethods";
import { changePassword,changeRegMailId,updatePhoneNumber } from "src/services/userService"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { setAlert } from "src/store/actions/alertAction";
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
const Settings: FC = (props: any): ReactElement => {
    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [changeMailIdModal, setChangeMailIdModal] = useState(false)
    const [changeMobNoModal, setChangeMobNoModal] = useState(false)
    const user = useSelector((state: RootState) => state.user);
    const navigate=useNavigate()
    return (
        <Fragment>
            <div>
                <Typography variant='h5'><KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate('/home/dashboard')} style={{
                width: "25px", height: "25px", marginRight: '25px',
                borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
              }} />Settings</Typography>
            </div>
            <br />
            <hr />
            <br />
            <div style={{ display: 'flex', justifyContent: 'Left' }}>
                <Typography variant='h6' sx={{mr:10}} >Change Password : </Typography>
                <Button sx={{backgroundColor:'#660066',":hover":{backgroundColor:'#660066'}}} variant="contained" onClick={() => { setChangePasswordModal(true) }}>Click Here</Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'Left',marginTop:15 }}>
                <Typography variant='h6' sx={{mr:8}} >Change Reg  Mail  ID : </Typography>
                <Button sx={{backgroundColor:'#660066',":hover":{backgroundColor:'#660066'}}} variant="contained" onClick={() => { setChangeMailIdModal(true) }}>Click Here</Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'Left',marginTop:15 }}>
                <Typography variant='h6' sx={{mr:7}} >Change Reg Mob No : </Typography>
                <Button sx={{backgroundColor:'#660066',":hover":{backgroundColor:'#660066'}}} variant="contained" onClick={() => { setChangeMobNoModal(true) }}>Click Here</Button>
            </div>
            <ChangePassword visiblity={changePasswordModal} setVisbility={setChangePasswordModal} />
            <ChangeMailId visiblity={changeMailIdModal} setVisbility={setChangeMailIdModal} />
            <ChangeMobNo visiblity={changeMobNoModal} setVisbility={setChangeMobNoModal} />
        </Fragment>
    )
}

interface IChangePassword {
    visiblity: boolean,
    setVisbility: any
}

const ChangePassword: FC<IChangePassword> = (props: IChangePassword): ReactElement => {
    
    const userId = useSelector((state: RootState) => state.user.id)
    const dispatch = useDispatch()

    const { visiblity, setVisbility } = props;

    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const [validForm, setValidForm] = useState(false)

    const { oldPassword, newPassword, confirmPassword } = formData;

    const onChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        checkValidation()
    }

    const checkValidation = () => {
        if (oldPassword !== "" && newPassword !== "" && confirmPassword !== "" && newPassword === confirmPassword) {
            setValidForm(true)
        } else {
            setValidForm(false)
        }
    }

    const onClick = async (e: any) => {
        e.preventDefault();
        const response = await changePassword({ oldPassword, newPassword, userId })
        setAlert(dispatch, response.data.message, response.data.status)
        setVisbility(false)
        setFormData({ oldPassword: '', newPassword: '', confirmPassword: '' })
    }

    return (
        <Modal sx={{color:'#CC99CC',marginTop:'15%'}} open={visiblity} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{position: "absolute",top: "30%",left: "50%",transform: "translate(-50%, -50%)",width: 700,bgcolor: "background.paper",border: "2px solid #660066",boxShadow: 24}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{color:'#660066'}} id="modal-modal-title" variant="h6" component="h2">
                        Change Password
                    </Typography>
                    <Button onClick={() => { setVisbility(false) }}><Close sx={{color:'#660066'}}/></Button>
                </Box>
                <br />
                <Box sx={{ mt: 1 }}>
                    <TextField color="secondary" margin="normal" type="text" required fullWidth id="oldPassword" label="Old Password" name="oldPassword" onChange={onChange} value={oldPassword} />
                    <TextField color="secondary" margin="normal" type="text" required fullWidth id="newPassword" label="New Password" name="newPassword" onChange={onChange} value={newPassword} />
                    <TextField color="secondary" margin="normal" type="password" required fullWidth id="confirmPassword" label="Confirm Password" name="confirmPassword" onChange={onChange} value={confirmPassword} />
                    <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2,backgroundColor:'#660066',":hover":{backgroundColor:'#660066'}}} disabled={validForm} onClick={onClick}> Change Password</Button>
                </Box>
            </Box>
        </Modal>
    )
}

interface IChangeMailId {
    visiblity: boolean,
    setVisbility: any
}
const ChangeMailId: FC<IChangeMailId> = (props: IChangeMailId): ReactElement => {

    // const userId = useSelector((state: RootState) => state.user.id)
    const dispatch = useDispatch()

    const { visiblity, setVisbility } = props;

    const [formData, setFormData] = useState({
        oldMailId: '',
        newMailId: '',
        userId: ''
    })

    const [validForm, setValidForm] = useState(false)

    const { oldMailId, newMailId, userId } = formData;

    const onChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        checkValidation()
    }

    const checkValidation = () => {
        if (oldMailId !== "" && newMailId !== "" && userId !== "" && oldMailId !== newMailId) {
            setValidForm(true)
        } else {
            setValidForm(false)
        }
    }

    const onClick = async (e: any) => {
        e.preventDefault();
        const response = await changeRegMailId({ oldMailId, newMailId, userId })
        setAlert(dispatch, response.data.message, response.data.status)
        setVisbility(false)
        setFormData({ oldMailId: '', newMailId: '', userId: '' })
    }

    return (
        <Modal sx={{color:'#CC99CC',marginTop:'15%'}} open={visiblity} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{position: "absolute",top: "30%",left: "50%",transform: "translate(-50%, -50%)",width: 700,bgcolor: "background.paper",border: "2px solid #660066",boxShadow: 24}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{color:'#660066'}} id="modal-modal-title" variant="h6" component="h2">
                        Change Registered MailID
                    </Typography>
                    <Button onClick={() => { setVisbility(false) }}><Close sx={{color:'#660066'}}/></Button>
                </Box>
                <br />
                <Box sx={{ mt: 1 }}>
                    <TextField color="secondary" margin="normal" type="text" required fullWidth id="oldMailId" label="Old MailId" name="oldMailId" onChange={onChange} value={oldMailId} />
                    <TextField color="secondary" margin="normal" type="text" required fullWidth id="newMailId" label="New MailId" name="newMailId" onChange={onChange} value={newMailId} />
                    {/* <TextField color="secondary" margin="normal" type="text" required fullWidth id="userId" label="User Id" name="userId" onChange={onChange} value={userId} /> */}
                    <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2,backgroundColor:'#660066',":hover":{backgroundColor:'#660066'}}} disabled={validForm} onClick={onClick}> Change MailID</Button>
                </Box>
            </Box>
        </Modal>
    )
}

interface IChangeMobNo {
    visiblity: boolean,
    setVisbility: any
}
const ChangeMobNo: FC<IChangeMobNo> = (props: IChangeMobNo): ReactElement => {

    const userId = useSelector((state: RootState) => state.user.id)
    const dispatch = useDispatch()

    const { visiblity, setVisbility } = props;

    const [formData, setFormData] = useState({
        phonenumber: ''
    })

    const [validForm, setValidForm] = useState(false)

    const { phonenumber } = formData;

    const onChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        checkValidation()
    }

    const checkValidation = () => {
        if (phonenumber !== ""&& userId !== "" && phonenumber!==userId) {
            setValidForm(true)
        } else {
            setValidForm(false)
        }
    }

    const onClick = async (e: any) => {
        e.preventDefault();
        const response = await updatePhoneNumber({ phonenumber, userId })
        console.log(response)
        setAlert(dispatch, response.data.message, response.data.status)
        setVisbility(false)
        setFormData({ phonenumber: '' })
    }

    return (
        <Modal sx={{color:'#CC99CC',marginTop:'15%'}} open={visiblity} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{position: "absolute",top: "30%",left: "50%",transform: "translate(-50%, -50%)",width: 700,bgcolor: "background.paper",border: "2px solid #660066",boxShadow: 24}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{color:'#660066'}} id="modal-modal-title" variant="h6" component="h2">
                        Change Mobile No
                    </Typography>
                    <Button onClick={() => { setVisbility(false) }}><Close sx={{color:'#660066'}}/></Button>
                </Box>
                <br />
                <Box sx={{ mt: 1 }}>
                    <TextField color="secondary" margin="normal" type="text" required fullWidth id="phonenumber" label="Phone Number" name="phonenumber" onChange={onChange} value={phonenumber} />
                    {/* <TextField color="secondary" margin="normal" type="text" required fullWidth id="userId" label="User Id" name="userId" onChange={onChange} value={userId} /> */}
                    <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2,backgroundColor:'#660066',":hover":{backgroundColor:'#660066'}}} disabled={validForm} onClick={onClick}> Change PhoneNo</Button>
                </Box>
            </Box>
        </Modal>
    )
}
export default Settings