import React, { ReactElement, FC } from "react";
import {Avatar, Typography,Card, Stack } from '@mui/material'
import { RootState } from "src/store";
import person from '../assets/walk.png'
import Animal from '../assets/dog.png'
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";



interface AlertViewProps{
    alerts:any[]
}
const AlertView: FC<AlertViewProps> = (props:AlertViewProps): ReactElement => {
    const{id}=useParams()
    const organanizationCameras=useSelector((state:RootState)=>state.organization.cameras)
    const{alerts}=props
    // console.log(alerts[0],'alerts[0]')
    const Image=(value:any)=>{
        if(value.includes('animal')){
            return Animal
        }
        else{
            return person
        }

    }
    const CameraName=()=>{
        const value=organanizationCameras.filter((x:any)=>x.id===id)[0]
        // console.log(value,'value')
        return value.name
        
    }
    const DetectedThings=(data:any[])=>{
        const val:any[]=[]
        
            data.map((value:any,index:any)=>{
                if(value!==''){
                val.push(value)
                if(index<(data.length-1)){
                    val.push(',')
                }
                    
            }
            })
       return val
    }
    return (
        <>
            {alerts && alerts.filter((_,idx:any)=>idx<50).map((value:any,index:any)=>{
                return (                    
                    <Stack direction="row" spacing={2} sx={{textAlign:'center',justifyContent:'center',zIndex:'500'}} mt={2} key={index}>
                     <Avatar alt='animal' src={Image(value.alertStatus.detectedThing)} sx={{width:'50px',height:'50px',marginTop:'0%'}}>
                     </Avatar>
                      {/* <Paper elevation={4} style={{ borderRadius:'50%',marginLeft:'15%' }}>  */}
                         <Card sx={{ width: '25%', height: 'auto', borderRadius: '20px',border:'2px solid #000000',padding:'5px',zIndex:'500' }}>
                             {/* <CardContent> */}
                             {/* <Stack direction="row" spacing={21} sx={{borderRadius:'15%',padding:'1%'}}> */}
                                 <Typography variant="body2" color="text.primary" fontWeight='bold' fontSize={16}>
                                    Detected: {DetectedThings(value.alertStatus.detectedThing)}
                                 </Typography>
                                 <Typography variant="body2" color="text.primary" fontWeight='bold' fontSize={16} >
                                    Date: {value.alertStatus.dateTime.split('__')[0].replaceAll('_','/')}
                                 </Typography>
                                 <Typography variant="body2" color="text.primary" fontWeight='bold' fontSize={16}>
                                    Time: {value.alertStatus.dateTime.split('__')[1].replaceAll('_',':')}
                                 </Typography>
                                 <Typography variant="body2" color="text.primary" fontWeight='bold' fontSize={16}>
                                    Location: {CameraName()}
                                 </Typography>
                                 {/* </Stack> */}
                             {/* </CardContent> */}
                         </Card>
                     {/* </Paper>  */}
                  </Stack>
                 
                )
                
            })}
             
        </>
        

    )



}

export default AlertView;





