import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Label,
    Legend,
    Cell,
  } from "recharts";
  import { FC, ReactElement,useEffect,useState } from 'react';
  import moment from "moment";
  import { scaleOrdinal } from 'd3-scale';
  import { schemeCategory10 } from 'd3-scale-chromatic';
  interface BarChartProps {
    data: any
    height: number,
    width: number,
  }
  interface propsProps{
      _id: {
        year?: number,
        month?: number
      },
      total_hours?: number,
      total_units?: number,
      total_cost_Saved?: number
  }
  const getPath = (x: number, y: number, width: number, height: number) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
      y + height / 3
    } 
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
      x + width
    }, ${y + height}
    Z`;
  };
  
  const TriangleBar: FC<any> = (props: any) => {
    const { fill, x, y, width, height} = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const  val = 5
  function getRandColor(brightness: any) {

    // Six levels of brightness from 0 to 5, 0 being the darkest
    const  rgba = [Math.random() * 256/2, Math.random() * 256, Math.random() * 256/2];
    const mix = [brightness * val, brightness * val, brightness * val]; //51 => 255/5
    const  mixedrgb = [rgba[0] + mix[0], rgba[1] + mix[1], rgba[2] + mix[2]].map(function (x) { return Math.round(x / 2.0) })
    return "rgba(" + mixedrgb.join(",") + ")";
}


  const NumberofUnitSaved: FC<BarChartProps> = (props: BarChartProps): ReactElement => {
    const { data } = props;

    const [hours,setHours]=useState([0])
    
    function getArrayMax(array:any){
      const maxHr= Math.max.apply(null, array);
      const n=(maxHr.toString().length)-1
      const maxHour=(Number((maxHr/10**n).toFixed(0))*10**n)+10**n     
      return maxHour;
   }


   const maxHour=getArrayMax(hours)
    useEffect(()=>{
      setHours(()=>[0])
      data.map((obj:any,index:any)=>{

          setHours((prev:any[])=>[...prev,obj.total_cost_Saved])
          
      })
    },[data])
    return (
      <BarChart
        width={props.width}
        height={props.height}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={(props: propsProps) => {
        return moment(`${props._id.month}/01/${props._id.year}`).format("MMM")
      }}
        >
          <Label
            style={{
              textAnchor: "middle",
              fontSize: "130%",
              fill: "#ccc",
            }}
            angle={0}
            position="bottom"
            value='' />
        </XAxis>
        <YAxis offset={5} dataKey={(props: any) => {

          return props.total_cost_Saved
        }}
        type='number'
        domain={[0,maxHour]}
        
        >
          <Label
            style={{
              textAnchor: "middle",
              fontSize: "110%",
              fill: "#ccc",
            }}
            angle={270}
            position="left"
            value='Cost in Rs.' />
        </YAxis>
        <Tooltip />
        <Legend />
        <Bar dataKey="total_cost_Saved" 
        fill="#660066" shape={<TriangleBar/>} barSize={25} label={{ position: "top"}}>

            {data.map((entry:any, index:any) => (
          <Cell key={`cell-${index}`} fill={getRandColor(18)} />
        ))}

        </Bar>
          
      </BarChart>
    )
  }
  export default NumberofUnitSaved;