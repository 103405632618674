import { Typography, Button,Box} from '@mui/material';
import { FC, Fragment, ReactElement, useMemo} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store';
import { IBuilding, IFloor, IAlertDevice, IDevice } from 'src/types';
// import dateComparator  from 'src/utils/commonMethods';
import { ColDef, GroupCellRendererParams, ValueGetterParams } from 'ag-grid-community';
import GridView from 'src/components/homePage/GridView';
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
// import { Box } from '@mui/system';
import '../../../screens/SwitchApps.css';
import { SET_SELECTEDDEVICEDATA } from 'src/store/actions/actionTypes';
import { getAlertByDevice } from 'src/services/alertDeviceService';


import * as React from 'react';


import { useParams } from 'react-router-dom';


// interface DeviceHomeProps {
// }

const ActiveDevices: FC = (props): ReactElement => {

    const devices = useSelector((state: RootState) => state.devices.devices)
    const floors = useSelector((state: RootState) => state.floors.floors)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    const alerts = useSelector((state: RootState) => state.alerts)
    // const [datas, setDatas] = React.useState<IDevice[]>([]);
    // console.log(devices,'devices')
    const activeDevices=devices.filter((x:any)=>x.deviceActiveStatus==='Active'&& x.deviceStatus==='Enable')
    // console.log(activeDevices,'activeDevices')
    const getBuildingData = (buildingId: string): string | undefined | null => {
        const buildingData = buildings.find((x: IBuilding) => x.id === buildingId)
        return buildingData ? buildingData.name : ''
    }

    const getFloorData = (floorId: string): string | undefined | null => {
        const floorData = floors.find((x: IFloor) => x.id === floorId)
        return floorData ? floorData.name : ''
    }

    const getRoomData = (roomId: string): string | undefined | null => {
        const roomData = rooms.find((x: IFloor) => x.id === roomId)
        return roomData ? roomData.number : ''
    }

//     const RefreshHome = async (data:IDevice) => {
//         const response = await getAlertByDevice({ deviceId:data.id })       
//         if (response.status === 200) {
//             if(data.deviceActiveStatus==='Active'&& data.deviceStatus==='Enable'){
//                 setDatas((alertPrev)=>[...alertPrev,data])
//             }       
            
//     }

// }
        
//         React.useEffect(()=>{
//         {devices.map((data: IDevice, index: number) => {
//         RefreshHome(data);})
//         }
//         },[])





    const dispatch = useDispatch()
    const navigate = useNavigate()



    const onhandleClick = (data: IDevice) => {
        dispatch({ type: SET_SELECTEDDEVICEDATA, payload: data })
        navigate(`/home/devices/${data._id}`)
    }


    const columnDefs: ColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: 'Name',
            maxWidth: 450,
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            cellRenderer: (params: GroupCellRendererParams) => {
                switch(params.data.deviceActiveStatus){
                    case 'Active':
                      return <Button onClick={() => onhandleClick(params.data)}> {`Device - ${params.data.name}`}</Button>
                    case 'Inactive':
                      return ''
                    default:
                      return ''

                }
            },
            sort:'desc'
        },
        {
            field: 'roomId',
            headerName: 'Room',
            maxWidth: 100,
            flex: 1,
            filter: 'agTextColumnFilter', 
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            valueGetter: (params: ValueGetterParams) => {
                const val = getRoomData(params.data.roomId)
                return val !== '' ? val : 'NA'
            }
        },
        {
            field: 'floorId',
            headerName: 'Floor',
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            valueGetter: (params: ValueGetterParams) => {
                const val = getFloorData(params.data.floorId)
                return val !== '' ? val : 'NA'
            }
        },
        {
            field: 'buildingId',
            headerName: 'Building',
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            valueGetter: (params: ValueGetterParams) => {
                const val = getBuildingData(params.data.buildingId)
                return val !== '' ? val : 'NA'
            }

        },
        {
            field: 'device status',
            headerName: 'Device Status',
            maxWidth: 350,
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            valueGetter: (params: ValueGetterParams) => {               
                switch(params.data.deviceStatus){
                    case 'Enable':
                      return 'Enabled'
                    case 'Disabled':
                      return 'Disabled'
                    default:
                      return 'Disabled'
                  }
             }
        },
        {
            field: 'active status',
            headerName: 'Active Status',
            maxWidth: 350,
            flex: 1,
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {               
                switch(params.data.deviceActiveStatus){
                    case 'Active':
                      return 'Active'
                    case 'Inactive':
                      return 'In Active'
                    default:
                      return 'In Active'
                  }
             }
        },
    ], [devices, rooms, floors, buildings]);

    const refreshDataGrid=()=>{
        navigate('../home')
          setTimeout(()=>{navigate(`../activedevices/`)},500)
      }
    return (
        <Fragment>
            
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h5'><KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate('/home/dashboard')} style={{
                width: "25px", height: "25px", marginRight: '25px',
                borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
              }} />Active Devices </Typography>
            </div>
            <hr />
            <div>
            <Button variant='contained' sx={{ backgroundColor: '#660066',float:'left', ":hover": { backgroundColor: '#660066' } }} onClick={()=>{navigate('checkdevices')}}>Check Devices</Button>
            <Button variant='contained' sx={{ backgroundColor: '#660066',float:'right', ":hover": { backgroundColor: '#660066' } }} onClick={refreshDataGrid}>Refresh</Button>
            </div>
            <br/>
            <br/>
            <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs} rowData={activeDevices}/>
            </Box>
            
        </Fragment>
    )
}

export default ActiveDevices;
