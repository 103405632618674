import { DataArray } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import weather from './weather';
import { Typography } from '@mui/material'
import { data02 } from "src/screens/Home/Building/Data";
import WeatherWidgetImage from './assets/WeatherWidget.gif'
import Cloudy from './assets/Cloudy.gif'
import Drizz from './assets/Drizz.gif'
import dust from './assets/dust.gif'
import fog from './assets/fog.gif'
import Haze from './assets/Haze.gif'
import Rain from './assets/RAIN.gif'
import smoke from './assets/smoke.gif'
import Snow from './assets/Snow.gif'
import tornado from './assets/tornado.gif'
const WeatherWidget = () => {

  const [lat, setLat] = useState([] as any);
  const [long, setLong] = useState([] as any);
  const [data, setData] = useState([] as any);

  useEffect(() => {
    const fetchData = async () => {
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
      });
      // await fetch(`${weather.REACT_APP_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${weather.REACT_APP_API_KEY}`)
      //   .then(res => res.json())
      //   .then(result => {
      //     setData(result)
      //     console.log(result);
      //   });
    }
    fetchData();
  }, [lat, long])
  const getIcon = (data: any) => {
    switch (data.weather[0].main) {
      case "Haze":
        return Haze
        break;
      case "Clouds":
        return Cloudy
        break;
      case "Rain":
        return Rain
        break;
      case "Snow":
        return Snow
        break;
      case "Dust":
        return dust
        break;
      case "Drizzle":
        return Drizz
        break;
      case "Fog":
        return fog
        break;
      case "Smoke":
        return smoke
        break;
      case "Tornado":
        return tornado
        break;
      default:
        return WeatherWidgetImage

    }
  }
  console.log(data, 'data')
  return (
    <div className="App">
      {/* {(typeof data.main != 'undefined') ? (
        <Weather weatherData={data}/>
      ): (
        <div></div>
      )} */}
      {Object.keys(data).length !== 0 && data.cod !== '400' ?
        (
          <div style={{
            height: '250px',borderRadius:'11px',
            backgroundImage: `url(${getIcon(data)})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>

            <div style={{
              height:'250px', width: '25%',borderRadius:'15px',
              // marginLeft:'50%',marginTop:'-84.4%',
              backgroundColor: 'black', opacity: 0.6}}>

              <Typography sx={{
                fontWeight: 'normal', fontSize: '28px',
                fontFamily: 'copperplate,fantasy', color: '#ffffff', float: 'inline-end'
              }}>
                {data.weather[0].main}
              </Typography>
              <Typography sx={{
                fontWeight: 'normal', fontSize: '28px',
                fontFamily: 'copperplate,fantasy', color: '#ffffff', float: 'inline-end', paddingTop: '5%'
              }}>
                {data.main.temp}{'\u00b0'}{'C'}
              </Typography>
          </div>  
        </div>):          
        <div style={{
            height: '250px',borderRadius:'11px',
            backgroundImage: `url(${WeatherWidgetImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>
</div>}
            
      
    </div >
  );
}
export default WeatherWidget