import { Dialog , DialogTitle , DialogContent , DialogContentText, DialogActions, Button } from '@mui/material';
import { FC, ReactElement } from "react";
import { deleteDevice } from 'src/services/deviceService';
type AlertType = "error" | "success" | "info" | "warning" | undefined;
interface DeleteBuildingProps {
    visibility: boolean,
    setVisibility: (a: any) => void;
    refreshHome: () => void;
    data: any;
    statusData:(a: boolean)=>void;
    messagesData:(a:AlertType)=>void
}

const DeleteDevice: FC<DeleteBuildingProps> = (props: DeleteBuildingProps) : ReactElement => {

    const { visibility, setVisibility, refreshHome, data: {name, id},statusData,messagesData}= props;

    const handleClick = async (e:any ) => {
        e.preventDefault();
        const response = await deleteDevice({name,_id: id})
        if(response.status === 200) {
            statusData(true)
            messagesData('warning')
            refreshHome()
            setVisibility({ visible: false })
        }
    }

    const handleClose =() => {
        setVisibility({visible: false})
    }

    return (
        <Dialog open={visibility} onClose={handleClose} aria-labelledby ="alert-dialog-title" aria-describedby = "alert-dialog-description">
            <DialogTitle id="alert-dialog-title"> Delete Device </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure do you want to delete {name} device ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}> Cancel </Button>
                <Button onClick={handleClick} autoFocus> Delete </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDevice