import React,{FC,Fragment,ReactElement, useState} from "react";
import { useMemo,useEffect,useCallback } from "react";
import { Typography,Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { ColDef,GroupCellRendererParams,ValueGetterParams } from "ag-grid-community";
import GridView  from '../../../components/homePage/GridView';
import { getAlertsByOrganizationId } from "../../../services/alertsFarmService";
import '../../../screens/SwitchApps.css'
const AlertReports:FC=():ReactElement=>{
    const farmGuardAlerts=useSelector((state:RootState)=>state.farmGuardAlerts.report_By_Org)
    console.log(farmGuardAlerts,'farmGuardAlerts')
    const organizationCameras=useSelector((state:RootState)=>state.organization.cameras)
    const CameraName=(data:any)=>{
        console.log(data,'dataReport')
        const value = organizationCameras.filter((x:any)=>x.id===data)[0]
        return value? value.name : 'NA'
    }

    const columnDefs:ColDef[]=useMemo(()=>[
        {
            field: 'detected',
            headerName: 'Detected',
            initialWidth: 200,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return params.data.alertStatus.detectedThing
            }
        },
        {
            field: 'location',
            headerName: 'Location',
            initialWidth: 200,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return CameraName(params.data.deviceId)
            }
        },
        {
            field: 'date',
            headerName: 'Detected Date',
            initialWidth: 200,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return (params.data.alertStatus.dateTime).split('__')[0].replaceAll('_','/')
            }
        },
        {
            field: 'time',
            headerName: 'Detected Time',
            initialWidth: 200,
            wrapHeaderText: true,
            flex: 1,
            filterParams: {
                buttons: ['reset', 'apply'],
              },
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {
                return (params.data.alertStatus.dateTime).split('__')[1].replaceAll('_',':')
            }
        },

    ],[farmGuardAlerts])
    return(
        <Fragment>
       <Typography variant="h4">
        Alert Reports
       </Typography>
       <hr/>
       <Box className="ag-theme-alpine" sx={{ height: 600, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs} rowData={farmGuardAlerts}/>
            </Box>
        </Fragment>
    )
}

export default AlertReports