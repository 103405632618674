import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import { FC, Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { RootState } from "src/store";
import { IBuilding } from "src/types";
import { useNavigate } from 'react-router-dom'
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
import { getBuildingDashboardDetails,getDeviceDataGroupbyBuildingId } from "src/services/buildingService";
import NoofTimesDoorOpened from "src/components/homePage/NewChartWidget/NoofTimesDoorOpened";
import TotalUnitsSaved from "src/components/homePage/NewChartWidget/TotalUnitsSaved";
import {buildingDashBoardDetails,deviceDataGroupbyBuildingId} from '../../../store/actions/buildingActions'
import TotalPowerSavedinHours from "src/components/homePage/NewChartWidget/TotalPowerSavedinHours";
import TotalCostSaved from "src/components/homePage/NewChartWidget/TotalCostSaved";
// interface IBuildingView {
// }

const BuildingView: FC = (props): ReactElement => {

  const building: IBuilding = useSelector((state: RootState) => state.buildings.selectedBuilding)
  const application = useSelector((state: RootState) => state.application)
  const organization = useSelector((state: RootState) => state.organization)
  const devices=useSelector((state:RootState)=>state.devices.devices)
  const buildingDashboardData=useSelector((state:RootState)=>state.buildings.buildingDashBoardDetails)
  const deviceDataGroupbyBuilding=useSelector((state:RootState)=>state.buildings.deviceDataGroupByBuildingId)
  const dispatch=useDispatch()
  // const [data, setdata] = useState<any>({
  //   deviceCount: 0,
  //   roomCount: 0,
  //   floorCount: 0,
  //   buildingCount: 0,
  //   chartResponseOpened: [],
  //   chartResponseClosed: [],
  //   cosumptionDetails: [{
  //     "_id": {
  //       "year": 0,
  //       "month": 0
  //     },
  //     "total_hours": 0,
  //     "total_count": 0
  //   }]
  // });
  const [dataFetchStatus, setDataFetchStatus] = useState(false)

  const refreshHome =  useCallback(async () => {
    const response = await getBuildingDashboardDetails({ organizationId: organization.id, applicationId: application.id, buildingId: building._id })
    if (response.status === 200) {
      // setdata(response.data)
      buildingDashBoardDetails(dispatch,response.data)
      setDataFetchStatus(true)
    }
  },[])

  useEffect(() => {
    if (!dataFetchStatus) {
      refreshHome()
    }
  }, [dataFetchStatus, refreshHome, buildingDashboardData])


  const navigate = useNavigate()
  
  
  // const [data1,setData1]=useState<any>({
  //   group_chartResponses:[]
  // })
  const group_chartResponses=async()=>{
    const response=await getDeviceDataGroupbyBuildingId({organizationId:organization.id,buildingId: building._id})
    if(response.status===200){
      deviceDataGroupbyBuildingId(dispatch,response.data)
      // setData1(response.data)
    }
  }
  useEffect(()=>{
    group_chartResponses();
  },[buildingDashboardData])

  return (
    <Fragment>
      {
        building ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant='h5'>  <KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate(-1)} style={{
                width: "25px", height: "25px", marginRight: '25px',
                borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
              }} /> Hostel - {building.name} </Typography>
            </div> <hr /> <br />  
            {/* <Typography>  {building.description} </Typography>  */}
            <br />
            <Grid justifyContent='center' alignItems='flex-start' direction={'column'}>
              <Grid container spacing={2} direction={'row'}>
                <Grid item xs={4}>
                  <Card sx={{
                    borderRadius: '25px',
                    background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                    boxShadow: "rgb(152 145 145) 1px 5px 15px"
                  }}>
                    <CardContent>
                      <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                        Total Floors in this Building
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                        {buildingDashboardData.floorCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card sx={{
                    borderRadius: '25px',
                    background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                    boxShadow: "rgb(152 145 145) 1px 5px 15px"
                  }}>
                    <CardContent>
                      <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                      Total Rooms in this Building
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                        {buildingDashboardData.roomCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card sx={{
                    borderRadius: '25px',
                    background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                    boxShadow: "rgb(152 145 145) 1px 5px 15px"
                  }}>
                    <CardContent>
                      <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                        Total Devices in this Building
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                        {buildingDashboardData.deviceCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction={'row'} mt={1}>
                <Grid item xs={6}>
                  <Card sx={{
                    borderRadius: '25px',
                    background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                    boxShadow: "rgb(152 145 145) 1px 5px 15px"
                  }}>
                    <CardContent>
                      <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                      Total Units Saved
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                        {deviceDataGroupbyBuilding.group_chartResponses.length!==0 ? (deviceDataGroupbyBuilding.group_chartResponses.reduce((prev:any,curr:any)=> {return prev + +curr.total_units},0)):0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card sx={{
                    borderRadius: '25px',
                    background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                    boxShadow: "rgb(152 145 145) 1px 5px 15px"
                  }}>
                    <CardContent>
                      <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                        Total Cost Saved
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                        {/* {data.cosumptionDetails.length!==0 ?(data.cosumptionDetails[0].total_count):0} */}
                        {deviceDataGroupbyBuilding.group_chartResponses.length!==0 ? (deviceDataGroupbyBuilding.group_chartResponses.reduce((prev:any,curr:any)=> {return prev + +curr.total_cost_Saved},0)):0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid container spacing={2} direction={'row'} mt={1}>
                  <Grid item xs={6}>
                    <Paper elevation={4} style={{ borderRadius: '25px' }}>
                      <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                        <CardContent>
                          <Typography color="text.secondary" sx={{ mb: 2 }}>
                            Total Number of times Door Opened
                          </Typography>
                          <NoofTimesDoorOpened data={buildingDashboardData.chartResponseOpened} height={300} width={500} />
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Total Power Saved in Hours
                      </Typography>
                      <TotalPowerSavedinHours data={buildingDashboardData.chartResponseClosed} height={300} width={500} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid> */}
            <Grid container columnSpacing={2} sx={{ mt: 5 }}>
              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                       Total Units Saved
                      </Typography>
                      <TotalUnitsSaved data={deviceDataGroupbyBuilding.group_chartResponses} height={300} width={500}/>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>


                  <Grid item xs={6}>
                    <Paper elevation={4} style={{ borderRadius: '25px' }}>
                      <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                        <CardContent>
                          <Typography color="text.secondary" sx={{ mb: 2 }}>
                          Total Cost Saved in Rs.
                          </Typography>
                          <TotalCostSaved data={deviceDataGroupbyBuilding.group_chartResponses} height={300} width={500}/>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                </Grid> 
          </>
        ) : (
          <></>
        )
      }
    </Fragment >
  )
}


export default BuildingView