import React, { ReactElement, FC } from "react";
import { useState, useEffect } from "react";
import {Avatar, Grid, Typography, Button, Box, Card, CardContent, CardMedia, IconButton, useTheme, CardActionArea, CardActions, Paper, Divider, Chip, Stack } from '@mui/material'
import HistoryIcon from '@mui/icons-material/History';
import person from './assets/walk.png'
import Animal from './assets/dog.png'
import { useSelector } from "react-redux";
import { RootState } from "src/store";

interface activitiesRecentProps{
    alerts:any[]
}
const Activities: FC<activitiesRecentProps> = (props:activitiesRecentProps): ReactElement => {
    const organanizationCameras=useSelector((state:RootState)=>state.organization.cameras)
    const {alerts}=props

    const Image=(value:any)=>{
        if(value.includes('animal')){
            return Animal
        }
        else{
            return person
        }

    }
    const CameraName=(loc:any)=>{
        //  console.log(loc,'loc')
        const value=organanizationCameras.filter((x:any)=>x.id===loc)[0]
        // console.log(value,'value')
        return value?value.name:'NA'
        
    }
    const DetctedName=(data:any)=>{
        if(data.includes('animal')){
            return 'animal'
        }
        else{
            return 'person'
        }
    }
    return (
        <>
            {alerts.filter((_,idx)=>idx<8).map((value:any,index:any)=>{
                return (
                    <Stack direction="row" spacing={2} sx={{textAlign:'center',justifyContent:'center'}} mt={2} key={index}>
                     <Avatar alt='animal' src={Image(value.alertStatus.detectedThing)} sx={{width:'50px',height:'50px',marginTop:'0%'}}>
                     </Avatar>
                      {/* <Paper elevation={4} style={{ borderRadius:'50%',marginLeft:'15%' }}>  */}
                         <Card sx={{ width: '100%', height: '15%', borderRadius: '20px',border:'2px solid #000000', }}>
                             {/* <CardContent> */}
                             <Stack direction="row" spacing={8} sx={{borderRadius:'15%',padding:'2%'}}>
                                 <Typography variant="body2" color="text.primary" fontWeight='bold' fontSize={16}>
                                     {DetctedName(value.alertStatus.detectedThing)}
                                 </Typography>
                                 <Typography variant="body2" color="text.primary" fontWeight='bold' fontSize={16} >
                                     {value.alertStatus.dateTime.split('__')[0].replaceAll('_','/')}
                                 </Typography>
                                 <Typography variant="body2" color="text.primary" fontWeight='bold' fontSize={16}>
                                     {value.alertStatus.dateTime.split('__')[1].replaceAll('_',':')}
                                 </Typography>
                                 <Typography variant="body2" color="text.primary" fontWeight='bold' fontSize={16}>
                                     {CameraName(value.deviceId)}
                                 </Typography>
                                 </Stack>
                             {/* </CardContent> */}
                         </Card>
                     {/* </Paper>  */}
                  </Stack>
                )
                
            })}
        </>
        

    )



}

export default Activities;





