import React from 'react'
import { Dispatch } from 'react'
import {
    SET_FARMALERTSBYORGANISATION, SET_FARMALERTSBYCAMERA,
    SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION,SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE,SET_FARM_ALERTS_REPORT_By_ORG
} from './actionTypes'
export const refreshFarmGuardData = (dispatch: Dispatch<any>, data: any[]) => {
    dispatch({ type: SET_FARMALERTSBYORGANISATION, payload: { value: data, fetchStatus: false } })
}

export const refreshFarmGuardDataByCamera = (dispatch: Dispatch<any>, data: any[]) => {
    dispatch({ type: SET_FARMALERTSBYCAMERA, payload: { value: data } })
}

export const refresh_chart_By_org = (dispatch: Dispatch<any>, data: any[]) => {
    dispatch({ type: SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION, payload: {data} })

}

export const refresh_chart_By_device = (dispatch: Dispatch<any>, data: any[]) => {
    dispatch({ type: SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE, payload: {data}})

}
export const refresh_Report_By_Org=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:SET_FARM_ALERTS_REPORT_By_ORG,payload:{value:data}})
}
