import { Dispatch } from "react";
import { CREATE_STUDENT,UPDATE_STUDENT,DELETE_STUDENT,SET_STUDENTDATA } from "./actionTypes";

export const createSTUDENT=(dispatch:Dispatch<any>,studentName:string)=>{
    dispatch({type:CREATE_STUDENT,studentName})
}

export const deleteSTUDENT=(dispatch:Dispatch<any>,_id:string)=>{
    dispatch({type:DELETE_STUDENT,_id})

}
export const updateSTUDENT=(dispatch:Dispatch<any>,_id:string)=>{
    
    dispatch({type:UPDATE_STUDENT,_id})

}

export const refreshStudentData=(dispatch:Dispatch<any>,data:string)=>{
    dispatch({type:SET_STUDENTDATA,payload:{value:data,fetchStatus:true}})

}