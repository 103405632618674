import * as React from 'react';
import {FC,ReactElement} from 'react';
export interface roleProps{
    role:string
}
interface TopNavBarProps {
    appName: string,
}
interface SearchFieldProps {
    getData: (x: string) => void;
}
type Props = roleProps & TopNavBarProps & SearchFieldProps;
const SuperAdminTopNavBar:FC<Props> = (props:Props):ReactElement=>{
    const {role}=props
return(
    <></>
)

}

export default SuperAdminTopNavBar;