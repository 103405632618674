export const SET_USERDATA = "SET_USERDATA"
export const SET_USERTOKEN = "SET_USERTOKEN"
export const RESET_USER = "RESET_USER"
export const CREATE_ORG = "CREATE_ORG"
export const UPDATE_ORG = "UPDATE_ORG"
export const DELETE_ORG = "DELETE_ORG"
export const SET_ORGANIZATIONDATA = "SET_ORGANIZATIONDATA"
export const RESET_ORGANIZATION = "RESET_ORGANIZATION"
export const SET_ROLEDATA = "SET_ROLEDATA"
export const RESET_ROLE = "RESET_ROLE"
export const SHOW_LOADER = "SHOW_LOADER"
export const HIDE_LOADER = "HIDE_LOADER"
export const CREATE_APP = "CREATE_APP"
export const UPDATE_APP ="UPDATE_APP"
export const DELETE_APP = "DELETE_APP"
export const  SET_APPLICATIONDATA = "SET_APPLICATIONDATA"
export const RESET_APPLICATION = "RESET_APPLICATION"
export const CREATE_DEVICE = "CREATE_DEVICE"
export const UPDATE_DEVICE = "UPDATE_DEVICE"
export const SET_SELECTEDDEVICEDATA = "SET_SELECTEDBUILDINGDATA"
export const DELETE_DEVICE = "DELETE_DEVICE"
export const SET_DEVICEDATA = "SET_DEVICEDATA"
export const RESET_DEVICE = "RESET_DEVICE"
export const VIEW_DEVICE = "VIEW_DEVICE"
export const SET_SELECTEDFLOORDATA = "SET_SELECTEDFLOORDATA"
export const SET_FLOORDATA = " SET_FLOORDATA"
export const RESET_FLOOR = "RESET_FLOOR"
export const CREATE_FLOOR = "CREATE_FLOOR"
export const UPDATE_FLOOR ="UPDATE_FLOOR"
export const DELETE_FLOOR = "DELETE_FLOOR"
export const SET_BUILDINGDATA = "SET_BUILDINGDATA"
export const SET_SELECTEDBUILDINGDATA = "SET_SELECTEDBUILDINGDATA"
export const RESET_BUILDING = "RESET_BUILDING"
export const CREATE_BUILDING = "CREATE_BUILDING"
export const UPDATE_BUILDING = "UPDATE_BUILDING"
export const DELETE_BUILDING = "DELETE_BUILDING"
export const SET_ROOMDATA = "SET_ROOMDATA"
export const RESET_ROOM = "RESET_ROOM"
export const CREATE_ROOM = "CREATE_ROOM"
export const UPDATE_ROOM = "UPDATE_ROOM"
export const DELETE_ROOM = "DELETE_ROOM"
export const SET_SELECTEDROOMDATA = "SET_SELECTEDROOMDATA"
export const SET_ALLAPPLICATIONS = "SET_ALLAPPLICATIONS"
export const SET_ACCESSEDAPPLICATIONS = "SET_ACCESSEDAPPLICATIONS"
export const SET_ALERT = "SET_ALERT"
export const REMOVE_ALERT = "REMOVE_ALERT"
export const CREATE_ALERT = "CREATE_ALERT"
export const SET_ALERTBYDEVICE ="SET_ALERTBYDEVICE"
export const CREATE_INSTRUMENTS="CREATE_INSTRUMENTS"
export const UPDATE_INSTRUMENTS="UPDATE_INSTRUMENTS"
export const DELETE_INSTRUMENTS="DELETE_INSTRUMENTS"
export const SET_INSTRUMENTSDATA="SET_INSTRUMENTSDATA"
export const SET_ALERTSBYDEVICE  = "SET_ALERTSBYDEVICE"
export const REMOVE_ALERTS = "REMOVE_ALERTS"
export const CREATE_STUDENT="CREATE_STUDENT"
export const DELETE_STUDENT="DELETE_STUDENT"
export const UPDATE_STUDENT="UPDATE_STUDENT"
export const SET_STUDENTDATA="SET_STUDENTDATA"
export const SET_SELECTEDSTUDENTDATA="SET_SELECTEDSTUDENTDATA"
export const SET_FARMALERTSBYORGANISATION="GET_FARMALERTSBYORGANISATION"
export const SET_FARMALERTSBYCAMERA="GET_FARMALERTSBYCAMERA"
export const SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION='SET_ALERTS_FARMGUARD_DAY_COUNT_BY_ORGANIZATION'
export const SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE='SET_ALERTS_FARMGUARD_DAY_COUNT_BY_DEVICE'
export const SET_FARM_ALERTS_REPORT_By_ORG='SET_FARM_ALERTS_REPORT_By_ORG'
export const SET_ALL_DEVICE_DATA_BY_ORG='SET_ALL_DEVICE_DATA_BY_ORG'
export const SET_DEVICE_ALERT_BY_MONTH_SERVICE='SET_DEVICE_ALERT_BY_MONTH_SERVICE'
export const FLOOR_DASHBOARD_DETAILS='FLOOR_DASHBOARD_DETAILS'
export const GROUP_DATA_BY_FLOORID='GROUP_DATA_BY_FLOORID'
export const SET_BUILDING_HOME_DATA='SET_BUILDING_HOME_DATA'
export const BUILDING_DASHBOARD_DETAILS='BUILDING_DASHBOARD_DETAILS'
export const GROUP_DATA_BY_BUILDINGID='GROUP_DATA_BY_BUILDINGID'
export const ROOM_DASHBOARD_DETAILS='ROOM_DASHBOARD_DETAILS'
