import React, { ReactElement, useEffect } from 'react';
import { useState,FC } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
type AlertType = "error" | "success" | "info" | "warning" | undefined;
interface notificationProps{
    message:AlertType,
    status:boolean
}

const Notification:FC<notificationProps>=(props:notificationProps):ReactElement=>{
    const{message,status}=props;
    // console.log(status,message)

    const messages=[
        {type:'success',content:'Added Successfully !'},
        {type:'warning',content:'Deleted Successfully !'},
        {type:'info',content:'Updated Successfully !'}
    ]
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        setOpen(status)
    },[status])
    
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const handleClose1 = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    
    
    const source=message===undefined?'success':messages.filter((x)=>x.type===message)[0].content
    return(
        <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose1}>
                <Alert onClose={handleClose1} severity={message}>
                    {source}
                </Alert>
            </Snackbar>
        </>
    )

}
export default Notification