import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
export default function DataCard(props:any) {
    const {user}=props
    const { org } = props
  return (
    <>
    <Card sx={{ maxWidth: 400,borderRadius:'25px',boxShadow: "rgb(152 145 145) 1px 5px 15px",}}>
      <CardMedia
        component='img'
        height="200"
        src={org.orgCoverImg}
        alt="Cover Image"
      />
      <CardContent sx={{}}>
        <Typography color='#660066' gutterBottom variant="h5" component="div">
          My Profile
        </Typography>
        <br/>
        <p style={{alignItems:'center',display:'flex' }}><Typography sx={{width:'100px'}} >Regn ID</Typography><Typography sx={{width:'10px'}} >:</Typography><Typography sx={{borderBottom:'2px solid #660066',width:'250px'}}>{user.emailId}</Typography></p>
        <br/>
        <p style={{alignItems:'center',display:'flex' }}><Typography sx={{width:'100px'}} >Reg Name</Typography><Typography sx={{width:'10px'}} >:</Typography><Typography sx={{borderBottom:'2px solid #660066',width:'250px'}}>{user.name}</Typography></p>
        <br/>
        <p style={{alignItems:'center',display:'flex' }}><Typography sx={{width:'100px'}} >Role</Typography><Typography sx={{width:'10px'}} >:</Typography><Typography sx={{borderBottom:'2px solid #660066',width:'250px'}}>Admin</Typography></p>
        <br/>
        <p style={{alignItems:'center',display:'flex' }}><Typography sx={{width:'100px'}} >Reg Mail ID</Typography><Typography sx={{width:'10px'}} >:</Typography><Typography sx={{borderBottom:'2px solid #660066',width:'250px'}}>{user.emailId}</Typography></p>
        <br/>
        <p style={{alignItems:'center',display:'flex' }}><Typography sx={{width:'100px'}} >Reg Mob No</Typography><Typography sx={{width:'10px'}} >:</Typography><Typography sx={{borderBottom:'2px solid #660066',width:'250px'}}>{user.phoneNumber}</Typography></p>
        <br/>
        <p style={{alignItems:'center',display:'flex' }}><Typography sx={{width:'100px'}} >Reg Address</Typography><Typography sx={{width:'10px'}} >:</Typography><Typography sx={{borderBottom:'2px solid #660066',width:'250px'}}>Chennai</Typography></p>
        <br/>
        <p style={{alignItems:'center',display:'flex' }}><Typography sx={{width:'100px'}} >City</Typography><Typography sx={{width:'10px'}} >:</Typography><Typography sx={{borderBottom:'2px solid #660066',width:'250px'}}>Chennai</Typography></p>
        <br/>
        <p style={{alignItems:'center',display:'flex' }}><Typography sx={{width:'100px'}} >State</Typography><Typography sx={{width:'10px'}} >:</Typography><Typography sx={{borderBottom:'2px solid #660066',width:'250px'}}>TamilNadu</Typography></p>
        <br/>
      </CardContent>
    </Card>
    </>
  );
}