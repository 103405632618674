import { IDeviceState, IAction,ISelectedDeviceAlerts } from "src/types";
import { SET_DEVICEDATA, SET_SELECTEDDEVICEDATA, RESET_DEVICE,SET_ALL_DEVICE_DATA_BY_ORG,SET_DEVICE_ALERT_BY_MONTH_SERVICE } from "../actions/actionTypes";

const initalState: IDeviceState = {
    selectedDevice: null,
    devices: [],
    allDeviceDataByOrg:{
    response:[{_id: '',total_hours: 0,total_watts: 0,total_units: 0,total_cost_Saved: 0}],
    deviceStatus:[{_id: '',createdOn:'',status: "true",deviceStatus: "Enable",deviceActiveStatus: "Active"}]
},
selectedDeviceAlertsByMonthService:{
    deviceCount: 0,
    roomCount: 0,
    chartResponseOpened: [],
    chartResponseClosed: [],
    cosumptionDetails: [{"_id": {"year": 0,"month": 0},"total_hours": 0,"total_count": 0,}]},
    fetchStatus: false
}

const deviceReducer = (state: IDeviceState = initalState, action: IAction) => {

    const { type, payload } = action;
    // console.log(payload,'###### payload ###########')
    switch (type) {
        case SET_DEVICEDATA:
            return {
                ...state,
                devices: payload.value,
                fetchStatus: payload.fetchStatus
            }
        case SET_SELECTEDDEVICEDATA :
            return {
                ...state,
                selectedDevice : {
                    ...payload
                }
            }    
        case RESET_DEVICE:
            return {
                ...state,
                initalState
            }
        case SET_ALL_DEVICE_DATA_BY_ORG:
            return {
                ...state,
                allDeviceDataByOrg:{response:[...payload.value.response],deviceStatus:[...payload.value.deviceStatus]}
            }
        case SET_DEVICE_ALERT_BY_MONTH_SERVICE:
            return{
                ...state,
                selectedDeviceAlertsByMonthService:{...payload.value}
            }

        default:
            return state
    }
}

export default deviceReducer