import { IInstrumentsState,IAction } from "src/types";
import { SET_INSTRUMENTSDATA } from "../actions/actionTypes";

const initialState:IInstrumentsState={
    instruments:[],
    fetchStatus:false

}

const instrumentReducer=(state:IInstrumentsState=initialState,action:IAction)=>{

    const {type,payload} =action;

    switch(type){
        case SET_INSTRUMENTSDATA:
            return{
                ...state,
                instruments:payload.value,
                fetchStatus:payload.fetchStatus

            }
        default:
            return state;
    }


}
export default instrumentReducer;