import { IBuilding, IFloor, IDevice, IRoom } from "src/types";

export function removeItemFromArray(sourceList: any, removeList: any) {
    let res = [...sourceList]
    removeList.forEach((item: any) => {
        res = removeOne(res, item);
    })
    return res;
}

function removeOne(sourceList: any, value: any) {
    const index = sourceList.indexOf(value);
    if (index >= 0 && index < sourceList.length) {
        return [
            ...sourceList.slice(0, index),
            ...sourceList.slice(index + 1),
        ];
    }
    return sourceList;
}

// export const modalStyle = {position: "absolute" as "absolute",top: "30%",left: "50%",transform: "translate(-50%, -50%)",width: 700,bgcolor: "background.paper",border: "2px solid #660066",boxShadow: 24,p: 4} as any;


export function formatData(data: any) {
    return {
        id: data._id,
        createdDate: new Date(data.createdate),
        ...data
    }
}

// export const dateComparator = {
//     // provide comparator function
//     comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
//         //using moment js
//         var dateAsString = moment(cellValue).format('DD/MM/YYYY');
//         var dateParts = dateAsString.split("/");
//         var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

//         if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
//             return 0
//         }

//         if (cellDate < filterLocalDateAtMidnight) {
//             return -1;
//         }

//         if (cellDate > filterLocalDateAtMidnight) {
//             return 1;
//         }

//         return 0
//     }
// }

function dateComparator(date1:any, date2:any) {
    const date1Number = monthToComparableNumber(date1);
    const date2Number = monthToComparableNumber(date2);
  
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
  
    return date1Number - date2Number;
  }
  
  // eg 29/08/2004 gets converted to 20040829
  function monthToComparableNumber(date:any) {
    if (date === undefined || date === null || date.length !== 21) {
      return null;
    }
  
    const yearNumber = Number.parseInt(date.substring(6, 10));
    const monthNumber = Number.parseInt(date.substring(3, 5));
    const dayNumber = Number.parseInt(date.substring(0, 2));
    const hours = Number.parseInt(date.substring(13,15));
    const minutes = Number.parseInt(date.substring(16, 18));
    const seconds = Number.parseInt(date.substring(19, 21));
    const Total=hours*10000+minutes*100+seconds
    const days=yearNumber * 10000000000 + monthNumber * 100000000 + dayNumber*1000000+Total;
    return days ;
  }
export default dateComparator;

export const getBuildingData = (buildings: IBuilding[], buildingId: string): any => {
    const buildingData = buildings.find((x: IBuilding) => x.id === buildingId)
    return buildingData
}

export const getFloorData = (floors: IFloor[], floorId: string): any => {
    const floorData = floors.find((x: IFloor) => x.id === floorId)
    return floorData
}

export const getDeviceData = (devices: IDevice[], deviceId: string): any => {
    const deviceData = devices.find((x: IDevice) => x.id === deviceId)
    return deviceData
}

export const getRoomData = (rooms: IRoom[], roomId: string): any => {
    const roomData = rooms.find((x: IRoom) => x.id === roomId)
    return roomData
}