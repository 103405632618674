import { ILoadingState, IAction } from "src/types";
import { SHOW_LOADER, HIDE_LOADER } from "../actions/actionTypes";

const initalState : ILoadingState = {
    visible: false
}
const organizationReducer = (state : ILoadingState = initalState, action: IAction) => {

    const {type} = action;

    switch(type) {
        case SHOW_LOADER:
            return {
                ...state,
                 visible: true
            }
        case HIDE_LOADER:
            return {
                ...state,
                visible: false
            }
        default:
            return state
    }
}

export default organizationReducer