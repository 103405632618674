import http from "./http-common";

export const getFloor = async (formData: any) => {
    return http.get<any>(`/floor`, formData);
}

export const getFloorById = async (formData: any) => {
    return http.post<any>(`/floor/floorById`, formData);
}

export const getFloorByOrganization = async (formData: any) => {
    return http.post<any>(`/floor/floorByOrganization`, formData);
}

export const createFloorService = async (formData: any) => {
    return http.post<any>(`/floor/create`, formData);
}

export const updateFloor = async (formData: any) => {
    return http.post<any>(`/floor/update`, formData);
}

export const deleteFloor = async (formData: any) => {
    return http.post<any>(`/floor/delete`, formData);
}

export const getFloorDashboardDetails = async (formData: any) => {
    return http.post<any>(`/floor/deviceAlertByFloor`, formData)
}

export const getDeviceDataGroupbyFloorId = async (formData: any) => {
    return http.post<any>(`/floor/deviceDataByGroupbyFloorId`, formData)
}