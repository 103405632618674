import { IApplicationState, IAction } from "src/types";
import { SET_APPLICATIONDATA, RESET_APPLICATION, SET_ALLAPPLICATIONS, SET_ACCESSEDAPPLICATIONS } from "../actions/actionTypes";

const initalState : IApplicationState = {
    id: '',
    name : '',
    accessedApplications : [],
    allApplications: [],
    fetchStatus: false
}
const applicationReducer = (state : IApplicationState = initalState, action: IAction) => {

    const {type, payload} = action;

    switch(type) {
        case SET_APPLICATIONDATA:
            return {
                ...state,
                 id: payload.id,
                 name: payload.name
            }
        case RESET_APPLICATION:
            return {
                ...state,
                initalState
            }
        case SET_ALLAPPLICATIONS:
            return {
                ...state,
                allApplications: payload
            }
        case SET_ACCESSEDAPPLICATIONS: 
            return {
                ...state,
                accessedApplications: payload
            }
        default:
            return state
    }
}

export default applicationReducer