import * as React from 'react';
import { AppBar, Avatar, Button, Toolbar, Typography } from "@mui/material";
import { FC, ReactElement, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "src/store";
import { SET_APPLICATIONDATA } from "src/store/actions/actionTypes";
import { stringAvatar } from "../../../constants/shared"
import SearchField from '../SearchField';
import { CompareArrows } from '@mui/icons-material';
export const myContext = React.createContext('');

interface TopNavBarProps {
    appName: string,
}
interface SearchFieldProps {
    getData: (x: string) => void;
}
type Props = TopNavBarProps & SearchFieldProps;
const TopNavBar: FC<Props> = (props: Props): ReactElement => {

    const { appName, getData } = props;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    function refreshPage() {
        navigate('/login/..');
        window.history.back();
        window.location.reload();
      }

    const user = useSelector((state: RootState) => state.user);
    const selectedApplication = useSelector((state: RootState) => state.application)
    const onClickSwitchApps = (e: any) => {
        e.preventDefault();
        dispatch({ type: SET_APPLICATIONDATA, payload: { id: '', name: '' } })
        navigate('/switchApps')
    }


    const [search, setSearch] = useState('');
    function getValue(name: any) {
        setSearch(name);

    }

    useEffect(() => {
        getData(search.toLocaleLowerCase())
    }, [search])
    
    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'linear-gradient(45deg, #660066, #660066)' }}>
            <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h6" noWrap component="div" color='white'>
                    {appName}
                    {
                        selectedApplication.name !== "" ? (
                            " - " + selectedApplication.name
                        ) : (
                            <></>
                        )
                    }
                </Typography>

                {
                    (user.name && user.name !== "") ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {selectedApplication.name === "" ? (
                                <myContext.Provider value={"search"}>
                                    <SearchField getDetail={getValue} />
                                </myContext.Provider>) : (<></>)
                            }

                            {
                                selectedApplication.name !== "" ? (

                                    <Button onClick={onClickSwitchApps} variant="text" sx={{
                                        mr: 3, color: 'white',
                                        // boxShadow: "rgb(152 145 145) 1px 5px 15px",
                                        // border:'1px solid #fff',
                                        borderRadius: '8px', ":hover": { border: '1px solid #fff', boxShadow: "rgb(152 145 145) 1px 5px 15px" }
                                    }}><CompareArrows sx={{ width: '45px' }} />  Switch Apps</Button>
                                ) : (
                                    <></>
                                )
                            }
                            <Avatar {...stringAvatar(user.name)} style={{ marginRight: '10px' }} />
                            <p style={{ whiteSpace: 'nowrap', color: 'white' }}>{user.name}</p>
                            <span style={{width:'5px', color: 'white'}}>{' '}</span>
                            <span style={{width:'5px', color: 'white'}}>{' | '}</span>
                            <Button onClick={refreshPage} style={{ whiteSpace: 'nowrap', color: 'white' }}> Log Out</Button>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </Toolbar>
        </AppBar>
    )
}



export default TopNavBar
















// import { AppBar, Avatar, Button, FormControl, InputLabel, MenuItem, Select, Toolbar, Typography } from "@mui/material";
// import { FC, ReactElement } from "react";
// import {CompareArrows } from '@mui/icons-material';
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router";
// import { RootState } from "src/store";
// import { SET_APPLICATIONDATA } from "src/store/actions/actionTypes";
// import { stringAvatar } from "../../constants/shared"

// interface TopNavBarProps {
//     appName: string;
// }

// const TopNavBar: FC<TopNavBarProps> = (props: TopNavBarProps): ReactElement => {

//     const { appName } = props;

//     const navigate = useNavigate()
//     const dispatch = useDispatch()

//     const user = useSelector((state: RootState) => state.user);
//     const selectedApplication = useSelector((state: RootState) => state.application)

//     const onClickSwitchApps = (e: any) => {
//         e.preventDefault();
//         dispatch({ type: SET_APPLICATIONDATA, payload: {id: '', name: ''} })
//         navigate('/switchApps')
//     }

//     return (
//         <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'linear-gradient(45deg, #660066, #660066)' }}>
//             <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//                 <Typography variant="h6" noWrap component="div">
//                     {appName}
//                     {
//                         selectedApplication.name !== "" ? (
//                             " - " + selectedApplication.name
//                         ) : (
//                             <></>
//                         )
//                     }
//                 </Typography>
//                 {
//                     (user.name && user.name !== "") ? (
//                         <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                             {
//                                 selectedApplication.name !== "" ? (
//                                     <Button onClick={onClickSwitchApps} variant="text" sx={{mr: 3, color: 'white',
//                                     boxShadow: "rgb(152 145 145) 1px 5px 15px",
//                                     border:'1px solid #fff',
//                                     borderRadius:'8px'}}><CompareArrows sx={{width:'45px'}}/>  Switch Apps</Button>
//                                 ) : (
//                                     <></>
//                                 )
//                             }
//                             <Avatar {...stringAvatar(user.name)} style={{ marginRight: '10px' }} />
//                             <p style={{ whiteSpace: 'nowrap' }}>{user.name}</p>
//                         </div>
//                     ) : (
//                         <></>
//                     )
//                 }
//             </Toolbar>
//         </AppBar>
//     )
// }



// export default TopNavBar