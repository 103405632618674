import React, { ReactElement, FC } from "react";
import { useState, useEffect } from "react";
import { Card, CardContent, CardActionArea, Paper, Typography } from '@mui/material'
import ClockImage from './assets/ClockImage.gif'



const Clock: FC = (): ReactElement => {
    const [time, setTime] = useState(new Date())

    const refreshClock = () => {
        setTime(new Date());
    }

    setTimeout(() => { refreshClock() }, 1000)
    // useEffect(()=>{
    // const timerId=setInterval(refreshClock,1000)
    // return function cleanup(){
    //     clearInterval(timerId)
    // }
    // },[date])

    // const pics=()=>{

    // }
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    
    return (
        <div style={{
            height:'250px',maxWidth:'40vw',width:'300px',
            backgroundImage:`url(${ClockImage})`,backgroundRepeat:'no-repeat',backgroundSize:'cover', 
            borderRadius:'11px'
        }}>

            <Typography sx={{ fontWeight: 'bold',fontSize: '28px', 
            fontFamily:'cursive',color:'#fff',float:'inline-end',paddingTop:'25%'}}>
                {time.toLocaleTimeString()}
            </Typography>
            <Typography style={{ fontWeight: 'bold',fontSize: '14px', 
            fontFamily:'cursive',color:'#fff',float:'inline-end' }}>
                {time.getDate()}{'/'}{time.getMonth()+1}{'/'}{time.getFullYear()}{'  '}{weekday[time.getDay()]}
            </Typography>


        </div>
    )



}

export default Clock;