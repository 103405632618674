import { IAction, IAlertDeviceState } from "src/types";
import { CREATE_ALERT, SET_ALERTBYDEVICE } from "../actions/actionTypes";

const initalState: IAlertDeviceState = []

const alertDeviceReducer = (state: IAlertDeviceState = initalState, action: IAction) => {

    const { type, payload } = action;

    switch (type) {
        case CREATE_ALERT:
            return [
                ...state,
                payload
            ]
        case SET_ALERTBYDEVICE:
            return [
                
                payload.value
            ]
        default:
            return state
    }
}

export default alertDeviceReducer