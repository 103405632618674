import React from "react";
import "src/style/flexboxgrid.min.css"
import "./index.css"

// import Home from "src/components/landPage/Sections/customHome";
// import Vision from "src/components/landPage/Sections/customVision";
// import Projects from "src/components/landPage/Sections/customProjects";
// import Testimonial from "src/components/landPage/Sections/customTestimonial";
// import Footer from "src/components/landPage/Sections/customFooter/Footer";
// import TopNavbar from "src/components/landPage/customNav/customTopBar";
// import About from "src/components/landPage/Sections/customAbout";
// import ContactB from "src/components/landPage/Sections/customContact2";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import LandingPage from "./landingpage";
export default function Landing() {
  
  const user = useSelector((state: RootState) => state.user)

  if (user.token !== "") {
    <Navigate to="/home" />
  }
  
  return (

    <LandingPage/>
  );
}

    {/*<Fragment>
       <TopNavbar />
      {/* <TopnavigationBar/>
      <Home />
      <Vision />
      <Projects />
      <Testimonial />
      <About />
      <ContactB />
      <Footer />  
      
    </Fragment>*/}
