import { Typography, Button,Box } from '@mui/material';
import { FC, Fragment, ReactElement, useMemo, useState,useRef,useCallback,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store';
import { IBuilding, IFloor, IAlertDevice, IDevice } from 'src/types';
// import dateComparator  from 'src/utils/commonMethods';
import { ColDef, GroupCellRendererParams, ValueGetterParams } from 'ag-grid-community';
import GridView from 'src/components/homePage/GridView';
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
import moment from 'moment';
// import { Box } from '@mui/system';
import '../../../screens/SwitchApps.css';
import { SET_SELECTEDDEVICEDATA } from 'src/store/actions/actionTypes';
import { getAlertByDevice } from 'src/services/alertDeviceService';
import { getAllDeviceDataByOrganisation } from 'src/services/deviceService';

import * as React from 'react';


import { useParams } from 'react-router-dom';


// interface DeviceHomeProps {
// }

const CheckDevices: FC = (props): ReactElement => {

    const [dates, setDates] = useState<{[key: string]: string}>({})
    const [datas, setDatas] = React.useState<IDevice[]>([]);
    const devices = useSelector((state: RootState) => state.devices.devices)
    // const activeDevices=devices.filter((x:any)=>x.deviceActiveStatus==='Active'&& x.deviceStatus==='Enable')
    const floors = useSelector((state: RootState) => state.floors.floors)
    const organization=useSelector((state:RootState)=>state.organization)
    const application = useSelector((state: RootState) => state.application)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    const alerts = useSelector((state: RootState) => state.alerts)
    const [count,setCount]=useState(5)
    const curr_date=new Date()

    const [data1,setData1]=useState({
        response:[{
        _id:'',
        total_hours: 0,
        total_watts: 0,
        total_units: 0,
        total_cost_Saved: 0
    },{
        _id:'',
        total_hours: 0,
        total_watts: 0,
        total_units: 0,
        total_cost_Saved: 0
    }],
    deviceStatus:[{
        _id:'',
        createdOn:'',
        status:"true",
        name:'',
        floorId:'',
        roomId:'',
        buildingId:'',
        deviceStatus: "Enable",
        deviceActiveStatus: "Active"
    },
    {
        _id:'',
        createdOn:'',
        status:"true",
        name:'',
        floorId:'',
        roomId:'',
        buildingId:'',
        deviceStatus: "Enable",
        deviceActiveStatus: "Active"
    }

    ]})

    // console.log(data1,'data1')
    const allDeviceData=useCallback(async ()=>{
        const response=await getAllDeviceDataByOrganisation({organizationId:organization.id,applicationId:application.id})
        if(response.status===200){
            setData1(response.data)
        }
    },[])


    useEffect(()=>{
        allDeviceData();
    },[])//datas,devices

    const checkDevicesData=data1.deviceStatus.filter((x:any)=>x.deviceStatus==='Enable'&& x.deviceActiveStatus==='Active')
    // console.log(checkDevicesData,'checkDevicesData')
//     const RefreshHomes = async (data:IDevice) => {
//         const response = await getAlertByDevice({ deviceId:data.id })   
//         if (response.status === 200 && response.data.length!==0) {
//             // const num=(response.data.length-1).toString()
//             // let id=data.id.toString()      
//             setDates((alertPrev: {[key: string]: string})=>({...alertPrev,[data.id]:response.data[0].createdOn
//             }))
//     }
    
// }
    

//         useEffect(()=>{
//         {devices.map((data: IDevice, index: number) => {
//         RefreshHomes(data);})
//         }        
//         },[])
        
 
    //     const RefreshHome = async (data:IDevice) => {
    //         const response = await getAlertByDevice({ deviceId:data.id })
    //         const old_Date=moment(dates[data.id]).format('MM/DD/YYYY')
    //         const date1=new Date(old_Date)
    //         const date2=new Date(curr_date)       
    //         const diff_IN_TIME=date2.getTime()-date1.getTime()
    //         const one_day = 1000 * 60 * 60 * 24
    //         const diff_in_Days=diff_IN_TIME/(one_day)    
    //         if (response.status === 200) {
    //             if(data.deviceActiveStatus==='Active'&& data.deviceStatus==='Enable'){
    //             if(diff_in_Days >=count){
                    
    //                 setDatas((alertPrev)=>[...alertPrev,data])
    //             } }      
                
    //     }
    
    // }
            // const countChanged=()=>{
            //     setDatas(()=>[])
            //     {devices.map((data: IDevice, index: number) => {
            //         RefreshHome(data);})
            //         }

            // }
            // React.useEffect(()=>{
            //     {devices.map((data: IDevice, index: number) => {
            //         RefreshHome(data);})
            //         }
            // },[])
    const getBuildingData = (buildingId: string): string | undefined | null => {
        const buildingData = buildings.find((x: IBuilding) => x.id === buildingId)
        return buildingData ? buildingData.name : ''
    }

    const getFloorData = (floorId: string): string | undefined | null => {
        const floorData = floors.find((x: IFloor) => x.id === floorId)
        return floorData ? floorData.name : ''
    }

    const getRoomData = (roomId: string): string | undefined | null => {
        const roomData = rooms.find((x: IFloor) => x.id === roomId)
        return roomData ? roomData.number : ''
    }

    // const getDeviceData = (deviceId: string): string | undefined | null => {
    //     const deviceData = devices.find((x: IDevice) => x.id === deviceId)
    //     return deviceData ? deviceData.name : ''
    // }

    
        


    const dispatch = useDispatch()
    const navigate = useNavigate()



    const onhandleClick = (data: IDevice) => {
        dispatch({ type: SET_SELECTEDDEVICEDATA, payload: data })
        navigate(`/home/devices/${data._id}`)
    }















    const columnDefs: ColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: 'Name',
            maxWidth: 450,
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            cellRenderer: (params: GroupCellRendererParams) => {
                      return <Button onClick={() => onhandleClick(params.data)}> {`Device - ${params.data.name}`}</Button>
            },
            sort:'desc'
        },
        {
            field: 'roomId',
            headerName: 'Room',
            maxWidth: 100,
            flex: 1,
            filter: 'agTextColumnFilter', 
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            valueGetter: (params: ValueGetterParams) => {
                const val = getRoomData(params.data.roomId)
                return val !== '' ? val : 'NA'
            }
        },
        {
            field: 'floorId',
            headerName: 'Floor',
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            valueGetter: (params: ValueGetterParams) => {
                const val = getFloorData(params.data.floorId)
                return val !== '' ? val : 'NA'
            }
        },
        {
            field: 'buildingId',
            headerName: 'Building',
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            valueGetter: (params: ValueGetterParams) => {
                const val = getBuildingData(params.data.buildingId)
                return val !== '' ? val : 'NA'
            }

        },
        {
            field: 'device status',
            headerName: 'Device Status',
            maxWidth: 350,
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],                
              },
            valueGetter: (params: ValueGetterParams) => {               
                switch(params.data.deviceStatus){
                    case 'Enable':
                      return 'Enabled'
                    case 'Disabled':
                      return 'Disabled'
                    default:
                      return 'Disabled'
                  }
             }
        },
        {
            field: 'active status',
            headerName: 'Active Status',
            maxWidth: 350,
            flex: 1,
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {               
                switch(params.data.deviceActiveStatus){
                    case 'Active':
                      return 'Active'
                    case 'Inactive':
                      return 'In Active'
                    default:
                      return 'In Active'
                  }
             }
        },
        {
            field: 'LastAlertDate',
            headerName: 'Last Alert Date',
            maxWidth: 350,
            flex: 1,
            filter: 'agTextColumnFilter',
            valueGetter: (params: ValueGetterParams) => {               
                return moment(params.data.createdOn).format('DD/MM/YYYY')
             }
        },
        {
            field: 'DateDiff',
            headerName: 'No of Days',
            maxWidth: 350,
            flex: 1,
            filter: 'agNumberColumnFilter',
            valueGetter: (params: ValueGetterParams) => {               
                const old_Date=moment(params.data.createdOn).format('MM/DD/YYYY')
                const date1=new Date(old_Date)
                const date2=new Date(curr_date)
                const diff_IN_TIME=date2.getTime()-date1.getTime()
                const one_day = 1000 * 60 * 60 * 24
                const diff_in_Days=diff_IN_TIME/(one_day)
                return diff_in_Days.toFixed(1)
             }
        },
    ], [devices, rooms, floors, buildings,dates]);

    const refreshDataGrid=()=>{
        navigate('../home/activedevices')
        setTimeout(()=>{navigate('')},500)
      }

      
      
      const noOfDays=(e:any)=>{
        setCount(e.target.value)
      }

      //onClick={countChanged}
    return (
        <Fragment>
            
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h5'><KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate('../activedevices')} style={{
                width: "25px", height: "25px", marginRight: '25px',
                borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
              }} />Check Devices </Typography>
            </div>
            <hr />
            {/* <div>
                <Typography>Days:<input onChange={noOfDays}></input>
                <Button variant='contained' sx={{ ml:2,backgroundColor: '#660066', ":hover": { backgroundColor: '#660066' } }} >Click</Button> 
                </Typography>
                </div> */}
            <div>
            <Button variant='contained' sx={{ backgroundColor: '#660066',float:'right', ":hover": { backgroundColor: '#660066' } }} onClick={refreshDataGrid}>Refresh</Button>
            </div>
            <br/>
            <br/>
            <Box className="ag-theme-alpine" sx={{ height: 400, width: '100%', '&header.': { backgroundColor: '#660066' } }}>
                <GridView columnDefs={columnDefs} rowData={checkDevicesData}/>
            </Box>
            
        </Fragment>
    )
}

export default CheckDevices;
