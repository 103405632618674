import http from "./http-common";

export const login = async (formData: any) => {
    return http.post<any>(`/user/login`, formData);
}

export const getUserById = async (formData: any) => {
    return http.get<any>(`/user/${formData.id}`)
}

export const getUserByEmail = async (formData: any) => {
    return http.post<any>(`/user/validateUser`, formData)
}

export const changePassword = async (formData: any) => {
    return http.post<any>('/user/changepassword', formData)
}
export const changeRegMailId = async (formData: any) => {
    return http.post<any>('/user/changeRegMailId', formData)
}
export const updatePhoneNumber = async (formData: any) => {
    return http.post<any>('/user/updatePhoneNumber', formData)
}