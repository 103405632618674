import TopNavBar from "./TopNavBar";
import SuperAdminTopNavBar from "./SuperAdminTopNavBar";
import { FC,ReactElement } from "react";
import { ROLE } from "src/constants/shared";
import { Typography } from "@mui/material";
export interface roleProps{
    role:string
}
interface TopNavBarProps {
    appName: string,
}
interface SearchFieldProps {
    getData: (x: string) => void;
}
type Props = roleProps & TopNavBarProps & SearchFieldProps;
const TopNavBarMain:FC<Props>=(props:Props):ReactElement=>{
    const {role,appName,getData}=props;
        switch(role){     
            case ROLE.Admin: return <TopNavBar appName= {appName} getData={getData} />
            case ROLE.User: return <TopNavBar appName= {appName} getData={getData}/>
            case ROLE.SuperAdmin:return <SuperAdminTopNavBar role={role} appName={appName} getData={getData} />
            default : return <Typography> Top NavBar Not Available !</Typography>
          }
    
}
export default TopNavBarMain;