import { Dispatch } from "react"
import { getDeviceDataGroupbyBuildingId } from "src/services/buildingService"
import { CREATE_BUILDING, UPDATE_BUILDING, DELETE_BUILDING, SET_BUILDINGDATA, SET_BUILDING_HOME_DATA, BUILDING_DASHBOARD_DETAILS, GROUP_DATA_BY_BUILDINGID } from "./actionTypes"

export const createBUILDING = (dispath: Dispatch<any>, buildingName: string) => {
    dispath({type: CREATE_BUILDING, buildingName})
}

export const updateBUILDING = (dispath: Dispatch<any>, buildingName: string) => {
    dispath({type: UPDATE_BUILDING, buildingName})
}

export const deleteBUILDING = (dispath: Dispatch<any>, buildingId: string) => {
    dispath({type: DELETE_BUILDING, buildingId})
}

export const refreshBuildingData = (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_BUILDINGDATA, payload: { value: [], fetchStatus: false } })
}


export const getBuildingHomeDatabyOrg=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:SET_BUILDING_HOME_DATA,payload:{value:data}})
}
export const buildingDashBoardDetails=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:BUILDING_DASHBOARD_DETAILS,payload:{value:data}})
}

export const deviceDataGroupbyBuildingId=(dispatch:Dispatch<any>,data:any)=>{
    dispatch({type:GROUP_DATA_BY_BUILDINGID,payload:{value:data}})
}