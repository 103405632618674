import { Dispatch } from "react"
import { CREATE_ALERT, SET_ALERTBYDEVICE } from "./actionTypes"

export const createAlert = (dispatch: Dispatch<any>, status: string) => {
    dispatch({ type : CREATE_ALERT, status})
}

export const getAlertByDeviceId = (dispatch: Dispatch<any>, data: string) => {
    dispatch({ type: SET_ALERTBYDEVICE, payload:{value:data}})
}
