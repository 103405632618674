import { Snackbar, Alert as AlertDialog } from '@mui/material';
import { FC, Fragment, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { IAlert } from 'src/types';

const Alert: FC = (props: any): ReactElement => {

    const alerts = useSelector((state: RootState) => state.alerts)

    return (
        <Fragment>
            {
                alerts.map((value: IAlert) => {
                    return <Snackbar autoHideDuration={6000} key={value.id}>
                        <AlertDialog severity="success" sx={{ width: '100%' }}>
                            {value.alertMessage}
                        </AlertDialog>
                    </Snackbar>
                })
            }
        </Fragment>
    )
}

export default Alert