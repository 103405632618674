import { Dispatch } from "react"
import { CREATE_ROOM, UPDATE_ROOM, DELETE_ROOM, SET_ROOMDATA, ROOM_DASHBOARD_DETAILS } from "./actionTypes"

export const createROOM = (dispath: Dispatch<any>, roomNumber: string) => {
    dispath({type: CREATE_ROOM, roomNumber})
}

export const updateROOM = (dispath: Dispatch<any>, roomNumber: string) => {
    dispath({type: UPDATE_ROOM, roomNumber})
}

export const deleteROOM = (dispath: Dispatch<any>, roomId : string) => {
    dispath({type: DELETE_ROOM, roomId })
}

export const refreshRoomData = (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_ROOMDATA, payload: { value: [], fetchStatus: false } })
}


export const refreshRoomDashBoardDetails=(dispatch:Dispatch<any>,data:any)=>{

    dispatch({type:ROOM_DASHBOARD_DETAILS,payload:{value:data}})
}