import http from "./http-common";

export const getOrganization = async (formData: any) => {
    return http.get<any>(`/organization`, formData);
}

export const getOrganizationById = async (formData: any) => {
    return http.get<any>(`/organization/${formData.id}`);
}

export const createOrganization = async (formData: any) => {
    return http.post<any>(`/organization/create`, formData);
}

export const updateOrganization = async (formData: any) => {
    return http.post<any>(`/organization/update`, formData);
}

export const deleteOrganization = async (formData: any) => {
    return http.post<any>(`/organization/delete`, formData);
}

export const assignAdminOrganization = async (formData: any) => {
    return http.post<any>(`/organization/assignAdmin`, formData);
}

export const updateOrganizationApplications = async (formData: any) => {
    return http.post<any>(`/organization/updateApplications`, formData);
}

export const createOrganizationUser = async (formData: any) => {
    return http.post<any>(`/organization/createOrgUser`, formData)
}

export const getOrganizationDashboardDetails = async (formData: any) => {
    return http.post<any>(`/organization/deviceAlertByOrganization`, formData)
}



export const getDeviceDataByOrgaizationId = async (formData: any) => {
    return http.post<any>(`/organization/deviceDataByOrgaizationId`, formData)
}