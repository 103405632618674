import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Box, Button, Grid, Typography,Dialog,DialogContent } from '@mui/material';
import { FC, ReactElement,useState,useEffect,useCallback } from "react";
import {  useDispatch, useSelector, } from 'react-redux';
import { RootState } from "src/store";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faCircle, faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../Instruments/instruments.css'
import { refreshInstrumentsData } from 'src/store/actions/instrumentsAction';
import {updateInstrumentsList,createInstrumentsList} from '../../../services/instrumentService';
import {getInstrumentsByOrganization} from 'src/services/instrumentService';


interface IAddInstruments{
    visibilityOfInstruments:boolean,
    setVisibilityOfInstruments:any,
    selectedInst:(x:any)=>void,
	defaultSelected?:any,
}
const AddInstruments: FC<IAddInstruments> = (props: IAddInstruments): ReactElement => {	
    const {visibilityOfInstruments,setVisibilityOfInstruments,selectedInst,defaultSelected}=props
	
	const [count,setCount]=useState(0)

	const [inputValue, setInputValue] = useState('');
	const [totalItemCount, setTotalItemCount] = useState(6);
	const instruments=useSelector((state:RootState)=>state.instruments.instruments)
	const[instrumentList,setInstrumentList]=useState([{ instrumentName: '', quantity: 1,watts:1, isSelected: false }])
	const organization=useSelector((state:RootState)=>state.organization)
	const application=useSelector((state:RootState)=>state.application)
	const [items, setItems] = useState([{_id:'', instrumentName: '', quantity: 1,watts:1, isSelected: false,isItemSelected:false }]);
	const [visibility,setVisbility]=useState(false)
	const dispatch=useDispatch()
	const organizationId = organization.id
	const applicationId = application.id
	const navigate =useNavigate()
	const initialList=useCallback(async ()=>{
		const response1= await getInstrumentsByOrganization({organizationId,applicationId})
		setInstrumentList(response1.data)
	},[])

	useEffect(()=>{
			initialList();
	},[])


	useEffect(()=>{
	const data = instruments
	const dataset=data.map((obj:any)=>{
			return {...obj,isItemSelected:false}
		})

	setItems(dataset)
	setCount(0)
	},[])




const defaultSelectedInstList=useCallback(()=>{


	if(defaultSelected!==undefined && count===0){
		const selecedInstName=defaultSelected.map((x:any)=> (x.instrumentName));
		items.map((obj:any)=>{
			if(selecedInstName.includes(obj.instrumentName)){
				obj.isItemSelected=true
				obj.isSelected=false
				obj.quantity=defaultSelected.filter((x:any)=>x.instrumentName===obj.instrumentName).map((x:any)=>x.quantity)[0]
				obj.watts=defaultSelected.filter((x:any)=>x.instrumentName===obj.instrumentName).map((x:any)=>x.watts)[0]
				setCount(1)
			}
			
		})			
		
	}

	
},[items])


useEffect(()=>{
	defaultSelectedInstList();
	
	},[defaultSelected,items])
	
	
	const refreshHome= useCallback(async ()=>{
		const response1= await getInstrumentsByOrganization({organizationId,applicationId})
		// dispatch({type:SET_INSTRUMENTSDATA,payload:{value:response1.data,fetchStatus:true}})
		refreshInstrumentsData(dispatch,response1.data)
		navigate('/home')
		setTimeout(()=>{navigate('/home/Instruments')},100)
	},[])

	const HandleAddButtonClick = async () => {
		let counts=0
		for(let i=0;i<items.length;i++){			
			if(items[i].instrumentName.toUpperCase()===inputValue.toUpperCase()){
				counts++;
			}			
		}
		if (counts===0){			
			
			const response= await createInstrumentsList({instrumentName:inputValue,applicationId:applicationId,organizationId:organizationId})			
			if (response.status===200 && response.data.length!==0){
				refreshHome()
	}
		}
		else if(counts>0 || counts<0){			

			setVisbility(true)
			setTimeout(()=>{setVisbility(false)},2000)
		}
		setInputValue('');
		calculateTotal();
	};

	const handleQuantityIncrease = (index:any) => {
		const newItems = [...items];
    if (newItems[index].quantity>=0){
		newItems[index].quantity++;
    setItems(newItems);
    }		
		calculateTotal();
	};

	const handleQuantityDecrease = (index:any) => {
		const newItems = [...items];

		
    if (newItems[index].quantity>0){
      newItems[index].quantity--;
      setItems(newItems);
    }
		
		calculateTotal();
	};

	const toggleComplete = (index:any) => {
		const newItems = [...items];

		newItems[index].isSelected = !newItems[index].isSelected;

		setItems(newItems);
	};

	const calculateTotal = () => {
		const totalItemCount = items.reduce((total:any, item:any) => {
			return total + item.quantity;
		}, 0);

		setTotalItemCount(totalItemCount);
	};

  const InputValue=(index:any)=>(event:any)=>{
    const newItems = [...items];
		const value=event.target.value
		newItems[index].quantity=value
      	setItems(newItems);		
		calculateTotal();
  }


  const handleQuantityIncrease1 = (index:any) => {
	const newItems = [...items];
if (newItems[index].watts>=0){
	newItems[index].watts++;
	setItems(newItems);
}
	
	calculateTotal();
};

const handleQuantityDecrease1 = (index:any) => {
	const newItems = [...items];

	
if (newItems[index].watts>0){
  newItems[index].watts--;
  setItems(newItems);
}
	
	calculateTotal();
};

const InputValue1=(index:any)=>(event:any)=>{
    const newItems = [...items];
		const value=event.target.value
		newItems[index].watts=value
      	setItems(newItems);		
		calculateTotal();
  }


  const [selected,setSelected]=useState<{[key:string]:boolean}>({})

  useEffect(()=>{
    items.map((item:any,index:any)=>
    setSelected((prevdata:{[key:string]:boolean})=>({...prevdata,[item.instrumentName]:false}))
    )
  },[])

  const selectedList=(item:any)=>{
	const dataset=items.map((data:any)=>{
		if (data.instrumentName === item.instrumentName){
			return {...data,isItemSelected:!data.isItemSelected}
		}
		else{
			return {...data}
		}
	})
	setItems(dataset)
}

    const [addedInstruments,setAddedInstruments]=useState([''])

	

    useEffect(()=>{
        const dataset=items.map((data:any)=>{
			
			if(data.isItemSelected===true){
				return {...data}
			}
			else{
				return 
			}
		})
		setAddedInstruments(dataset.filter(x=>x))
    },[items])
	

	return (
		<>

		<Dialog  sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "1000px",
            // Set your width here
        },
      },
    }} open={visibilityOfInstruments} className='app-background'>
		<DialogContent  >			
			<div className='main-container'>
			<Typography sx={{color:'#000',fontSize:22,fontWeight:'bold' }}>ADD Instruments</Typography>			
			{/* <Button variant='contained' sx={{ backgroundColor:'#fbc1bb',color:'#000',float:'right',borderRadius:5,ml:5, ":hover": { backgroundColor: '#fbc1bb',color:'#000' } }} onClick={deleteItems}>Delete</Button> */}
			<Button variant='contained' sx={{ backgroundColor:'#fbc1bb',color:'#000',float:'right',borderRadius:5,ml:5, ":hover": { backgroundColor: '#fbc1bb',color:'#000' } }} onClick={()=>{selectedInst(addedInstruments); setVisibilityOfInstruments(false)}}>SUBMIT</Button>
            <Button variant='contained' sx={{ backgroundColor:'#fbc1bb',color:'#000',float:'right',borderRadius:5, ":hover": { backgroundColor: '#fbc1bb',color:'#000' } }} onClick={()=>setVisibilityOfInstruments(false)}>Cancel</Button>
			{visibility ?(<Typography sx={{color:'yellow',fontSize:22,fontWeight:'bold',float:'right',borderRadius:5,mr:15}}>**Item Already Added!!**</Typography>):<></>}
			<br/>
			<br/>
                <div>
                <Box sx={{minWidth:25, minHeight:150,backgroundColor:'#fff',borderRadius:5,display:'flex'}}>
                    <Grid justifyContent='center' alignItems='space-between' direction={'column'}>
                    <Grid container  direction={'row'} >                       
                            {
                                items.filter((x:any)=>x.instrumentName!=='').map((item:any,index:any)=>{
                                        return  <Grid item  sx={{margin:'2px'}} key={index}><Button  variant='contained'
                                        sx={{ backgroundColor:items[index].isItemSelected ? '#ec645b' : '#fbc1bb',color:'#000',borderRadius:5, ":hover": { backgroundColor:items[index].isItemSelected ? '#ec645b' : '#fbc1bb',color:'#000' } }}
                                        onClick={()=>selectedList(item)}
                                        >
                                        {item.instrumentName}</Button></Grid>
                                })
                            }                       
                    </Grid>
                    </Grid>

                </Box>
                </div>
				<div className='item-list'>
					{items.map((item:any, index:any) => 
          			item.instrumentName!=='' && item.isItemSelected ? (
          (
						<div className='item-container' key={item.instrumentName}>
							<div className='item-name' onClick={() => toggleComplete(index)}>
								{item.isSelected ? (
									<>
										{/* <FontAwesomeIcon icon={faCheckCircle} /> */}
										<span className='completed' style={{fontWeight:'bold',fontSize:16}}>{item.instrumentName.toUpperCase()}</span>
									</>
								) : (
									<>
										{/* <FontAwesomeIcon  icon={faCircle} /> */}
										<span style={{fontWeight:'bold',fontSize:16}}>{item.instrumentName.toUpperCase()}</span>
									</>
								)}
							</div>
							<div className='quantity'>
								<button style={{height:'15px'}}>
									<FontAwesomeIcon style={{height:'15px'}} icon={faChevronLeft} onClick={() => handleQuantityDecrease(index)} />
								</button>
								<span style={{fontWeight:'bold',fontSize:14}}>Qty : <input type='text' value={item.quantity} onChange={InputValue(index)} name='qty' style={{maxWidth:'25px',border:'none',fontWeight:'bold',fontSize:14}}></input></span>
								<button style={{height:'15px'}}>
									<FontAwesomeIcon style={{height:'15px'}} icon={faChevronRight} onClick={() => handleQuantityIncrease(index)} />
								</button>
                				<span style={{marginLeft:'10px'}}>|</span>
								<button style={{height:'15px'}}>
									<FontAwesomeIcon style={{height:'15px'}} icon={faChevronLeft} onClick={() => handleQuantityDecrease1(index)} />
								</button>
								<span style={{fontWeight:'bold',fontSize:14}}>Watts : <input type='text' value={item.watts} onChange={InputValue1(index)} name='qty' style={{maxWidth:'28px',border:'none',fontWeight:'bold',fontSize:14}}></input></span>
								<button style={{height:'15px'}}>
									<FontAwesomeIcon style={{height:'15px'}} icon={faChevronRight} onClick={() => handleQuantityIncrease1(index)} />
								</button>
							</div>
						</div>
					)):<></>)}
				</div>
				{/* <div className='total'>Total: {totalItemCount}</div> */}
			</div>
		</DialogContent>
        </Dialog>
		</>
	);
};

export default AddInstruments