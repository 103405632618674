import { Box, Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import { FC, Fragment, ReactElement, useCallback, useEffect, useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { getRoomDashboardDetails } from "src/services/roomService";
import { RootState } from "src/store";
import { IBuilding, IFloor, IRoom} from 'src/types';
import { getBuildingData, getFloorData } from "src/utils/commonMethods";
import { useNavigate } from 'react-router-dom';
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
import '../../../screens/SwitchApps.css'
import NoofTimesDoorOpened from "src/components/homePage/NewChartWidget/NoofTimesDoorOpened";
import TotalUnitsSaved from "src/components/homePage/NewChartWidget/TotalUnitsSaved";
import TotalPowerSavedinHours from "src/components/homePage/NewChartWidget/TotalPowerSavedinHours";
import TotalCostSaved from "src/components/homePage/NewChartWidget/TotalCostSaved";
import { refreshRoomDashBoardDetails } from 'src/store/actions/roomActions';


// interface IRoomView {
// }
const RoomView: FC = (props): ReactElement => {
  
  const navigate = useNavigate()

  // const [data, setdata] = useState<any>({
  //   deviceCount: 0,
  //   roomCount: 0,
  //   chartResponseOpened: [],
  //   chartResponseClosed:[],
  //   cosumptionDetails: [{
  //     "_id": {
  //       "year": 0,
  //       "month": 0
  //     },
  //     "total_hours": 0,
  //     "total_count": 0
  //   }]
  // });
  
  const [dataFetchStatus, setDataFetchStatus] = useState(false)
  // console.log(data,'dataNEWchart')
  const application = useSelector((state: RootState) => state.application)
  const organization = useSelector((state: RootState) => state.organization)
  const room: IRoom = useSelector((state: RootState) => state.rooms.selectedRoom)
  const devices=useSelector((state:RootState)=>state.devices.devices)
  const device=devices.filter((x:any)=>x.roomId===room.id);
  const roomDashboardDetails=useSelector((state:RootState)=>state.rooms.roomDashboardDetails)
  console.log(roomDashboardDetails,'roomDashboardDetails')
  // const watts=device[0].instrumentList.length===0?0:Number(device[0].totalWattsOfInstruments)
  // const kiloWatts=watts/1000;
  // const costPerUnit=6;
  // const NewData=data
  // NewData.chartResponseClosed.map((data:any,index:any)=>{
  //   NewData.chartResponseClosed[index].total_units=Number((data.total_hours*kiloWatts).toFixed(0))
  //   NewData.chartResponseClosed[index].total_cost_Saved=Number((data.total_hours*kiloWatts*costPerUnit).toFixed(0))
  // })
  const buildings: IBuilding[] = useSelector((state: RootState) => state.buildings.buildings)
  const floors: IFloor[] = useSelector((state: RootState) => state.floors.floors)
  const dispatch=useDispatch()
  const refreshHome =  useCallback(async () => {
    const response = await getRoomDashboardDetails({ organizationId: organization.id, applicationId: application.id, roomId: room._id })
    if (response.status === 200) {
      refreshRoomDashBoardDetails(dispatch,response.data)
      // setdata(response.data)
      setDataFetchStatus(true)
    }
  }, [])

  useEffect(() => {
    if (!dataFetchStatus) {
      refreshHome()
    }
  }, [dataFetchStatus, refreshHome])

  return (
    <Fragment>
      {
        room ? (
          <>
            <Box>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h5'>  <KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate(-1)} style={{
                  width: "25px", height: "25px", marginRight: '25px',
                  borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
                }} /> Room - {room.number} </Typography>
              </div> <hr /> <br />
              <Grid justifyContent='center' alignItems='flex-start' direction={'column'}>
                <Grid container spacing={2} direction={'row'}>
                  <Grid item xs={4}>
                    <Card
                      sx={{
                         maxWidth: 500, borderRadius: '25px',
                        background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                        boxShadow: "rgb(152 145 145) 1px 5px 15px"
                      }}>
                      <CardContent>
                        <Typography variant="h5" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                          No of Devices in this room
                        </Typography>
                        <Typography variant="h4" component="div" sx={{ mb: 0, fontWeight: 'bold', float: 'right' }} color="#000">
                          {roomDashboardDetails.deviceCount}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{
                      maxWidth: 500, borderRadius: '25px',
                      background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                      boxShadow: "rgb(152 145 145) 1px 5px 15px"
                    }}>
                      <CardContent>
                        <Typography variant="h5" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                          Floor
                        </Typography>
                        <Typography variant="h4" component="div" sx={{ mb: 0, fontWeight: 'bold', float: 'right' }} color="#000">
                          {
                            getFloorData(floors, room.floorId).name
                          }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{
                      maxWidth: 500, borderRadius: '25px',
                      background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                      boxShadow: "rgb(152 145 145) 1px 5px 15px"
                    }}>
                      <CardContent>
                        <Typography variant="h5" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                          Building
                        </Typography>
                        <Typography variant="h4" component="div" sx={{ mb: 0, fontWeight: 'bold', float: 'right' }} color="#000">
                          {
                            getBuildingData(buildings, room.buildingId).name
                          }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction={'row'} mt={1}>
                  <Grid item xs={6}>
                    <Card sx={{
                      borderRadius: '25px',
                      background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                      boxShadow: "rgb(152 145 145) 1px 5px 15px"
                    }}>
                      <CardContent>
                        <Typography variant="h5" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                        Total Units Saved in Current Month
                        </Typography>
                        <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                          {
                            // data.cosumptionDetails.length > 0 ? Math.round((data.cosumptionDetails[0].total_hours + Number.EPSILON) * 100) / 100 : 0
                            roomDashboardDetails.chartResponseClosed.length>0 ? roomDashboardDetails.chartResponseClosed[roomDashboardDetails.chartResponseClosed.length-1].total_units :0
                          }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{
                      borderRadius: '25px',
                      background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                      boxShadow: "rgb(152 145 145) 1px 5px 15px"
                    }}>
                      <CardContent>
                        <Typography variant="h5" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                        Total Cost Saved in Current Month
                        </Typography>
                        <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                          {
                            // data.cosumptionDetails.length > 0 ? data.cosumptionDetails[0].total_count : 0
                            roomDashboardDetails.chartResponseClosed.length>0 ? roomDashboardDetails.chartResponseClosed[roomDashboardDetails.chartResponseClosed.length-1].total_cost_Saved :0
                          }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2} direction={'row'} mt={1}>
                  <Grid item xs={6}>
                    <Paper elevation={4} style={{ borderRadius: '25px' }}>
                      <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                        <CardContent>
                          <Typography color="text.secondary" sx={{ mb: 2 }}>
                            Total Number of times Door Opened
                          </Typography>
                          <NoofTimesDoorOpened data={roomDashboardDetails.chartResponseOpened} height={300} width={500} />
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Total Power Saved in Hours
                      </Typography>
                      <TotalPowerSavedinHours data={roomDashboardDetails.chartResponseClosed} height={300} width={500} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid> */}
            <Grid container columnSpacing={2} sx={{ mt: 5 }}>
              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                       Total Units Saved
                      </Typography>
                      <TotalUnitsSaved data={roomDashboardDetails.chartResponseClosed} height={300} width={500}/>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>


                  <Grid item xs={6}>
                    <Paper elevation={4} style={{ borderRadius: '25px' }}>
                      <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                        <CardContent>
                          <Typography color="text.secondary" sx={{ mb: 2 }}>
                          Total Cost Saved in Rs.
                          </Typography>
                          <TotalCostSaved data={roomDashboardDetails.chartResponseClosed} height={300} width={500}/>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                </Grid> 
              </Grid>
            </Box>
          </>
        ) : (
          <> </>
        )
      }
    </Fragment>
  )
}
export default RoomView