import React, { ReactElement, FC } from "react";
import { useState, useEffect } from "react";
import { Avatar, Grid, Typography, Button, Box, Card, CardContent, CardMedia, IconButton, useTheme, CardActionArea, CardActions, Paper, Divider, Chip, Stack } from '@mui/material'
import HistoryIcon from '@mui/icons-material/History';
import ActiveCamera from './assets/Activecamera.gif'
import InactiveCamera from './assets/InactiveCamera.jpg'
import Animal from './assets/dog.png'
import { Circle } from "@mui/icons-material";
import  {useSelector} from 'react-redux'
import { RootState } from "src/store";


const DashboardFooter: FC = (): ReactElement => {
    const organization=useSelector((state:RootState)=>state.organization)
    return (
        <>
            <Stack direction={'row'} spacing={2} sx={{ textAlign: 'center', justifyContent: 'center' }} >
                <Card sx={{
                    width: '50%', height: '21%',
                    borderRadius: '15%', border: '5px solid #54C571',
                    zIndex: 1000,
                }}>
                    <CardContent sx={{ borderRadius: '50%', float: 'left' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.5vw', color: '#000000', fontFamily: 'cursive' }}>
                            Active Cameras
                        </Typography>
                        <Typography sx={{ fontSize: '4vw', color: 'darkgreen', fontFamily: 'cursive' }}>
                        {organization.cameras.length}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        style={{ width: '8vw', height: '8vw', borderRadius: '50%', float: 'right', backgroundColor: '#fff' }}
                        component="img"
                        height="248vh"
                        title="Active Cameras"
                        image={ActiveCamera}
                        alt="green iguana" />
                </Card>
                <Card sx={{
                    width: '50%', height: '21%',
                    borderRadius: '15%', border: '5px solid #E41B17',
                    zIndex: 1000,
                }}>
                    <CardContent sx={{ borderRadius: '50%', float: 'left' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.5vw', color: '#000000', fontFamily: 'cursive' }}>
                            InActive Cameras
                        </Typography>
                        <Typography sx={{ fontSize: '4vw', color: 'darkred', fontFamily: 'cursive' }}>
                            0
                        </Typography>
                    </CardContent>
                    <CardMedia
                        style={{ width: '8vw', height: '8vw', borderRadius: '50%', float: 'right', backgroundColor: '#fff' }}
                        component="img"
                        height="248vh"
                        title="InActive Cameras"
                        image={InactiveCamera}
                        alt="green iguana" />
                </Card>
            </Stack>
        </>


    )



}

export default DashboardFooter;





