import React, { ReactElement, FC } from "react";
import { useState, useEffect } from "react";
import {Avatar, Grid, Typography, Button, Box, Card, CardContent, CardMedia, IconButton, useTheme, CardActionArea, CardActions, Paper, Divider, Chip, Stack } from '@mui/material'
import HistoryIcon from '@mui/icons-material/History';
import person from './assets/walk.png'
import Animal from './assets/dog.png'

    interface ImagesRecentProps{
        alerts:any[]
    }
    const ImagesRecent: FC<ImagesRecentProps> = (props:ImagesRecentProps): ReactElement => {
        const{alerts}=props
        const ImgSrc=(src:any)=>{
            const base64Flag = 'data:image/jpeg;base64,';
            const ImgString=base64Flag+src
            return ImgString
        }
    return (
        <>
            {alerts && alerts.filter((_,idx)=>idx<2).map((value:any,index:any)=>{
                return (
                    <Card key={index} sx={{mt:2}}>
                        <CardMedia   
                            component="img"
                            height="248vh"
                            image={ImgSrc(value.detectedImg.data)}
                            sx={{ padding: "0em 0em 0em 0em", objectFit:'fill' }}
                            alt="green iguana"/>
                    </Card>
                      
                )
                
            })}
        </>
        

    )



}

export default ImagesRecent;





