import { Grid, Typography, Paper, Card, CardContent, CardMedia,Stack } from '@mui/material';
import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import deviceOnline from '../../../../assets/svg/deviceOnline.gif'
import deviceOffline from '../../../../assets/svg/deviceOffline.png'
import Building from '../../../../assets/svg/Building.png'
import Floor from '../../../../assets/svg/Floor.png'
import Rooms from '../../../../assets/svg/Room.png'
import Devices from '../../../../assets/svg/Devices.png'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { getOrganizationDashboardDetails } from 'src/services/organizationService';
import { getDeviceDataByOrgaizationId } from 'src/services/organizationService';
import NoofTimesDoorOpened from "../DashboardCharts/NoofTimesDoorOpened";
import TotalPowerSavedinHours from "../DashboardCharts/TotalPowerSavedinHours";

const OrgUserDoorLockDashboard: FC = (): ReactElement => {
  // console.log('DoorLockDashboard Hi');
  const [data, setdata] = useState<any>({
    deviceCount: 0,
    roomCount: 0,
    floorCount: 0,
    buildingCount: 0,
    chartResponseOpened: [],
    chartResponseClosed: [],
    activeDeviceCount: 0,
    inactiveDeviceCount: 0
  });
  const [hoursStatus, sethoursfetchStatus] = useState(false)

  const application = useSelector((state: RootState) => state.application)
  const organization = useSelector((state: RootState) => state.organization)
  const refreshHome =  useCallback(async () => {
    const response = await getOrganizationDashboardDetails({ organizationId: organization.id, applicationId: application.id })
    if (response.status === 200) {
      setdata(response.data)
      sethoursfetchStatus(true)
    }
  }, [])

  useEffect(() => {
    if (!hoursStatus) {
      refreshHome()
    }
  }, [hoursStatus, refreshHome, data])

  
  const [data1,setData1]=useState<any>({
    group_chartResponses:[]
  })
  const group_chartResponses=async()=>{
    const response=await getDeviceDataByOrgaizationId({organizationId: organization.id})
    if(response.status===200){
      setData1(response.data)
      console.log(data1.group_chartResponses
        ,'group_chartResponses')
    }
  }
  useEffect(()=>{
    group_chartResponses();
  },[data])


  return (
    <>
      <Grid justifyContent='center' alignItems='flex-start' direction={'column'}>
        <Grid container spacing={2} direction={'row'}>
          <Grid xs={3} item>
            <Paper elevation={4} style={{ borderRadius: '25px' }}>
              <Link to='../devices'><Card sx={{
                 background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                boxShadow: "rgb(152 145 145) 1px 5px 15px", borderRadius: '25px'
              }}>
                <CardContent>
                  <Typography sx={{ mb: 1.5, fontWeight: 'bold' }} color="text.secondary">
                    Devices
                  </Typography>
                  <CardMedia image={Devices} sx={{
                    width: '95px', height: '95px', float: 'right',
                    marginBottom: '-50px', marginTop: '-50px'

                  }} />
                  <Typography variant="h3" component="div" sx={{
                    float: 'left', mb: 1.5, color: "text.secondary",
                  }}>
                    {data.deviceCount}
                  </Typography>
                </CardContent>
              </Card></Link>
            </Paper>
          </Grid>
          <Grid xs={3} item>
            <Paper elevation={4} style={{ borderRadius: '25px' }}>
              <Link to='../rooms'><Card sx={{
                background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                boxShadow: "rgb(152 145 145) 1px 5px 15px", borderRadius: '25px'
              }}>
                <CardContent>
                  <Typography sx={{ mb: 1.5, fontWeight: 'bold' }} color="text.secondary">
                    Rooms
                  </Typography>
                  <CardMedia image={Rooms} sx={{
                    width: '60px', height: '60px', float: 'right',
                    marginBottom: '-50px', marginTop: '-38px'
                  }} />
                  <Typography variant="h3" component="div" sx={{ float: 'left', mb: 1.5, color: "text.secondary", }}>
                    {data.roomCount}
                  </Typography>
                </CardContent>
              </Card></Link>
            </Paper>
          </Grid>
          <Grid xs={3} item>
            <Paper elevation={4} style={{ borderRadius: '25px' }}>
              <Link to='../floors'><Card sx={{
              background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                boxShadow: "rgb(152 145 145) 1px 5px 15px", borderRadius: '25px'
              }}>
                <CardContent>
                  <Typography sx={{ mb: 1.5, fontWeight: 'bold' }} color="text.secondary">
                    Floors
                  </Typography>
                  <CardMedia image={Floor} sx={{
                    width: '75px', height: '75px', float: 'right',
                    marginBottom: '-50px', marginTop: '-46px'
                  }} />
                  <Typography variant="h3" component="div" sx={{ float: 'left', mb: 1.5, color: "text.secondary", }}>
                    {data.floorCount}
                  </Typography>
                </CardContent>
              </Card></Link>
            </Paper>
          </Grid>
          <Grid xs={3} item>
            <Paper elevation={4} style={{ borderRadius: '25px' }}>
              <Link to='../buildings'><Card sx={{
              background: "linear-gradient(25deg,#CC99CC,#fff)",
                boxShadow: "rgb(152 145 145) 1px 5px 15px", borderRadius: '25px'
              }}>
                <CardContent>
                  <Typography sx={{ mb: 1.5, fontWeight: 'bold' }} color="text.secondary">
                    Buildings
                  </Typography>
                  <CardMedia image={Building} sx={{
                    width: '75px', height: '75px', float: 'right',
                    marginBottom: '-50px', marginTop: '-50px'
                  }} />
                  <Typography variant="h3" component="div" sx={{ float: 'left', mb: 1.5, color: "text.secondary", }}>
                    {data.buildingCount}
                  </Typography>
                </CardContent>
              </Card></Link>
            </Paper>
          </Grid>
        </Grid>
        <Grid container mt={2}>
        <Stack direction={'row'} spacing={2}>
          <Grid item xs={6} >
            <Paper elevation={4} style={{ borderRadius: '25px' }}>
              <Card sx={{borderRadius: '25px'}}>
                <CardContent>
                  <Typography color="text.secondary" sx={{ mb: 2,fontWeight:'bold' }}>
                    Monthly Power Saved in Units
                  </Typography>
                  <NoofTimesDoorOpened data={data1.group_chartResponses} height={300} width={580} />
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={4} style={{ borderRadius: '25px' }}>
              <Card sx={{borderRadius: '25px' }}>
                <CardContent>
                  <Typography color="text.secondary" sx={{ mb: 2 }}>
                    Monthly Cost Saved in Rs.
                  </Typography>
                  <TotalPowerSavedinHours data={data1.group_chartResponses} height={300} width={580}/>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          </Stack>
        </Grid>
        <Grid container spacing={2} direction={'row'} mt={1}>
          <Grid item xs={6}>
            <Paper elevation={4} style={{ borderRadius: '25px' }}>
            <Link to='../activedevices'><Card sx={{background: "linear-gradient(45deg,#fff 50%,#00FA9A 50%)", borderRadius: '25px' }}>
                <CardContent>
                  <Typography sx={{ mb: 1.5, fontWeight: 'bold' }} color="text.secondary">
                    Active Devices
                  </Typography>
                  <CardMedia image={deviceOnline} sx={{
                    width: '200px', height: '200px', float: 'right',
                    marginBottom: '-50px', marginTop: '-100px'
                  }} />
                  <Typography variant="h4" component="span" sx={{ float: 'left', mb: 1.5, color: "text.secondary", }}>
                    {data.activeDeviceCount}
                  </Typography>
                </CardContent>
              </Card></Link>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={4} style={{ borderRadius: '25px' }}>
            <Link to='../inactivedevices'><Card sx={{background: "linear-gradient(45deg,#fff 50%,#F67280 50%)", borderRadius: '25px' }}>
                <CardContent>
                  <Typography sx={{ mb: 1.5, fontWeight: 'bold' }} color="text.secondary">
                    Inactive Devices
                  </Typography>
                  <CardMedia image={deviceOffline} sx={{
                    width: '200px', height: '200px', float: 'right',
                    marginBottom: '-50px', marginTop: '-100px'
                  }} />
                  <Typography variant="h4" component="span" sx={{ float: 'left', mb: 1.5, color: "text.secondary", }}>
                    {data.inactiveDeviceCount}
                  </Typography>

                </CardContent>
              </Card></Link>
            </Paper>
          </Grid>
        </Grid>        
      </Grid>
    </>
  )
}

export default OrgUserDoorLockDashboard