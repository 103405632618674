import React, { FC,ReactElement, useCallback, useEffect } from 'react'
import { useState } from 'react'
import {Card,CardMedia,CardContent,Typography,Grid} from '@mui/material'
import { getAlertbyId } from 'src/services/alertsFarmService'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
interface ImagesViewProps{
    alerts:any[]
}

const ImagesView:FC<ImagesViewProps>=(props:ImagesViewProps):ReactElement=>{
    const organanizationCameras=useSelector((state:RootState)=>state.organization.cameras)
    const {id}=useParams()
    const{alerts}= props
    // console.log(alerts,'alertsImagesView')
    const ImgSrc=(src:any)=>{
        const base64Flag = 'data:image/jpeg;base64,';
        const ImgString=base64Flag+src
        return ImgString
    }
    const CameraName=()=>{
        const value=organanizationCameras.filter((x:any)=>x.id===id)[0]
        return value.name
        
    }
    const AlertList=(data:any)=>{
        const response:any=[]
        data.map((val:any)=>{
            console.log(val,'val')
           if(val!==''){
            response.push(val)
            response.push(',')
           }
        })
        console.log(response,'response')
        return response
    }
    return(
                <>
                <Grid container spacing={2}>
                {alerts && alerts.filter((_,idx:any)=>idx<50).map((data:any,index:any)=>{
                    return(
                    <Grid key={index} item xs={4} sx={{mt:1}}>
                    <Card sx={{ width:400,height:300,borderRadius:'5px' }}>
                    <CardMedia
                    component="img"
                    height="240"
                    image={ImgSrc(data.detectedImg.data)}
                    alt="green iguana"
                    sx={{ padding: "0em 0em 0em 0em", objectFit:'fill' }}
                    />
                    <CardContent sx={{backgroundColor:'#660066'}}>
                    <Typography variant="body2" color="#fff" fontWeight='bold' fontSize={16}>
                                    {AlertList(data.alertStatus.detectedThing)} Detected @ {CameraName()} on {data.alertStatus.dateTime.split('__')[0].replaceAll('_','/')} <span>{' '}</span>
                                    Time: {data.alertStatus.dateTime.split('__')[1].replaceAll('_',':')}
                    </Typography>
                    </CardContent>
                </Card>
                </Grid>
                    )
                    
                })}
                </Grid>

                </>
                
                )
}
export default ImagesView

// import React, { useCallback, useEffect } from 'react'
// // import { Buffer } from 'buffer'
// import { useState } from 'react'
// import {Card,CardMedia,CardContent} from '@mui/material'
// import { getAlertbyId } from 'src/services/alertsFarmService'


// const Cameras=()=>{
//     const [data1,setData1]=useState({detectedImg:{data:{data}}})
//     //detectedImg:{data:[],type:Buffer}
//     console.log(data1,'data1')
//     // const base96String = btoa(String.fromCharCode());
//     // const base96String=btoa(String.fromCharCode(...codes:data1.detectedImg.data))
//     const arrayBufferToBase64=(buffer:any)=> {
//         let binary = '';
//         const bytes = [].slice.call(new Uint8Array(buffer));
//         bytes.forEach((b) => binary += String.fromCharCode(b));
//         return window.btoa(binary);
//     };

//     // function _arrayBufferToBase64( buffer:any ) {
//     //     let binary = '';
//     //     console.log(buffer,'buffer.length')
//     //     const bytes = new Uint8Array( buffer );
//     //     const len = bytes.byteLength;
//     //     console.log(len)
//     //     for (let i = 0; i < len; i++) {
//     //         binary += String.fromCharCode( bytes[ i ] );
//     //     }
//     //     return window.btoa( binary );
//     // }

// //detectedImg:{data:[]}
//     const [data2,setData2]=useState('')
//     const dataSet=useCallback(async()=>{
//         const response =await getAlertbyId({alertId:'a8382c54-3140-4175-8479-b560ee48dc44'}).then((res) => res.json())
//         .then((data) => {
//             console.log(data)})
//         if (response.status===200){
//             setData1(response.data)
//             // const base64ImageString = Buffer.from(data1.detectedImg.data).toString('base64')
//             const value=(data:any)=>{
//                    const values= data.arrayBuffer()
//                    return values
//             }
//             const dataset=data1.detectedImg.data.data
//             console.log(typeof(dataset),'typeof(dataset)')
//             // console.log(value(dataset),'data1.detectedImg.data')
//             // const base64ImageString =_arrayBufferToBase64(data1.detectedImg.data)
//             // console.log(base64ImageString,'base96String1')
//             // setData2(dataset.data)
//         }
//     },[])
//         useEffect(()=>{
//             dataSet()
//         },[])
//         // console.log(typeof(data1!==undefined? data1.detectedImg.data:''))
//         // if (data1 !=='')
//         if(data1 !==undefined){
//             // const base64ImageString = Buffer.from(data1.detectedImg.data).toString('base64')
//             // console.log(base64ImageString,'base96String')
//         }
//         console.log(data2,'data2')
//         const img_src=`${data2}`
//         //'data:image/jpeg;base64'//+btoa(data1.detectedImg.data)
//         // `data:image/image/jpg;base64,base64_encode(${data1.detectedImg.data})`
//     return(
//         <>
        
//         <h1>Camera</h1>
//         <div>
//         <img src={img_src} width='1080px' alt='Image_Src'/>
 
//         </div>
//         {/* <Card sx={{width:'250px',height:'250px',borderRadius:'50%'}}>

//         <CardMedia
//         component="img"
//         height="248vh"
//         image='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2YPzGdWf3e56zMNWqWAB1vXZUflPnPadlVw&usqp=CAU'
//         alt="green iguana"
        
//         />

//         </Card> */}
        
//         </>
//     )
// }
// export default Cameras