import * as React from 'react';
import {FC} from 'react';
import "./app.css"
import { Typography,Box,Card,Button } from '@material-ui/core';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import lauchimg from '../assets/img/launch3.png'
import farmguardIcon from '../assets/img/farmguard.png';
import doorlockIcon from '../assets/img/doorlock.png';
import smarttimecontrollerIcon from '../assets/img/smarttimecontroller.png';
import motionsensorIcon from '../assets/img//motion-sensor.png'
const data={
name:'Farm Guard',
description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima maxime quam architecto quo inventore harum ex magni, dicta impedit.'
}
const CardComponent= (props) => {
    const {data}=props;


    const icons={
        'Farm Guard':farmguardIcon,
        'Smart Door Lock Detector and Monitor':doorlockIcon,
        'Smart Timer Controller':smarttimecontrollerIcon,
        'Smart Motion Detector':motionsensorIcon,
        }
    
    const source=icons[data.name]
    return (
        
                <Card className="cards" style={{border:'none',width:'300px',borderRadius:'5%',boxShadow:'none',background:'transparent',
                
                }}> 
                <Card className='iconBoxes'  boxshadow={10} style={{
                    textAlign:'center',justifyContent:'center',borderRadius:'50%',marginBottom:'10px'
                       
                    }}>
                    <img src={source} alt='icon' width='50px' height='50px' style={{textAlign:'center',
                        marginTop:'25%'}}
                        />
                </Card>

                        
                    <Box  className='headerboxes'
                        > 
                         <Typography  variant="h5"
                         style={{color:'black',
                             fontWeight:'bold'}}>
                             {data.name}
                         </Typography> </Box>
                         <div></div>
                        
                        <Box className="descBoxes"> 
                        <div style={{textAlign:'left',justifyContent:'center'}}>
                        <p><b>Type:</b>   {data.Type}<br/>
                        <b>Features:</b> {data.Features}<br/>
                        <b>Sensor:</b>{data.Sensor}<br/>
                        <b>Range:</b> {data.Range}<br/>
                        <b>Brand:</b> {data.Brand}<br/></p>
                        </div>
                        </Box>
                    
                </Card>

    )

}
export default CardComponent;






{/* <Card style={{ justifyContent:"center", alignItems:"center"}}></Card>
                    <div className="card__side card__side--back card__side--back-1"
                    style={{boxShadow:'2px 5px 9px #888888'}}>
                    <div className="card__cta">
                    <div style={{ overflow: 'hidden',marginTop:'15%',height:'8.5rem',
                    textAlign:'center',justifyContent:'center',  
                    }}>
                    <Typography  variant="h5" style={{color:'white',
                             fontWeight:'bold'}}
                    >
                        {/* {data.name} 'Farm Guard'
                    </Typography></div>
                            <div style={{textAlign:'center',justifyContent:'center',marginTop:'-25px'}}>
                            <Button variant='text' size="small" 
                            // disabled={access(data._id)}
                            // onClick={(x)=>pageNavigate(x,data._id,data.name)}                            
                            >
                                {/* {access(data._id)===false ? ( 
                                {true===false ? (
                            <img src={lauchimg} alt='' width='100px' height='100px'/>)
                            :(<img className='launchButton' src={lauchimg} alt='' width='100px' height='100px'/>)}                           
                            </Button></div>




                        </div>
                    </div> */}