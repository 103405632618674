import { Box, Toolbar } from "@mui/material";
import { FC, ReactElement } from "react";
import Alert from "./Alert";

interface MainContainerProps {
    children: ReactElement
}

const MainContainer: FC<MainContainerProps> = (props: MainContainerProps): ReactElement => {

    const {children} = props;

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3}}>
            <Toolbar />
            <Alert />
            {children}
        </Box>
    )
}

export default MainContainer;