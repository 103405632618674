import React from 'react'
import { useNavigate } from 'react-router-dom'
import {Button, Grid} from '@mui/material'
import { RootState } from 'src/store'
import { useSelector,useDispatch } from 'react-redux'
import { SET_FARMALERTSBYCAMERA,SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE } from 'src/store/actions/actionTypes'
import FolderComponent from '../../../components/homePage/FarmGuard/Camera/folderComponent'
import { getAlertsByDeviceId,getFarmAlertCountByDeviceId } from 'src/services/alertsFarmService'
import { refreshFarmGuardDataByCamera } from 'src/store/actions/alertFarmGuardAction'
const Cameras=()=>{
    const organizationCameras=useSelector((state:RootState)=>state.organization.cameras)
    const organizationId=useSelector((state:RootState)=>state.organization.id)
    const applicationId=useSelector((state:RootState)=>state.application.id)
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const handlerClick= async(data:any)=>{
        navigate(`/home/cameras/folderView/${data.id}`)
        const response1 = await getAlertsByDeviceId({deviceId: data.id, organizationId: organizationId, applicationId: applicationId })
        if(response1.status===200){
            refreshFarmGuardDataByCamera(dispatch,response1.data)       
        }       
        const response2 = await getFarmAlertCountByDeviceId({deviceId: data.id, organizationId: organizationId, applicationId: applicationId })
        if(response2.status===200){
            dispatch({type:SET_ALERTS_FARMGUARD_COUNT_BY_DEVICE,payload:{dayResponse:response2.data.dayResponse,monthResponse:response2.data.monthResponse}})
        }   
    }
    return(
        <>
        <Grid container spacing={4}>
        {organizationCameras && organizationCameras.map((data:any,index:any)=>{
                return (
            <Grid item key={index}>
                <Button onClick={()=>handlerClick(data)} sx={{":hover":{filter:'drop-shadow(30px 10px 4px #CCDAD9)'}}}>
                <FolderComponent cameraName={data.name}/>
                </Button>
             </Grid>
                )
        })}
           </Grid>
        
        </>
                

                
                )
}
export default Cameras
