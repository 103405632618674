import { IOrganizationState, IAction } from "src/types";
import { SET_ORGANIZATIONDATA, RESET_ORGANIZATION } from "../actions/actionTypes";

const initalState : IOrganizationState = {
    id: '',
    name : '',
    fetchStatus: false,
    orgCoverImg: '',
    cameras:'',
}
const organizationReducer = (state : IOrganizationState = initalState, action: IAction) => {

    const {type, payload} = action;

    switch(type) {
        case SET_ORGANIZATIONDATA:
            return {
                ...state,
                 name: payload.name,
                 id: payload.id,
                 orgCoverImg: payload.orgCoverImg,
                 cameras:payload.cameras
            }
        case RESET_ORGANIZATION:
            return {
                ...state,
                initalState
            }
        default:
            return state
    }
}

export default organizationReducer