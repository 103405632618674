import React from 'react';
import { Box, Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import { FC, Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { getFloorDashboardDetails, getDeviceDataGroupbyFloorId } from "src/services/floorService";
import { RootState } from "src/store";
import { IBuilding, IFloor } from "src/types";
import { useNavigate } from 'react-router-dom';
import KeyboardDoubleArrowLeftSharpIcon from '@mui/icons-material/KeyboardDoubleArrowLeftSharp';
import { getBuildingData } from "src/utils/commonMethods";
import NoofTimesDoorOpened from "src/components/homePage/NewChartWidget/NoofTimesDoorOpened";
import TotalUnitsSaved from "src/components/homePage/NewChartWidget/TotalUnitsSaved";
import TotalPowerSavedinHours from "src/components/homePage/NewChartWidget/TotalPowerSavedinHours";
import TotalCostSaved from "src/components/homePage/NewChartWidget/TotalCostSaved";
import { floorDashBoardDetails,deviceDataGroupByFloorId } from 'src/store/actions/floorActions';
// interface IFloorView {
// }

const FloorView: FC = (props): ReactElement => {

  const navigate = useNavigate()

  // const [data, setdata] = useState<any>({
  //   deviceCount: 0,
  //   roomCount: 0,
  //   chartResponseOpened: [],
  //   chartResponseClosed: [],
  //   cosumptionDetails: [{
  //     "_id": {
  //       "year": 0,
  //       "month": 0
  //     },
  //     "total_hours": 0,
  //     "total_count": 0
  //   }]
  // });

  const [dataFetchStatus, setDataFetchStatus] = useState(false)
  const buildings: IBuilding[] = useSelector((state: RootState) => state.buildings.buildings)
  const floor: IFloor = useSelector((state: RootState) => state.floors.selectedFloor)
  const devices = useSelector((state: RootState) => state.devices.devices)
  const application = useSelector((state: RootState) => state.application)
  const organization = useSelector((state: RootState) => state.organization)
  const floorDashboard=useSelector((state:RootState)=>state.floors.floorDashBoardDetails)
  const deviceDataByFloorId=useSelector((state:RootState)=>state.floors.deviceDataGroupByFloorId)
  const dispatch=useDispatch()
/////////////////
  const refreshHome = useCallback(async () => {
    const response = await getFloorDashboardDetails({ organizationId: organization.id, applicationId: application.id, floorId: floor._id })
    if (response.status === 200) {
      floorDashBoardDetails(dispatch,response.data)
      // setdata(response.data)
      setDataFetchStatus(true)
    }
  }, [])



  useEffect(() => {
    if (!dataFetchStatus) {
      refreshHome()
    }
  }, [dataFetchStatus])//, refreshHome, data
  /////////////////

  // const [data1, setData1] = useState<any>({
  //   group_chartResponses: []
  // })
  const group_chartResponses = async () => {
    const response = await getDeviceDataGroupbyFloorId({ floorId: floor._id,organizationId:organization.id,applicationId:application.id })
    if (response.status === 200) {
      deviceDataGroupByFloorId(dispatch,response.data)
      // setData1(response.data)
    }
  }
  useEffect(() => {
    group_chartResponses();
  }, [])
//////////////////
// console.log(data1,'data1')
  return (
    <Fragment>
      {
        floor ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant='h5'> <KeyboardDoubleArrowLeftSharpIcon onClick={() => navigate(-1)} style={{
                width: "25px", height: "25px", marginRight: '25px',
                borderRadius: '5px', border: '2px solid #fff', backgroundColor: "#660066", color: "#fff"
              }} />Floor -  {floor.name} </Typography>

            </div>
            <hr />
            <br />
            {/* <Typography> {floor.description} </Typography> <br /> */}
            <Box>
              <Grid container spacing={5} alignItems="flex-start" >
                <Grid item xs={4}>
                  <Card sx={{
                    borderRadius: '25px',
                    background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                    boxShadow: "rgb(152 145 145) 1px 5px 15px"
                  }}>
                    <CardContent>
                      <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                        TOTAL ROOMS IN THIS FLOOR
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mb: 1, fontWeight: 'bold', float: 'right', mr: 2 }} color="#000">
                        {floorDashboard.roomCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card sx={{
                    borderRadius: '25px',
                    background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                    boxShadow: "rgb(152 145 145) 1px 5px 15px"
                  }}>
                    <CardContent>
                      <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                        TOTAL DEVICES IN THIS FLOOR
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mb: 1, fontWeight: 'bold', float: 'right', mr: 2 }} color="#000">
                        {floorDashboard.deviceCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card sx={{
                    borderRadius: '25px',
                    background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                    boxShadow: "rgb(152 145 145) 1px 5px 15px"
                  }}>
                    <CardContent>
                      <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                        Building
                      </Typography>
                      <Typography variant="h4" component="div" sx={{ mb: 1, fontWeight: 'bold', float: 'right', mr: 2 }} color="#000">
                        {
                          getBuildingData(buildings, floor.buildingId).name
                        }
                      </Typography>
                    </CardContent>

                  </Card>
                </Grid>
                <Grid container spacing={2} direction={'row'} mt={1}>
                  <Grid item xs={6}>
                    <Card sx={{
                      borderRadius: '25px',
                      background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                      boxShadow: "rgb(152 145 145) 1px 5px 15px",
                      ml:5,
                    }}>
                      <CardContent>
                        <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                          Total Units Saved
                        </Typography>
                        <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                          {deviceDataByFloorId.group_chartResponses.length !== 0 ? (deviceDataByFloorId.group_chartResponses.reduce((prev: any, curr: any) => { return prev + +curr.total_units }, 0)) : 0}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{
                      borderRadius: '25px',
                      background: "linear-gradient(10deg,#CC99CC 1%,#fff 99%)",
                      boxShadow: "rgb(152 145 145) 1px 5px 15px"
                    }}>
                      <CardContent>
                        <Typography variant="h6" component="div" sx={{ mb: 0, fontWeight: 'bold' }} color="text.secondary">
                          Total Cost Saved
                        </Typography>
                        <Typography variant="h4" component="div" sx={{ mb: 0, mr: 2, fontWeight: 'bold', float: 'right' }} color="#000">
                          {/* {data.cosumptionDetails.length!==0 ?(data.cosumptionDetails[0].total_count):0} */}
                          {deviceDataByFloorId.group_chartResponses.length !== 0 ? (deviceDataByFloorId.group_chartResponses.reduce((prev: any, curr: any) => { return prev + +curr.total_cost_Saved }, 0)) : 0}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {/* <Grid container spacing={2} direction={'row'} mt={1}>
              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Total Number of times Door Opened
                      </Typography>
                      <NoofTimesDoorOpened data={floorDashboard.chartResponseOpened} height={300} width={500} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Total Power Saved in Hours
                      </Typography>
                      <TotalPowerSavedinHours data={floorDashboard.chartResponseClosed} height={300} width={500} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid> */}
            <Grid container columnSpacing={2} sx={{ mt: 5 }}>
              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Total Units Saved
                      </Typography>
                      <TotalUnitsSaved data={deviceDataByFloorId.group_chartResponses} height={300} width={500} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>


              <Grid item xs={6}>
                <Paper elevation={4} style={{ borderRadius: '25px' }}>
                  <Card sx={{ minWidth: 275, borderRadius: '25px' }}>
                    <CardContent>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Total Cost Saved in Rs.
                      </Typography>
                      <TotalCostSaved data={deviceDataByFloorId.group_chartResponses} height={300} width={500} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid><br /></>
        ) : (
          <></>
        )
      }
    </Fragment>
  )
}

export default FloorView