import React from 'react';
import {Button,Typography,Card,CardMedia,TextField,MenuItem } from '@mui/material';
import { FC, ReactElement,useState,useCallback,useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "src/store";
import Notification from '../../../components/homePage/Notifications/notification'
import {useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch } from '@fortawesome/free-solid-svg-icons';
import { refreshStudentData } from 'src/store/actions/studentDataAction';
import 'src/screens/Home/StudentData/studentEntry.css'
import studentDataBackground from 'src/assets/svg/studentDataBackGround.gif'
import { createStudent,deleteStudent,updateStudent,getAllStudentDataByOrganisation,uploadFiles } from 'src/services/studentDataService';
import { IBuilding,IFloor,IRoom,IStudentData } from 'src/types';
import moment from 'moment';
import API_ENDPOINT from '../../../services/ApiEndPoint';
import axios from "axios";
import readXlsxFile from 'read-excel-file'
import { data } from '../Building/Data';

import { type } from 'os';
type AlertType = "error" | "success" | "info" | "warning" | undefined;
const Instruments: FC = (props: any): ReactElement => {	
	const [inputValue, setInputValue] = useState('');
    // console.log(inputValue,'inputValue')
	const [formVisibility,setFormVisibility]=useState(true)
    // const buildings=useSelector((state:RootState)=>state.buildings.buildings)

    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const floors = useSelector((state: RootState) => state.floors.floors)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)
    // console.log(buildings,'buildings')
	const organization=useSelector((state:RootState)=>state.organization)
	const application=useSelector((state:RootState)=>state.application)
	const studentDatas=useSelector((state:RootState)=>state.studentData.students)
    // console.log(studentDatas,'studentDatas')
	const lastRegNo=studentDatas.map((x:any)=>x.regNo).sort().reverse()
	// console.log(lastRegNo[lastRegNo.length-1]+1,'lastRegNo')
	const dispatch=useDispatch()
	const organizationId = organization.id
	const applicationId = application.id
	const navigate =useNavigate()
	// console.log(instrumentList,'instrumentList')
	
	const refreshHome= useCallback(async ()=>{
		const response1= await getAllStudentDataByOrganisation({organizationId,applicationId})
		// dispatch({type:SET_INSTRUMENTSDATA,payload:{value:response1.data,fetchStatus:true}})
		refreshStudentData(dispatch,response1.data)
		navigate('/home')
		setTimeout(()=>{navigate('/home/studentEntry')},100)
	},[])


	const [inputValues,setInputValues]=useState<{[key:string]:any}>({
		academicYearFrom:0,
        academicYearTo: 0,
        address: "",
        age: 0,
        branch: "",
        buildingId: "",
        dateOfBirth: "",
        dateOfJoining: "",
        dateOfLeft: "",
        floorId: "",
        gender: "",
        mailID: "",
        parentPhoneNo: 0,
        phoneNo: 0,
        regNo: 0,
        rollNo: "",
        roomId: "",
        studentName: "",
        _id:"",
        organizationId:"",
        applicationId:"",
	})

	const handleInputChange=(e:any)=>{
		e.preventDefault();
		setInputValues((prev:{[key:string]:string})=>({...prev,[e.target.name]:e.target.value}));
	}
	// console.log(inputValues,'inputValues')

	const deleteStudentData=async()=>{
		const response=await deleteStudent({_id:inputValues._id,organizationId:inputValues.organizationId,applicationId:inputValues.applicationId})
        if(response.status===200){
            // console.log(response.data,"Student Deleted")
            refreshHome()
        }
	}
	const submitStudent=async()=>{
		const response=await createStudent({...inputValues,organizationId,applicationId})
        if(response.status===200){
            refreshHome()
            // console.log('Student Created')
        }
	}
    const updateStudentData=async()=>{
		const response=await updateStudent({...inputValues})
        if(response.status===200){           
            // console.log(response.data,'Student Updated')
            refreshHome()
        }
	}
	const Search=()=>{
		const searchStudent=studentDatas.filter((x:any)=>x.regNo===Number(inputValue))[0]
        // console.log(searchStudent,'searchStudent')
        if(searchStudent !== undefined){
            setInputValues(searchStudent)
        }
        

	}
    const dateFormat=(data:any)=>{
        return moment(data).format('yyyy-MM-DD')
    }

    const[buttonClicked,setButtonClicked]=useState(false)
    // console.log(buttonClicked,'buttonClicked')


    const [file,setFile]=useState()
    const [dataCopy,setDataCopy]=useState<any[]>([])
    const handleChange=(event:any)=>{
        if(event.target.files[0].name.split('.')[1]==='xlsx'){
            setFile(event.target.files[0])
        }
        else{
            console.log('Select PDF File')
        }
        
    }
    const handleDataSubmit=(file:any)=>{
        readXlsxFile(file).then((rows)=>setDataCopy(rows))
        // const formData = new FormData()
        // formData.append('applicationId',applicationId)
        // formData.append('organizationId',organizationId)
        // formData.append('file',file)
        // axios.post(API_ENDPOINT+'/upload/uploadStudentData',formData, {
        // }).then(res => {
        //     console.log(res)
        // })
    }
    const [rollNoList,setRollNoList]=useState([''])
    useEffect(()=>{
        setRollNoList([])
        studentDatas.map((data:any)=>{
            setRollNoList((prev)=>[...prev,data.rollNo])
        })
    },[studentDatas])

    const uploadingRollNo:any[]=[]
    // console.log(uploadingRollNo,'uploadingList')
    const [uploadingList,setUploadingList]=useState<any[]>([])
    // console.log(uploadingList,'uploadingList')
    useEffect(()=>{
        setUploadingList([])
        dataCopy.slice(1).map((data:any,index:any)=>{
            if(!uploadingRollNo.includes(data[3])){               
                setUploadingList((prev)=>[...prev,data])  
                uploadingRollNo.push(data[3])             
            }
        })
    },[dataCopy])

    const [dataTocreate,setDataToCreate]=useState([{}])
    const[createCount,setCreateCount]=useState(0)
    const [status, setStatus] = useState(false)
    const [message, setMessage] = useState<AlertType>('success')
    useEffect(()=>{
        setDataToCreate([])
        setCreateCount(0)
        const regNoFinal=lastRegNo[0]
        // console.log(regNoFinal,'regNoFinal')
        
        uploadingList.filter((x:any)=>!rollNoList.includes(x[3])).map((data:any,index:any)=>{
            const buildingId=buildings.filter((x:any)=>x.name===data[16])[0]._id?buildings.filter((x:any)=>x.name===data[16])[0]._id:'None'
            const floorId=floors.filter((x:any)=>x.name===data[15] && x.buildingId===buildingId)[0]._id?floors.filter((x:any)=>x.name===data[15] && x.buildingId===buildingId)[0]._id:'None'
            const roomId=rooms.filter((x:any)=>x.number===data[14] && x.buildingId===buildingId && x.floorId===floorId )[0]._id?rooms.filter((x:any)=>x.number===data[14] && x.buildingId===buildingId && x.floorId===floorId )[0]._id:'None'
            const values={
                'studentName':data[1],
                'regNo':regNoFinal+1+index,
                'rollNo':data[3],
                'gender':data[4],
                'age':data[5],
                'dateOfBirth':data[6],
                'academicYearFrom':data[7],
                'academicYearTo':data[8],
                'branch':data[9],
                'mailID':data[10],
                'phoneNo':data[11],
                'parentPhoneNo':data[12],
                'address':data[13],
                'roomId':roomId,
                'floorId':floorId,
                'buildingId':buildingId,
                'dateOfJoining':data[17],
                'dateOfLeft':data[18],
                'applicationId':applicationId,
                'organizationId':organizationId
}            
                setDataToCreate((prev)=>[...prev,values])
        })      
        setCreateCount(1)
    },[uploadingList])

console.log(dataCopy,'dataCopy.length')


    
const DataCreation=useCallback( (data:any)=>{
     createStudent(data) 
},[])


console.log(dataTocreate,'dataTocreate')

useEffect(()=>{     
        if(dataTocreate.length!==0 && createCount===1){           
            
            dataTocreate.map((data:any,index:any)=>{
                console.log(data.studentName,index)
                DataCreation(data)
            })
            setStatus(true)
            setMessage('success')           
        }     
    //     async() =>{const response1= await getAllStudentDataByOrganisation({organizationId,applicationId})
    //     refreshStudentData(dispatch,response1.data)
    // }
    (async (): Promise<void> => {
        try {
            const response1 = await getAllStudentDataByOrganisation({ organizationId, applicationId });
            refreshStudentData(dispatch, response1.data);
        } catch (error) {
            // Handle errors if needed
            console.error('Error fetching student data:', error);
        }
    })();
		
    },[dataTocreate])
    

   
	return (
		<>
        <Card sx={{width:'80%',marginLeft:'10%',borderRadius:'15px'}}>
        <CardMedia
        component="img"
        height="250"
        // image="https://wallpaperaccess.com/full/1858898.jpg"
        image={studentDataBackground}
        alt="green iguana"
      />
		<div className='app-backgrounds'>			
			<div className='main-containers'>
			<Typography sx={{color:'#000',fontSize:22,fontWeight:'bold',justifyContent:'center',textAlign:'center',textDecoration:'underline' }}>Hostel Student Registration</Typography>			
			<Button variant='contained' sx={{ backgroundColor:'#660066',color:'#fff',float:'right',borderRadius:5,ml:5, ":hover": { backgroundColor: '#660066',color:'#fff' } }} onClick={deleteStudentData}>Delete</Button>
			{buttonClicked?<Button variant='contained' sx={{ backgroundColor:'#660066',color:'#fff',float:'right',borderRadius:5,ml:5, ":hover": { backgroundColor: '#660066',color:'#fff' } }} onClick={updateStudentData}>Update</Button>:
            <Button variant='contained' sx={{ backgroundColor:'#660066',color:'#fff',float:'right',borderRadius:5,ml:5, ":hover": { backgroundColor: '#660066',color:'#fff' } }} onClick={submitStudent}>Submit</Button>}
            <Button variant='contained' sx={{ backgroundColor:'#660066',color:'#fff',float:'right',borderRadius:5,ml:5, ":hover": { backgroundColor: '#660066',color:'#fff' } }} onClick={()=>{setFormVisibility(true);refreshHome();setButtonClicked(false)}}>+ Add/Refresh</Button>
			<Button variant='contained' sx={{ backgroundColor:'#660066',color:'#fff',float:'right',borderRadius:5,ml:5, ":hover": { backgroundColor: '#660066',color:'#fff' } }} onClick={()=>{navigate('../studentEntry/studentReport')}}>Report</Button>
			<span style={{float:'left'}}>
            <input  type="file" onChange={handleChange} style={{backgroundColor:'#660066',color:'#fff',borderRadius:'5px',fontWeight:'bold'}} />
            <Button variant='contained' sx={{ backgroundColor:'#660066',color:'#fff',borderRadius:5,ml:1, ":hover": { backgroundColor: '#660066',color:'#fff' } }} onClick={()=>handleDataSubmit(file)} >Upload</Button>
            </span>
            <br/>
			<br/>
				<div className='add-item-boxs'>					
					<input list='studentList' style={{fontWeight:'bold',fontSize:14}} value={inputValue} onChange={(event) => setInputValue(event.target.value)} className='add-item-input' placeholder='Search Student RegNo...' />
					<datalist id="studentList">
                        {lastRegNo.map((x:any)=>{
                            return <option key={x} value={x}>{x}</option>
                        })}
                    </datalist>
                    <FontAwesomeIcon icon={faSearch} onClick={()=>{Search();setButtonClicked(true)}}/>
				</div>
				{formVisibility?<div>
                <p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Regn ID</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField aria-readonly name='regNo' label="Reg No" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14}} value={buttonClicked?inputValues.regNo:lastRegNo[0]+1} className='input-field'/>
                <p style={{alignItems:'center',display:'flex',marginLeft:'15%' }}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold'}} >Building Name</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='buildingId' select label="Building Name" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}} value={inputValues.buildingId} onChange={handleInputChange} className='input-field'>
                    <MenuItem>None</MenuItem>
                    {buildings.map((x:IBuilding)=>{
                        console.log()
                        return <MenuItem key={x.id} value={x._id}>{x.name}</MenuItem>
                    })}
                </TextField>
                
                </p>
                </p>
                <br/>
                <p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Student Name</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='studentName' label="Student Name" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14}} value={inputValues.studentName} onChange={handleInputChange} className='input-field'/>
                <p style={{alignItems:'center',display:'flex',marginLeft:'15%' }}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold'}} >Floor Name</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='floorId' select label="Floor Name" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}} value={inputValues.floorId} onChange={handleInputChange} className='input-field'>
                <MenuItem>None</MenuItem>
                {floors.filter((x:IFloor)=>x.buildingId===inputValues.buildingId).map((y:IFloor)=>{
                    return <MenuItem key={y.id} value={y.id}>{y.name}</MenuItem>
                })}
                </TextField>
                </p>
                </p>
                <br/>
                <p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Roll No</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='rollNo' label="Roll No" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14}} value={inputValues.rollNo} onChange={handleInputChange} className='input-field'/>
                <p style={{alignItems:'center',display:'flex',marginLeft:'15%' }}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold'}} >Room Name</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='roomId' label="Room Name" select size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}} value={inputValues.roomId} onChange={handleInputChange} className='input-field'>
                    <MenuItem>None</MenuItem>
                    {rooms.filter((x:IRoom)=>x.buildingId===inputValues.buildingId && x.floorId===inputValues.floorId).map((y:IRoom)=>{
                        return <MenuItem key={y.id} value={y.id}>{y.number}</MenuItem>
                    })}
                </TextField>
                </p>
                </p>
                <br/>
				<p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Age</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='age' label="Age" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14}} value={inputValues.age} onChange={handleInputChange} className='input-field'/>
                <p style={{alignItems:'center',display:'flex',marginLeft:'15%' }}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold'}} >Date of Birth</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField  type='date' name='dateOfBirth' size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}} value={dateFormat(inputValues.dateOfBirth)} onChange={handleInputChange} className='input-field'/>
                </p>
                </p>
                <br/>
                <p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Gender</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField select name='gender' label="Gender" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}} value={inputValues.gender} onChange={handleInputChange} className='input-field'>
                    <MenuItem value={'Male'}>Male</MenuItem>
                    <MenuItem value={'Female'}>Female</MenuItem>
                </TextField>
                <p style={{alignItems:'center',display:'flex',marginLeft:'15%' }}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold'}} >Date of Joining</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField type="date" name='dateOfJoining'  size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}} value={dateFormat(inputValues.dateOfJoining)} onChange={handleInputChange} className='input-field'/>
                </p>
                </p>
                <br/>
                <p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Academic Year</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField type='number' name='academicYearFrom' label="From" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'12ch'}} value={inputValues.academicYearFrom} onChange={handleInputChange} className='input-field'/>
                <TextField type='number' name='academicYearTo' label="To" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,ml:4,width:'12ch'}} value={inputValues.academicYearTo} onChange={handleInputChange} className='input-field'/>
                <p style={{alignItems:'center',display:'flex',marginLeft:'15%' }}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold'}} >Date of Left</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField type="date" name='dateOfLeft' size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14,width:'28ch'}} value={dateFormat(inputValues.dateOfLeft)} onChange={handleInputChange} className='input-field'/>
                </p>
                </p>
                <br/>                
                <p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Branch</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='branch' label="Branch" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14}} value={inputValues.branch} onChange={handleInputChange} className='input-field'/>
                <p style={{alignItems:'center',display:'flex',marginLeft:'15%' }}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold'}} >Mail ID</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField type='email' name='mailID' label="Mail ID" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14}} value={inputValues.mailID} onChange={handleInputChange} className='input-field'/>
                </p>
                </p>
                <br/>
                <p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Phone No</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='phoneNo' label="Phone No" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14}} value={inputValues.phoneNo} onChange={handleInputChange} className='input-field'/>
                <p style={{alignItems:'center',display:'flex',marginLeft:'15%' }}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold'}} >Parent Phone No</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='parentPhoneNo' label="Parent Phone No" size='small' variant="outlined" color='secondary' sx={{fontWeight:'bold',fontSize:14}} value={inputValues.parentPhoneNo} onChange={handleInputChange} className='input-field'/>
                </p>
                </p>
                <br/>
                <p style={{alignItems:'center',display:'flex'}}><Typography sx={{width:'150px',color:'#660066',fontSize:16,fontWeight:'bold' }} >Address</Typography><Typography sx={{width:'10px'}} >:</Typography>
                <TextField name='address' label="Address" size='small' variant="outlined" color='secondary' multiline rows={6} sx={{fontWeight:'bold',fontSize:14,width:'92ch'}} value={inputValues.address} onChange={handleInputChange} className='input-field'/>
                </p>
                <br/>
                <Notification message={message} status={status} />
			</div>
			:<></>}
            </div>
		</div>
        </Card>
		</>
	);
};

export default Instruments