import { IFloorState, IAction } from "src/types";
import { SET_FLOORDATA, RESET_FLOOR, SET_SELECTEDFLOORDATA,FLOOR_DASHBOARD_DETAILS,GROUP_DATA_BY_FLOORID } from "../actions/actionTypes";

const initalState: IFloorState = {
    selectedFloor: null,
    floorDashBoardDetails:{ deviceCount: 0,
        roomCount: 0,
        chartResponseOpened: [],
        chartResponseClosed: [],
        cosumptionDetails: [{"_id": {"year": 0,"month": 0},"total_hours": 0,"total_count": 0,}]},
    deviceDataGroupByFloorId:{group_chartResponses:[]},
    floors: [],
    fetchStatus: false
}

const floorReducer = (state: IFloorState = initalState, action: IAction) => {

    const { type, payload } = action;

    switch (type) {
        case SET_FLOORDATA:
            return {
                ...state,
                floors: payload.value,
                fetchStatus: payload.fetchStatus
            }
        case SET_SELECTEDFLOORDATA:
            return {
                ...state,
                selectedFloor: {
                    ...payload
                }
            }
        case RESET_FLOOR:
            return {
                ...state,
                initalState
            }
        case FLOOR_DASHBOARD_DETAILS:
            return {
                ...state,
                floorDashBoardDetails:{...payload.value}
            }
        case GROUP_DATA_BY_FLOORID:
            return{
                ...state,
                deviceDataGroupByFloorId:{...payload.value}
            }
        default:
            return state
    }
}

export default floorReducer