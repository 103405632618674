import { FC, ReactElement, useEffect, useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Paper, Box, Grid, Typography, CircularProgress, Backdrop } from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from 'src/services/userService';
import { HIDE_LOADER, SET_USERDATA, SHOW_LOADER } from 'src/store/actions/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from 'src/store';
import { ROLE } from 'src/constants/shared';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://bgent.com/">
        BGENT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const Login: FC = (): ReactElement => {

  const user = useSelector((state: RootState) => state.user)

  // useEffect(() => {
  // }, [user.token])

  const dispatch = useDispatch()

  const [formData, setFromData] = useState({
    emailId: { value: '', formHelperText: '' },
    password: { value: '', formHelperText: '' },
    loginErrorMessage: '',
    showPassword: false,
  })

  const { emailId: { value: emailIdValue, formHelperText: emailIdHelperText }, password: { value: passwordValue, formHelperText: passwordHelperText }, loginErrorMessage } = formData;

  const { visible } = useSelector((state: RootState) => state.loading)

  const onChangeForm = (e: any) => {
    setFromData({ ...formData, [e.target.name]: { value: e.target.value, formHelperText: '' }, loginErrorMessage: '' })
  }
  const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
  );
  const onHandleClick = async (e: any) => {
    e.preventDefault()
    dispatch({ type: SHOW_LOADER, payload: {} })
    if (emailIdValue.length === 0) {
      setFromData({ ...formData, emailId: { ...formData.emailId, formHelperText: 'Please Enter the Email Id' } })
    }
    else if (!validEmail.test(emailIdValue)) {
      setFromData({ ...formData, emailId: { ...formData.emailId, formHelperText: 'Please Enter a valid Email Id' } })
    }
    else if (passwordValue.length === 0) {
      setFromData({ ...formData, password: { ...formData.password, formHelperText: 'Please Enter the Password' } })
    }
    else if (passwordValue.length < 8) {
      setFromData({ ...formData, password: { ...formData.password, formHelperText: 'Please enter a valid password' } })
    }
    else {
      const response = await login({ email: emailIdValue, password: passwordValue })
      if (response.status === 200 && response.data.status === 'success') {
        const data = response.data;
        // console.log(data)
        dispatch({ type: SET_USERDATA, payload: { token: data.token, id: data.id, emailId: data.email, name: data.name, role: data.role.name, phoneNumber: data.phoneNumber } })
      } else {
        setFromData({ ...formData, loginErrorMessage: response.data.errorMessage })
      }
    }
    dispatch({ type: HIDE_LOADER, payload: {} })
  }

  if (user.token !== "") {
    return (user.role !== ROLE.SuperAdmin) ? <Navigate to="/switchApps" /> :<Navigate to="/switchApps" /> 
  }

  {/*<Navigate to="/home" />*/}
  const handleClickShowPassword = () => {
    setFromData({
      ...formData,
      showPassword: !formData.showPassword
    });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{
        height: '100vh', backgroundImage: 'url(./loginBackground.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ background: '#01122678', color: 'white' }}>
          <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <Typography component="h5" variant="h5"> Welcome to BGENT </Typography>
            <hr style={{ width: '80%' }} />
            <Avatar sx={{ m: 1, bgcolor: '#1976d2' }}> <LockOutlinedIcon /> </Avatar>
            <Typography component="h1" variant="h5"> Sign in </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField sx={{ backgroundColor: '#dbedff' }} variant='filled' margin="normal" required fullWidth id="emailId" label="Email Address" name="emailId" autoComplete="emailId" autoFocus value={emailIdValue} onChange={onChangeForm} helperText={emailIdHelperText} error={emailIdHelperText !== ""} />
              <TextField sx={{ backgroundColor: '#dbedff' }} variant='filled' margin="normal" required fullWidth name="password" label="Password" id="password" autoComplete="current-password" helperText={passwordHelperText} error={passwordHelperText !== ""}
                onChange={onChangeForm}
                type={formData.showPassword ? 'text' : 'password'}
                value={passwordValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {formData.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}

              />
              <Button type="submit" fullWidth variant="contained" onClick={onHandleClick} sx={{ mt: 3, mb: 2 }}> Sign In</Button>
              {
                loginErrorMessage.length !== 0 ? (<p style={{ width: '100%', textAlign: 'center', color: 'red' }}>{loginErrorMessage}</p>) : (<></>)
              }
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">Forgot password?</Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={visible}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </ThemeProvider>
  );
}

export default Login