import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { FC, ReactElement } from "react";
import { deleteBuilding } from "src/services/buildingService";
type AlertType = "error" | "success" | "info" | "warning" | undefined;
interface DeleteBuildingProps {
    visibility: boolean,
    setVisiblity: (a: any) => void;
    refreshHome: () => void;
    data: any;
    // msgDel:(a:boolean)=>void;
    statusData:(a: boolean)=>void;
    messagesData:(a:AlertType)=>void
}

const DeleteBuilding: FC<DeleteBuildingProps> = (props: DeleteBuildingProps): ReactElement => {

    const { visibility, setVisiblity, refreshHome, data : {name, id},statusData,messagesData } = props;

    const handleClick = async (e: any) => {
        e.preventDefault();
        const response = await deleteBuilding({name, _id: id})
        if(response.status === 200) {
            refreshHome()
            // msgDel(true)
            statusData(true)
            messagesData('warning')
            setVisiblity({visible: false})
        }
    }

    const handleClose = () => {
        setVisiblity({visible: false})
    }


    return (
        <Dialog open={visibility} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"> Delete Building </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure do you want to delete {name} building ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClick} autoFocus> Delete </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteBuilding