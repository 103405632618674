import { AgGridReact } from 'ag-grid-react';
import { Button } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FC, ReactElement, useState,useMemo,useCallback,useRef, useEffect, Key } from 'react';
import { ColDef } from 'ag-grid-community';
import { FilterSharp } from '@mui/icons-material';

interface GridViewProps {
    columnDefs: ColDef[],
    rowData: any[],
    types?:string,
    filters?:string,
    filterParams?:string
    clear?:string
}

export const Ttype=''

const GridView: FC<GridViewProps> = (props: GridViewProps): ReactElement => {

    const { columnDefs, rowData,types,filters,filterParams,clear} = props

    const gridRef = useRef<AgGridReact<any>>(null)
    // const gridRef = useRef(null)
    const [filteredRows, setFilteredRows] = useState<any>([])
    
    
    const defaultColDef = {
    sortable: true,



}

const getParams = () => {
    return {
      allColumns: true,
    };
  };
const onBtnExport = useCallback(() => {
    if (gridRef.current){
        gridRef.current.api.exportDataAsCsv(getParams());
}
}, []);

    const onFilterChanged = () => {
    setFilteredRows([])
    gridRef.current?.api.forEachNodeAfterFilterAndSort((node:any)=> {
        filteredRows.push(node.data)
        setFilteredRows(filteredRows)
    })
    }

    const applyFilter = useCallback((types,filters,filterParams) => {
        // if(gridRef.current){
        //     const countryFilterComponent = gridRef.current.api.getFilterInstance(
        //         'buildingId'
        //       );
        //       if(countryFilterComponent){
        //         countryFilterComponent.setModel({ values: ['Gents hostel I', '7c9f658d-ed7b-4466-8f29-a3b96c7bc471'] });
        //         gridRef.current.api.onFilterChanged();
        //       }
              
        // }

        // if(gridRef.current){
        //     // gridRef.current.api.setFilterModel(null); 
        //     const hardcodedFilter = { [filterParams]: { 'type': types, 'filter': filters }}
        //         // `{${filterParams}: {type: ${types} , filter: ${filters}}}`
        //         // age: { type: 'lessThan', filter: '30' },
        //         // athlete: { type: 'startsWith', filter: 'Mich' },
        //         // date: { type: 'lessThan', dateFrom: '2010-01-01' },
        //       ;
        //       gridRef.current.api.setFilterModel(hardcodedFilter); 
        // }
        if(gridRef.current && filterParams ){
        const sportsFilterComponent = gridRef.current.api.getFilterInstance(filterParams);
        if(sportsFilterComponent){
            sportsFilterComponent.setModel({
  
                
                    type: types,
                    filter: filters,
               

                });
        }    
        
            gridRef.current.api.onFilterChanged();}
      }, []);

      useEffect(()=>{
        setTimeout(()=>{
            
            applyFilter(types,filters,filterParams)
        },500)
      },[filters])
      const clearFilter=useCallback(()=>{
         if(gridRef.current){
             gridRef.current.api.setFilterModel(null); 
            }
      },[])

      useEffect(()=>{
        if(clear==='clear'){
            clearFilter()
        }
        
      },[clear])
    return (
        <>
        <AgGridReact
            animateRows
            ref={gridRef}
            defaultColDef={defaultColDef}
            onFilterChanged={onFilterChanged}
            columnDefs={columnDefs}
            rowData={rowData}
            pagination
            multiSortKey={'ctrl'}
            paginationPageSize={15}
            suppressExcelExport={true}
        />
        <br/>
        <br/>
        <Button onClick={onBtnExport} variant='contained' sx={{ backgroundColor: '#660066',float:'right', ":hover": { backgroundColor: '#660066' } }}>Download</Button>
        </>
    )
}

export default GridView