import http from "./http-common";

export const getApplications = async () => {
    return http.get<any>(`/application`);
}

export const createApplication = async (formData: any) => {
    return http.post<any>(`/application/create`, formData);
}

export const updateApplication = async (formData: any) => {
    return http.post<any>(`/application/update`, formData);
}

export const deleteApplication = async (formData: any) => {
    return http.post<any>(`/application/delete`, formData);
}

export const getAccessedApplications = async (formData: any) => {
    return http.post<any>(`/application/getAccessedApplications`, formData);
}