import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { FC, ReactElement } from "react";
import { deleteRoomService } from 'src/services/roomService';
type AlertType = "error" | "success" | "info" | "warning" | undefined;
interface DeleteRoomProps {
    visibility : boolean ,
    setVisiblity : (a: any) => void;
    refreshHome : () => void;
    data : any;
    statusData:(a: boolean)=>void;
    messagesData:(a:AlertType)=>void
}

const DeleteRoom : FC<DeleteRoomProps> =(props: DeleteRoomProps) : ReactElement => {

     const { visibility , setVisiblity, refreshHome, data : {number,  id},statusData,messagesData} = props;

     const handleClick = async (e: any) => {
        e.preventDefault();
        const response = await deleteRoomService({ _id: id})
        if(response.status === 200) {
            statusData(true)
            messagesData('warning')
            refreshHome()
            setVisiblity({visible: false})
        }
     }

     const handleClose = () => {
        setVisiblity({ visible: false})
     }

    return (
        <Dialog open={visibility} onClose={handleClose}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"> Delete Room </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure do you want to delete {number} room ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClick} autoFocus> Delete </Button>
            </DialogActions>  
        </Dialog>
    )
}

export default DeleteRoom