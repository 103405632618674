import axios from "axios";
import store from "src/store";
import API_ENDPOINT from "./ApiEndPoint";

const api = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-type": "application/json"
  }
});

api.interceptors.response.use(
  res => res,
  err => {
    if (err.response.data.msg === 'Token is not valid') {
      store.dispatch({ type: 'LOGOUT', payload: {} });
    }
    return Promise.reject(err);
  }
);

export default api;