import { createStore, applyMiddleware } from 'redux'
// import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from './reducers'
import setAuthToken from 'src/utils/setAuthToken';

const initialState = {};

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...[thunk])))
// const store = configureStore({reducer:reducer})

export type RootState = ReturnType<typeof store.getState>;
let currentState = store.getState();

store.subscribe(() => {
    // keep track of the previous and current state to compare changes
    const previousState: RootState = currentState;
    currentState = store.getState();
    // if the token changes set the value in localStorage and axios headers
    if (previousState.user.token !== currentState.user.token) {
        const token = currentState.user.token;
        setAuthToken(token);
    }
});

export default store